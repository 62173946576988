import React, { FC, useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../context/globalContext/globalContext";
import { useNavigate } from "react-router-dom";

interface ModifyInformationInterface {}

const ModifyInformation: FC<ModifyInformationInterface> = () => {
  // global state
  const { state }: any = useContext(GlobalContext);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});

  // useEffect to get localStorage Data
  useEffect(() => {
    let localStorageData: any = localStorage.getItem("formData");
    let formData = JSON.parse(localStorageData);
    if (formData) {
      setFormData(formData);
    }
  }, []);

  // method to get redirect user to forms page
  const modifyUserData = () => {
    if (formData) {
      let userData = { ...formData, formStep: 1 };
      const updatedUserData = JSON.stringify(userData);
      localStorage.setItem("formData", updatedUserData);
      navigate("/form");
    }
  };
  return (
    <>
      {formData && Object.keys(formData).length > 0 && (
        <div className="container mx-auto px-4 lg:px-16 flex justify-center">
          <div className="w-full lg:w-[91%] lg:pr-[10px] lg:px-2">
            <div className="w-full bg-white border border rounded-xl p-6">
              <div>
                <p className="text-[#4A4A4A] text-center text-[18px] font-semibold">
                  {state?.language?.result?.modifyTitle}
                </p>
                <p className="text-[#3A484D] text-center text-[14px] pt-4">
                  {state?.language?.result?.modifyContent}
                </p>
              </div>
              <div className="flex justify-center pt-6">
                <button
                  className="text-white  font-medium text-[16px] text-center bg-[#01a2da] hover:bg-[#0590c1] rounded-full py-3 px-6 lg:px-12"
                  onClick={() => modifyUserData()}
                >
                  {state?.language?.result?.modifyButton}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ModifyInformation;
