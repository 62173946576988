import React, { FC, useContext } from "react";
import { GlobalContext } from "../../context/globalContext/globalContext";
import HouseQ2 from "../../assets/icons/pdfStep/boiler.png";
import HouseQ1 from "../../assets/icons/pdfStep/heater.png";
import { epcColorHandler } from "../../utils/epcColors";
import { epcImageHandler } from "../../utils/result";

interface Step2Interface {}

const Step2: FC<Step2Interface> = () => {
  // Global state
  const { state }: any = useContext(GlobalContext);
  return (
    <div className={`mr-2 ml-2  mx-auto pt-7`}>
      {/* step 1 title box */}
      <div
        className={`w-full rounded-3xl py-3 `}
        style={{
          backgroundColor: epcColorHandler(
            state?.visitedUserData?.visitor_result?.ecolabel_step2
          ),
        }}
      >
        <div className="pb-2">
          <img
            className="absolute right-0 h-10 font-semibold text-white mr-[10%] mt-[7px]"
            alt="epc-label"
            src={epcImageHandler(
              state?.visitedUserData?.visitor_result?.ecolabel_step2?.toLowerCase()
            )}
          />
          <h5 className="text-center text-[30px] font-semibold text-white m-0">
            {state?.language?.pdf?.step2Title}
          </h5>
        </div>
      </div>
      <div className="py-4 flex flex-wrap">
        {/* 1st */}
        <div className="w-6/12 pr-2">
          <div className="w-full py-2 border-2 border-[#E1F4FB] px-6 rounded-xl h-full">
            <div className="w-full flex flex-row pt-4 pb-4">
              {/* left side */}
              <div className="w-full lg:w-2/12 flex justify-center">
                <img src={HouseQ1} alt="" className="h-20" />
              </div>
              {/* right side */}
              <div className="w-full lg:px-6 pt-4 lg:pt-0 lg:py-0 lg:w-10/12">
                <h5 className="text-[#3A484D] text-[19px] font-semibold">
                  {state?.language?.pdf?.step2Box1Title}
                </h5>
                {state?.visitedUserData?.pdf_data?.text10 !== undefined &&
                  state?.visitedUserData?.pdf_data?.text10 !== false && (
                    <p className="text-[#3A484D] text-[16px] pt-2">
                      {state?.language?.language === "fr" ? state?.visitedUserData?.pdf_data?.text10 : state?.visitedUserData?.pdf_data_de?.text10}
                    </p>
                  )}
                <p className="text-[#3A484D] text-[16px] pt-2">
                  {state?.visitedUserData?.pdf_data?.textstep2a !== false
                    ? state?.language?.language === "fr"
                      ? state?.visitedUserData?.pdf_data?.textstep2a
                      : state?.visitedUserData?.pdf_data_de?.textstep2a
                    : "-"}
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* 2nd */}
        <div className="w-6/12 pl-2">
          <div className="w-full py-2 border-2 border-[#E1F4FB] px-6 rounded-xl h-full">
            <div className="w-full flex flex-row pt-4 pb-4">
              {/* left side */}
              <div className="w-full lg:w-2/12 flex justify-center  h-full">
                <img src={HouseQ2} alt="" className="h-20" />
              </div>
              {/* right side */}
              <div className="w-full lg:px-6 pt-4 lg:pt-0 lg:py-0 lg:w-10/12">
                <h5 className="text-[#3A484D] text-[18px] font-semibold">
                  {state?.language?.pdf?.step2Box2Title}
                </h5>
                {state?.visitedUserData?.pdf_data?.text11 !== undefined &&
                  state?.visitedUserData?.pdf_data?.text11 !== false && (
                    <p className="text-[#3A484D] text-[16px] pt-2">
                      {state?.language?.language === "fr" ? state?.visitedUserData?.pdf_data?.text11 : state?.visitedUserData?.pdf_data_de?.text11}
                    </p>
                  )}
                <p className="text-[#3A484D] text-[16px] pt-2">
                  {state?.visitedUserData?.pdf_data?.textstep2b !== false
                    ? state?.language?.language === "fr"
                      ? state?.visitedUserData?.pdf_data?.textstep2b
                      : state?.visitedUserData?.pdf_data_de?.textstep2b
                    : "-"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`w-full rounded-3xl bg-[#fff] border-2 py-4 `}
        style={{
          borderColor: epcColorHandler(
            state?.visitedUserData?.visitor_result?.ecolabel_step2
          ),
        }}
      >
        <div className="flex justify-between px-14 items-center">
          <h5
            className={`text-left text-[24px] font-semibold`}
            style={{
              color: epcColorHandler(
                state?.visitedUserData?.visitor_result?.ecolabel_step2
              ),
            }}
          >
            {state?.language?.pdf?.step2BottomText}
          </h5>
          <h5
            className={`text-[35px] font-semibold `}
            style={{
              color: epcColorHandler(
                state?.visitedUserData?.visitor_result?.ecolabel_step2
              ),
            }}
          >
            {state?.visitedUserData?.visitor_result?.ecolabel_step2_value}{" "}
            {state?.language?.language === "fr" ? "kWh/m².an" : "kWh/m².Jahr"}
          </h5>
        </div>
      </div>
    </div>
  );
};

export default Step2;
