import React, { FC, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../../context/globalContext/globalContext";
import { setModalHandler } from "../../context/actions/modal";
import InfoIcon from "../../assets/icons/information-button.svg";
import { setHomePageFormData } from "../../context/actions/homepageAction";
import { storelocalStorageData } from "../../utils/forms";
import { mainPageScroll } from "../../utils/smoothScroll";
import BottomArrow from "../../assets/images/homepage/section2Arrow.svg";
import BottomArrow2 from "../../assets/images/homepage/illustration-homepage-3-arrow.svg";

interface HomeEvaluationInterface {
  section2?: any;
  section3?: any;
}

const HomeEvaluation: FC<HomeEvaluationInterface> = ({
  section2,
  section3,
}) => {
  // global state
  const { state, dispatch }: any = useContext(GlobalContext);
  const navigate = useNavigate();

  // state for radio button onChange not shows sele
  const [homePageForm, setHomePageForm]: any = useState({
    address: "",
    age: [0, 1, 2, 3, 4],
    button: 0,
  });

  // ageValuesHandler to show selected value inside input
  const ageValuesHandler = (value: any) => {
    if (JSON.stringify(value) === "[0, 1, 2, 3, 4]") {
      return "[0, 1, 2, 3, 4]";
    }
    if (JSON.stringify(value) === "[1, 0, 2, 3, 4]") {
      return "[1, 0, 2, 3, 4]";
    }
    if (JSON.stringify(value) === "[2, 0, 1, 3, 4]") {
      return "[2, 0, 1, 3, 4]";
    }
    if (JSON.stringify(value) === "[3, 0, 1, 2, 4]") {
      return "[3, 0, 1, 2, 4]";
    }
    if (JSON.stringify(value) === "[4, 0, 1, 2, 3]") {
      return "[4, 0, 1, 2, 3]";
    }
  };

  // onChangeHandler to add data into state
  const onChangeHandler = (value: any, key: string) => {
    let duplicate: any = { ...homePageForm };
    duplicate[key] = value;
    setHomePageForm(duplicate);
  };

  // submitHandler to store data of homepage form data
  const onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (homePageForm?.address === "" || homePageForm?.button === 0) {
      setModalHandler(
        {
          content: "",
          modal: "formError",
        },
        dispatch
      );
    }
    // success (redirect to form page and data into Global state)
    else {
      storelocalStorageData({ homepageFormData: homePageForm });
      setHomePageFormData(homePageForm, dispatch);
      navigate("/form");
    }
  };

  return (
    <>
      <div className="home-evaluation-container w-full pt-6" ref={section2}>
        <div className="large-screen-content-and-image">
          <div className="container mx-auto px-6 ">
            {/* title */}
            <div className="py-6 lg:w-9/12">
              <h1 className="text-[22px] lg:text-[34px] font-semibold leading-10 text-[#4a4a4b]">
                {state?.language?.HomePageForm?.title}
              </h1>
            </div>
            <div>
              <form
                className="flex flex-col lg:flex-row lg:justify-between px-8 lg:px-0"
                onSubmit={(e: React.SyntheticEvent) => {
                  onSubmit(e);
                }}
              >
                {/* left side form & info*/}
                <div className="w-full lg:w-8/12 pt-6">
                  <div>
                    {/* code postal & age-group */}
                    <div className="flex flex-col lg:flex-row">
                      <div className="w-full lg:w-6/12 flex flex-col">
                        <label className="text-[20px] font-medium lg:px-4 text-[#4A4A4A]">
                          {state?.language?.HomePageForm?.form?.title}
                        </label>
                        <div className="py-6">
                          <input
                            placeholder="1330 Rixensart"
                            className="rounded-2xl border-2 border-[#eaf7fc] text-[18px] py-4 w-10/12 px-5 bg-white outline-none text-left text-[#4a4a4b]"
                            type="text"
                            value={homePageForm?.address}
                            onChange={(e) =>
                              onChangeHandler(e.target.value, "address")
                            }
                          />
                        </div>
                      </div>
                      {/* radio for desktop */}
                      <div className="lg:px-6 hidden lg:block">
                        <label className="text-[20px] font-medium text-[#4A4A4A]">
                          {
                            state?.language?.HomePageForm?.form?.checkBoxGroup
                              ?.title
                          }
                        </label>
                        {/* 1st */}
                        <label className="radio-container mt-4 mb-2">
                          <span className="text-[18px] font-normal text-[#4a4a4b]">
                            {
                              state?.language?.HomePageForm?.form?.checkBoxGroup
                                ?.radioBtn1Text
                            }
                          </span>
                          <input
                            type="radio"
                            name="radio1"
                            checked={homePageForm?.button === 1 ? true : false}
                            onChange={() => onChangeHandler(1, "button")}
                          />
                          <span className="checkmark"></span>
                        </label>
                        {/* 2nd */}
                        <label className="radio-container mb-2">
                          <span className="text-[18px] font-normal text-[#4a4a4b]">
                            {
                              state?.language?.HomePageForm?.form?.checkBoxGroup
                                ?.radioBtn2Text
                            }
                          </span>
                          <input
                            type="radio"
                            name="radio1"
                            checked={homePageForm?.button === 2 ? true : false}
                            onChange={() => onChangeHandler(2, "button")}
                          />
                          <span className="checkmark"></span>
                        </label>
                        {/* 3rd */}
                        <label className="radio-container">
                          <span className="text-[18px] font-normal text-[#4a4a4b]">
                            {
                              state?.language?.HomePageForm?.form?.checkBoxGroup
                                ?.radioBtn3Text
                            }
                          </span>
                          <input
                            type="radio"
                            name="radio1"
                            onChange={() => onChangeHandler(3, "button")}
                            checked={homePageForm?.button === 3 ? true : false}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    {/* code postal & age-group */}
                    <div className="pt-4 lg:pt-0">
                      <div className="w-full lg:w-6/12 flex flex-col">
                        <label className="text-[20px] font-medium lg:px-4 text-[#4A4A4A]">
                          {state?.language?.HomePageForm?.form?.dropDown?.title}
                        </label>
                        <div className="pt-6">
                          <select
                            placeholder="1330 Rixensart"
                            className="rounded-2xl border-2 border-[#eaf7fc] text-[18px] py-4 w-[67%] lg:w-6/12 px-6 bg-white outline-none text-[#4a4a4b]"
                            onChange={(e) =>
                              onChangeHandler(JSON.parse(e.target.value), "age")
                            }
                            value={ageValuesHandler(homePageForm?.age)}
                          >    
                            <option value="[0, 1, 2, 3, 4]">{state?.language?.HomePageForm?.form?.select1829}</option>
                            <option value="[1, 0, 2, 3, 4]">{state?.language?.HomePageForm?.form?.select3039}</option>
                            <option value="[2, 0, 1, 3, 4]">{state?.language?.HomePageForm?.form?.select4049}</option>
                            <option value="[3, 0, 1, 2, 4]">{state?.language?.HomePageForm?.form?.select5059}</option>
                            <option value="[4, 0, 1, 2, 3]">{state?.language?.HomePageForm?.form?.select60}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    {/* radio for mobile */}
                    <div className="pt-9 pb-2 block lg:hidden">
                      <label className="text-[20px] font-medium text-[#4A4A4A]">
                        {
                          state?.language?.HomePageForm?.form?.checkBoxGroup
                            ?.title
                        }
                      </label>
                      {/* 1st */}
                      <label className="radio-container mt-4 mb-2">
                        <span className="text-[18px] font-normal text-[#4a4a4b]">
                          {
                            state?.language?.HomePageForm?.form?.checkBoxGroup
                              ?.radioBtn1Text
                          }
                        </span>
                        <input
                          type="radio"
                          name="radio2"
                          checked={homePageForm?.button === 1 ? true : false}
                          onChange={() => onChangeHandler(1, "button")}
                        />
                        <span className="checkmark"></span>
                      </label>
                      {/* 2nd */}
                      <label className="radio-container mb-2">
                        <span className="text-[18px] font-normal text-[#4a4a4b]">
                          {
                            state?.language?.HomePageForm?.form?.checkBoxGroup
                              ?.radioBtn2Text
                          }
                        </span>
                        <input
                          type="radio"
                          name="radio2"
                          checked={homePageForm?.button === 2 ? true : false}
                          onChange={() => onChangeHandler(2, "button")}
                        />
                        <span className="checkmark"></span>
                      </label>
                      {/* 3rd */}
                      <label className="radio-container">
                        <span className="text-[18px] font-normal text-[#4a4a4b]">
                          {
                            state?.language?.HomePageForm?.form?.checkBoxGroup
                              ?.radioBtn3Text
                          }
                        </span>
                        <input
                          type="radio"
                          name="radio2"
                          onChange={() => onChangeHandler(3, "button")}
                          checked={homePageForm?.button === 3 ? true : false}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </div>
                  {/* info */}
                  <div className="pt-14 hidden lg:block">
                    <div className="py-8 px-10 w-10/12 bg-white rounded-2xl">
                      <p className="text-[22px] font-semibold leading-6 text-[#00A3DA]">
                        {state?.language?.HomePageForm?.objectives?.title}
                      </p>
                      <p className="text-[16px] font-normal leading-6 text-[#3A484D] pt-4">
                        {state?.language?.HomePageForm?.objectives?.description}
                      </p>
                    </div>
                    {/* downward icon */}
                    <div className="downward-arrow-icon"></div>
                  </div>
                </div>
                {/* right side button and information text */}
                <div className="w-full lg:w-4/12">
                  {/* button and information text for small screens */}
                  <div className="pt-6 pb-2">
                    <div className="w-full absolute left-0 lg:initial lg:relative">
                      <button
                        type="submit"
                        className="text-white w-full font-medium text-[20px] text-center bg-[#01a2da] hover:bg-[#0590c1] lg:rounded-2xl py-9 px-4 transition"
                      >
                        {state?.language?.HomePageForm?.form?.submitBtn?.title}
                      </button>
                    </div>
                  </div>
                  <div className="responsvie-info flex px-2 py-3 mt-[110px] lg:mt-[0px]">
                    <div className="pt-[3px]">
                      <img src={InfoIcon} alt="" width="16px" height="55px" />
                    </div>
                    <div className="w-[90%]">
                      <p className="text-[#505e61] font-normal text-[14px] px-2">
                        {state?.language?.HomePageForm?.bottomMessage}
                      </p>
                    </div>
                  </div>
                  <div className="responsvie-info flex px-2 py-2">
                    <div className="pt-[3px]">
                      <img src={InfoIcon} alt="" width="16px" height="55px" />
                    </div>
                    <div className="w-[90%]">
                      <p className="text-[#505e61] font-normal px-2 text-[14px]">
                        {state?.language?.HomePageForm?.bottomMessageTwo}
                      </p>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          {/*  */}
          <div className="large-screen-img z-10">
            <div className="home-evaluationResponsive-image">
              <div className="flex w-full justify-center">
                <div className="absolute pl-[22rem]">
                  <img
                    src={BottomArrow}
                    alt=""
                    className="h-24 pt-4 cursor-pointer"
                    onClick={() => mainPageScroll(section3.current)}
                  />
                </div>
              </div>
              <img
                src={require("../../assets/images/homepage/section2Bg-cropped.png")}
                alt=""
                className="w-full lg:mt-[-8px] lg:z-[-1] relative"
              />
            </div>
          </div>
        </div>
      </div>
      {/* mobile info */}
      <div className="px-3 home-evaluation-container">
        <div className="pt-14 lg:hidden block ">
          <div className="flex justify-center pt-2 container mx-auto">
            <div className="py-8 px-10 bg-white rounded-2xl">
              <p className="text-[18px] font-medium leading-6 text-[#01a2da]">
                {state?.language?.HomePageForm?.objectives?.title}
              </p>
              <p className="text-[16px] font-normal leading-6 text-[#3a484e] pt-4">
                {state?.language?.HomePageForm?.objectives?.description}
              </p>
            </div>
          </div>
          {/* downward icon */}
          <div className="downward-arrow-icon z-10"></div>
        </div>
      </div>
      {/* house image for both */}
      <div className="medium-screen-img">
        <div className="home-evaluationResponsive-image">
          <div className="flex w-full justify-center">
            <div className="absolute pl-[22rem]">
              <img
                src={BottomArrow}
                alt=""
                className="h-24 pt-4 cursor-pointer"
                onClick={() => mainPageScroll(section3.current)}
              />
            </div>
          </div>
          <img
            src={require("../../assets/images/homepage/section2Bg-cropped.png")}
            alt=""
            className="w-full lg:mt-[-8px] lg:z-[-1] relative"
          />
        </div>
      </div>
      <div className="lg:hidden block">
        <div className="bg-[#4b6866]">
          <div className="absolute pl-[4rem] w-full flex justify-center z-10 pt-14">
            <img
              src={BottomArrow2}
              alt=""
              className="h-18 cursor-pointer"
              onClick={() => mainPageScroll(section3.current)}
            />
          </div>
          <img
            src={require("../../assets/images/homepage/illustration-homepage-responsive-4.png")}
            alt=""
            className="w-full relative"
          />
        </div>
      </div>
    </>
  );
};

export default HomeEvaluation;
