import React, { FC, useContext } from "react";
import { GlobalContext } from "../../context/globalContext/globalContext";
import { useNavigate } from "react-router-dom";
import { setModalHandler } from "../../context/actions/modal";
import CrossIcon from "../../assets/icons/close.png";

interface ResultModallInterface {}

const ResultModal: FC<ResultModallInterface> = () => {
  const navigate = useNavigate();
  // global state
  const { state, dispatch }: any = useContext(GlobalContext);
  return (
    <div
      className={`relative z-50 modal-container ${
        state.modal?.modal !== "" ? "opacity-100" : "opacity-0"
      }`}
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      {/* condition to show modal when it's active */}
      {state?.modal?.modal !== "" && (
        <div>
          <div className="fixed inset-0 bg-[#E9F6FD] bg-opacity-75 transition-opacity"></div>
          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex sm:items-center justify-center lg:min-h-full p-4 text-center sm:p-0">
              <div className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 w-full lg:w-7/12">
                {/* header */}
                <div
                  className="flex justify-end absolute right-0 m-3 cursor-pointer w-fit"
                  onClick={() => {
                    setModalHandler(
                      {
                        content: "",
                        modal: "",
                      },
                      dispatch
                    );
                  }}
                >
                  <img src={CrossIcon} alt="cross-icon" className="h-3" />
                </div>
                {/* body of home page modal */}
                <div>
                  <div className="bg-white px-4 py-8">
                    <div>
                      <div className="w-full">
                        <h3
                          className="text-[18px] text-center leading-6 font-semibold text-[#4C4D4D]"
                          id="modal-title"
                        >
                          {/* for error */}
                          {state?.modal?.modal === "sendEmailError" &&
                            state?.language?.result?.emailError}
                          {state?.modal?.modal === "sendEmailError2" &&
                            state?.language?.result?.emailError2}
                          {/* for success */}
                          {state?.modal?.modal === "sendEmailSuccess" &&
                            state?.language?.result?.emailConfirmation}
                          {/* user not exist */}
                          {state?.modal?.modal !== "sendEmailSuccess" &&
                            state?.modal?.modal !== "sendEmailError" &&
                            state?.modal?.modal !== "sendEmailError2" &&
                            state?.modal?.modal !== "stepsContent" &&
                            state?.language?.result?.modalContent}
                          {/* steps notifation title */}
                          {state?.modal?.modal === "stepsContent" &&
                            state?.language?.result?.infoboxHeader}
                        </h3>
                        {/* content for steps */}
                        {state?.modal?.modal === "stepsContent" && (
                          <div
                            className={`pt-5 text-[14px] text-[#4C4D4D]`}
                            dangerouslySetInnerHTML={{
                              __html:
                                state?.language?.result?.infoboxDescription,
                            }}
                          ></div>
                        )}
                        {state?.modal?.modal !== "stepsContent" && (
                          <div className="pt-8 flex justify-center">
                            <button
                              className="text-white font-medium text-[16px] text-center bg-[#01a2da] hover:bg-[#0590c1] rounded-full py-3 px-10 transition"
                              onClick={() => {
                                if (
                                  state?.modal?.modal !== "sendEmailError" &&
                                  state?.modal?.modal !== "sendEmailError2" &&
                                  state?.modal?.modal !== "sendEmailSuccess"
                                ) {
                                  navigate("/");
                                }
                                setModalHandler(
                                  {
                                    content: "",
                                    modal: "",
                                  },
                                  dispatch
                                );
                              }}
                            >
                              {state?.modal?.modal === "sendEmailSuccess"
                                ? state?.language?.result?.emailButton
                                : state?.language?.modal?.button}
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ResultModal;
