import React, { FC, useContext } from "react";
import { GlobalContext } from "../../context/globalContext/globalContext";
import DoubleHouse from "../../assets/icons/result-page-icons/double-house.svg";
import HouseAir from "../../assets/icons/result-page-icons/house-air.svg";
import HouseWind from "../../assets/icons/result-page-icons/house-wind.svg";
// import BoilerElectric from "../../assets/icons/result-page-icons/boiler-electric.svg";
import SolarPanel from "../../assets/icons/result-page-icons/solarpanels-sun.svg";
import Boiler from "../../assets/icons/result-page-icons/boiler.svg";
import Heater from "../../assets/icons/result-page-icons/heater.svg";

interface BuildingDetailsInterface {}

const BuildingDetails: FC<BuildingDetailsInterface> = () => {
  // global state
  const { state }: any = useContext(GlobalContext);

  const getSelectedAnswere = (id: number) => {
    if (state?.visitedUserData !== "") {
      // to find selected answers
      if (id === 31) {
        let selectedAnswere = state?.visitedUserData?.visitor_answer?.filter(
          (answer: any) => answer.question_id === id
        );
        return selectedAnswere !== undefined && selectedAnswere;
      } else {
        let selectedAnswere = state?.visitedUserData?.visitor_answer?.find(
          (answer: any) => answer.question_id === id
        );
        // to return selected answers
        if (
          selectedAnswere !== undefined &&
          selectedAnswere?.answer[state?.language?.language] === "non"
        ) {
          return "Non";
        } else {
          return selectedAnswere !== undefined && selectedAnswere
            ? selectedAnswere?.answer[state?.language?.language]
            : "";
        }
      }
    }
  };

  // handler to get value subtypes value
  const getSelectedAnswereType = (id: number) => {
    if (state?.visitedUserData !== "") {
      // to find selected answers
      let selectedAnswere = state?.visitedUserData?.visitor_answer?.find(
        (answer: any) => answer.question_id === id
      );
      // to return selected answers
      return selectedAnswere !== undefined && selectedAnswere
        ? selectedAnswere?.answer.fr.toLowerCase()
        : "";
    }
  };
  console.log(
    "state?.visitedUserData?.visitor_answer",
    state?.visitedUserData?.visitor_answer
  );
  return (
    <div className="container mx-auto px-4 lg:px-16 py-4 flex justify-center">
      <div className="w-full lg:w-[91%] lg:pr-[10px] lg:px-2">
        <div className="w-full bg-white border border rounded-xl lg:p-6">
          <div className="pt-4 lg:pt-0">
            <p className="text-[#4A4A4A] text-center text-[18px] font-semibold">
              {state?.language?.result?.building?.title}
            </p>
          </div>
          <div className="flex flex-wrap pt-3 lg:pt-10 lg:pl-12 pr-5 lg:pr-2">
            {/* 1st */}
            <div className="w-full lg:w-6/12 py-2 lg:border-0 border-b border-[#EAF7FD] py-6 px-6 lg:p-0">
              <div className="pt-4 lg:pt-0 hidden lg:block">
                <h5 className="text-[#3A484D] text-[16px] font-semibold">
                  {state?.language?.result?.building?.habitation}
                </h5>
              </div>
              <div className="w-full flex flex-col lg:flex-row lg:items-center py-4">
                {/* left side */}
                <div className="w-full lg:w-2/12 flex items-center">
                  <img src={DoubleHouse} alt="" className="h-10 lg:h-16" />
                  <div className="pt-2 lg:pt-0 block lg:hidden px-4">
                    <h5 className="text-[#3A484D] text-[16px] font-semibold">
                      {state?.language?.result?.building?.habitation}
                    </h5>
                  </div>
                </div>
                {/* right side */}
                <div className="w-full lg:px-6 pt-4 lg:pt-0 lg:py-0 lg:w-10/12">
                  <p className="text-[#3A484D] text-[14px]">
                    {getSelectedAnswere(5)}
                  </p>
                  <p className="text-[#3A484D] text-[14px] py-1">
                    {" "}
                    {getSelectedAnswere(6)}
                  </p>
                  <p className="text-[#3A484D] text-[14px]">
                    {getSelectedAnswere(7)}
                  </p>
                  <p className="text-[#3A484D] text-[14px] py-1">
                    {getSelectedAnswere(8)}
                  </p>
                  {/*  */}
                  {/*  */}
                </div>
              </div>
            </div>
            {/* 2nd */}
            <div className="w-full lg:w-6/12 py-1 lg:border-0 lg:pl-4 lg:pr-0 border-b border-[#EAF7FD] p-6">
              <div className="pt-4 lg:pt-0 hidden lg:block">
                <h5 className="text-[#3A484D] text-[16px] font-semibold">
                  {state?.language?.result?.building?.isolation}
                </h5>
              </div>
              <div className="w-full flex flex-col lg:flex-row py-4">
                {/* left side */}
                <div className="w-full lg:w-2/12 flex items-center lg:items-start">
                  <img
                    src={HouseAir}
                    alt=""
                    className="h-10 lg:h-16 lg:mt-4 lg:ml-2"
                  />
                  <div className="pt-2 lg:pt-0 block lg:hidden px-4">
                    <h5 className="text-[#3A484D] text-[16px] font-semibold">
                      {state?.language?.result?.building?.isolation}
                    </h5>
                  </div>
                </div>
                {/* right side */}
                <div className="w-full pt-4 lg:pt-0 lg:pl-6 lg:pr-0 lg:w-10/12">
                  {getSelectedAnswere(11) !== "" && (
                    <p className="text-[#3A484D] text-[14px]">
                      <span className="font-semibold pr-1">
                        {state?.language?.result?.question1}
                      </span>
                      {getSelectedAnswere(11)}
                    </p>
                  )}
                  {/* box 2 */}
                  {getSelectedAnswereType(12) !== "oui" &&
                    getSelectedAnswere(12) !== "" && (
                      <p className="text-[#3A484D] text-[14px] py-1">
                        <span className="font-semibold pr-1">
                          {state?.language?.result?.question11}
                        </span>
                        {getSelectedAnswere(12)}
                      </p>
                    )}
                  {/* Épaisseur isolant toiture ou plafond :  */}
                  {getSelectedAnswere(13) !== "" && (
                    <p className="text-[#3A484D] text-[14px] py-1">
                      <span className="font-semibold pr-1">
                        {state?.language?.result?.question2}
                      </span>
                      {getSelectedAnswere(13)}
                    </p>
                  )}
                  {getSelectedAnswere(14) !== "" && (
                    <p className="text-[#3A484D] text-[14px] py-1">
                      <span className="font-semibold pr-1">
                        {state?.language?.result?.question3}
                      </span>
                      {getSelectedAnswere(14)}
                    </p>
                  )}
                  {/* box 3 */}
                  {getSelectedAnswereType(16) !== "oui" &&
                    getSelectedAnswere(16) !== "" && (
                      <p className="text-[#3A484D] text-[14px]">
                        <span className="font-semibold pr-1">
                          {state?.language?.result?.question12}
                        </span>
                        {getSelectedAnswere(16)}
                      </p>
                    )}
                  {/*Épaisseur isolant murs  */}
                  {getSelectedAnswere(17) !== "" && (
                    <p className="text-[#3A484D] text-[14px] py-1">
                      <span className="font-semibold pr-1">
                        {state?.language?.result?.question4}
                      </span>
                      {getSelectedAnswere(17)}
                    </p>
                  )}
                  {getSelectedAnswere(18) !== "" && (
                    <p className="text-[#3A484D] text-[14px] py-1">
                      <span className="font-semibold pr-1">
                        {state?.language?.result?.question5} :
                      </span>
                      {getSelectedAnswere(18)}
                    </p>
                  )}
                  {getSelectedAnswere(19) !== "" && (
                    <p className="text-[#3A484D] text-[14px] py-1">
                      <span className="font-semibold pr-1">
                        {state?.language?.result?.question14}
                      </span>
                      {getSelectedAnswere(19)}
                    </p>
                  )}
                  {getSelectedAnswereType(21) !== "oui" &&
                    getSelectedAnswere(21) !== "" && (
                      <p className="text-[#3A484D] text-[14px] py-1">
                        <span className="font-semibold pr-1">
                          {state?.language?.result?.question13}
                        </span>
                        {getSelectedAnswere(21)}
                      </p>
                    )}
                  {/*   */}
                  {getSelectedAnswere(22) !== "" && (
                    <p className="text-[#3A484D] text-[14px] py-1">
                      <span className="font-semibold pr-1">
                        {state?.language?.result?.question6}
                      </span>
                      {getSelectedAnswere(22)}
                    </p>
                  )}
                  {getSelectedAnswere(23) !== "" && (
                    <p className="text-[#3A484D] text-[14px]">
                      <span className="font-semibold pr-1">
                        {state?.language?.result?.question7}
                      </span>
                      {getSelectedAnswere(23)}
                    </p>
                  )}
                  {getSelectedAnswere(24) !== "" && (
                    <p className="text-[#3A484D] text-[14px]">
                      {getSelectedAnswere(24)}
                    </p>
                  )}
                </div>
              </div>
            </div>
            {/* 3rd */}
            <div className="w-full lg:w-6/12 py-1 lg:border-0 border-b border-[#EAF7FD] p-6 lg:p-0">
              <div className="pt-4 lg:pt-0 hidden lg:block">
                <h5 className="text-[#3A484D] text-[16px] font-semibold">
                  {state?.language?.result?.building?.ventilation}
                </h5>
              </div>
              <div className="w-full flex flex-col lg:flex-row py-4">
                {/* left side */}
                <div className="w-full lg:w-2/12 flex items-center lg:items-start">
                  <img src={HouseWind} alt="" className="h-10 lg:h-16" />
                  <div className="pt-2 lg:pt-0 block lg:hidden px-4">
                    <h5 className="text-[#3A484D] text-[16px] font-semibold">
                      {state?.language?.result?.building?.ventilation}
                    </h5>
                  </div>
                </div>
                {/* right side */}
                <div className="w-full pt-4 lg:pt-0 lg:px-6 lg:w-10/12">
                  <p className="text-[#3A484D] text-[14px]">
                    <span className="font-semibold pr-1">
                      {state?.language?.result?.question8}
                    </span>
                    {getSelectedAnswere(26)}
                  </p>
                  <p className="text-[#3A484D] text-[14px] py-1">
                    <span className="font-semibold pr-1">
                      {state?.language?.result?.question9}
                    </span>
                    {getSelectedAnswere(27)}
                  </p>
                  <p className="text-[#3A484D] text-[14px] py-1">
                    <span className="font-semibold pr-1">
                      {state?.language?.result?.question10}
                    </span>
                    {getSelectedAnswere(28)}
                  </p>
                </div>
              </div>
            </div>
            {/* 4th */}
            <div className="w-full lg:w-6/12 py-1 lg:border-0 lg:px-4 border-b border-[#EAF7FD] p-6 lg:p-0">
              <div className="pt-4 lg:pt-0 hidden lg:block">
                <h5 className="text-[#3A484D] text-[16px] font-semibold">
                  {state?.language?.result?.building?.heater}
                </h5>
              </div>
              <div className="w-full flex flex-col lg:flex-row py-4">
                {/* left side */}
                <div className="w-full lg:w-2/12 flex items-center">
                  <img src={Heater} alt="" className="h-10 lg:h-16" />
                  <div className="pt-2 lg:pt-0 block lg:hidden px-4">
                    <h5 className="text-[#3A484D] text-[16px] font-semibold">
                      {state?.language?.result?.building?.heater}
                    </h5>
                  </div>
                </div>
                {/* right side */}
                <div className="w-full pt-4 lg:pt-0 lg:px-6 lg:w-10/12">
                  <p className="text-[#3A484D] text-[14px]">
                    <span className="font-semibold pr-1">
                      {state?.language?.result?.building?.heaterPrimary} :
                    </span>
                    {getSelectedAnswere(29)}
                  </p>
                  {getSelectedAnswere(31)?.length > 0 && (
                    <p className="text-[#3A484D] text-[14px]">
                      <span className="font-semibold pr-1">
                        {state?.language?.result?.building?.heaterSecondary} :
                      </span>
                      {getSelectedAnswere(31)?.map((item: any, index: any) => (
                        <span key={item.id} className="pr-1">
                          {item?.answer[state?.language?.language]}{" "}
                          {index + 1 === getSelectedAnswere(31).length
                            ? ""
                            : "-"}
                        </span>
                      ))}
                    </p>
                  )}
                </div>
              </div>
            </div>
            {/* 5th */}
            <div className="w-full lg:w-6/12 py-1 lg:border-0 border-b border-[#EAF7FD] p-6 lg:p-0">
              <div className="pt-4 lg:pt-0 hidden lg:block">
                <h5 className="text-[#3A484D] text-[16px] font-semibold">
                  {state?.language?.result?.building?.water}
                </h5>
              </div>
              <div className="w-full flex flex-col lg:flex-row items-center py-4">
                {/* left side */}
                <div className="w-full lg:w-2/12 flex items-center">
                  <img src={Boiler} alt="" className="h-10 lg:h-16" />
                  <div className="pt-2 lg:pt-0 block lg:hidden px-4">
                    <h5 className="text-[#3A484D] text-[16px] font-semibold">
                      {state?.language?.result?.building?.water}
                    </h5>
                  </div>
                </div>
                {/* right side */}
                <div className="w-full pt-4 lg:pt-0 lg:px-6 lg:w-10/12">
                  <p className="text-[#3A484D] text-[14px]">
                    {getSelectedAnswere(32)}
                  </p>
                  <p className="text-[#3A484D] text-[14px]">
                    {state?.language?.result?.building?.pvWater} :{" "}
                    {getSelectedAnswere(33)}
                  </p>
                </div>
              </div>
            </div>
            {/* 6th */}
            <div className="w-full lg:w-6/12 py-1 lg:px-4 p-6 lg:p-0">
              <div className="pt-4 lg:pt-0 hidden lg:block">
                <h5 className="text-[#3A484D] text-[16px] font-semibold">
                  {state?.language?.result?.building?.pvElec}
                </h5>
              </div>
              <div className="w-full flex flex-col lg:flex-row items-center py-4">
                {/* left side */}
                <div className="w-full lg:w-2/12 flex flex-row items-center">
                  <img src={SolarPanel} alt="" className="h-10 lg:h-16" />
                  <div className="pt-2 lg:pt-0 block lg:hidden px-4">
                    <h5 className="text-[#3A484D] text-[16px] font-semibold">
                      {state?.language?.result?.building?.pvElec}
                    </h5>
                  </div>
                </div>
                {/* right side */}
                <div className="w-full pt-4 lg:pt-0 lg:px-6 lg:w-10/12">
                  <p className="text-[#3A484D] text-[14px] py-1">
                    {state?.language?.result?.building?.pvElec} :{" "}
                    {getSelectedAnswere(34)}
                  </p>
                  {getSelectedAnswere(35) !== "" && (
                    <p className="text-[#3A484D] text-[14px]">
                      {getSelectedAnswere(35)}
                    </p>
                  )}
                  {getSelectedAnswere(36) !== "" && (
                    <p className="text-[#3A484D] text-[14px]">
                      {getSelectedAnswere(36)}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuildingDetails;
