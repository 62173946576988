import React, { FC, useContext } from "react";
import { GlobalContext } from "../../context/globalContext/globalContext";
import AuditLogement from "../../assets/icons/auditlogement.png";
import GuichetsEnergie from "../../assets/icons/guichets_energie_1.jpg";
import CertificatPeb from "../../assets/icons/certificat_peb.png";
import Walloreno from "../../assets/icons/walloreno.png";
import BlueArrow from "../../assets/icons/questionsForm/step1/arrow-down-blue.svg";

interface ProjectsInterface {}

const Projects: FC<ProjectsInterface> = () => {
  // global state
  const { state }: any = useContext(GlobalContext);
  return (
    <div className="result_page_projects_container">
      <div className="container mx-auto px-4 lg:px-16 pt-4 lg:pt-4 pb-[70px] flex justify-center">
        <div className="w-full lg:w-[91%] lg:pr-[10px] lg:px-2 flex flex-col lg:flex-row">
          {/* left side */}
          <div className="lg:pr-2 lg:w-6/12">
            <div className="w-full bg-white border border rounded-xl p-4 lg:p-6">
              <div className="flex w-full">
                <div className="w-4/12">
                  <img src={AuditLogement} className="ml-[-7px]" alt="" />
                </div>
                <div className="w-full w-8/12">
                  <div>
                    <p className="text-[#3A484D] text-left text-[16px] font-semibold">
                      {state?.language?.result?.informations?.box31}
                    </p>
                  </div>
                  <div className="hidden lg:block">
                    <div className="flex justify-end">
                      <a
                        className="text-[#00A3DA] text-right text-[14px] pt-4 font-semibold flex items-center"
                        href="https://energie.wallonie.be/fr/liste-des-auditeurs-logement.html?IDC=8008"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="">
                          <img
                            src={BlueArrow}
                            alt=""
                            className="h-6 rotate-[-90deg]"
                          />
                        </span>
                        <span className="w-[95%] pt-[2px] pl-[10px]">
                          {state?.language?.result?.informations?.box32}
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="block lg:hidden pb-4">
                <a
                  className="text-[#00A3DA] text-left text-[14px] pt-4 font-semibold flex items-center"
                  href="https://energie.wallonie.be/fr/liste-des-auditeurs-logement.html?IDC=8008"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="">
                    <img
                      src={BlueArrow}
                      alt=""
                      className="h-6 rotate-[-90deg]"
                    />
                  </span>
                  <span className="w-[95%] pt-[2px] pl-[10px]">
                    {state?.language?.result?.informations?.box32}
                  </span>
                </a>
              </div>
              <div>
                <div className="flex px-1">
                  <span className="w-2 mt-2 h-2 bg-[#00A3DA] rounded-full"></span>
                  <p className="text-[#3A484D] text-[14px] m-0 px-2 w-10/12">
                    {state?.language?.result?.informations?.box32Point1}
                  </p>
                </div>
                <div className="flex px-1">
                  <span className="w-2 mt-2 h-2 bg-[#00A3DA] rounded-full"></span>
                  <p className="text-[#3A484D] text-[14px] m-0 px-2 w-10/12">
                    {state?.language?.result?.informations?.box32Point2}
                  </p>
                </div>
                <div className="flex px-1">
                  <span className="w-2 h-2 mt-2 bg-[#00A3DA] rounded-full"></span>
                  <p className="text-[#3A484D] text-[14px] m-0 px-2 w-10/12">
                    {state?.language?.result?.informations?.box32Point3}
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full hidden lg:block bg-white border border rounded-xl p-6 mt-4">
              <div className="flex w-full">
                <div className="w-full">
                  <div>
                    <p className="text-[#3A484D] text-left text-[16px] font-semibold">
                      {state?.language?.result?.informations?.box1New}
                    </p>
                  </div>
                  <div className="flex justify-center lg:pl-10">
                    <img
                      src={GuichetsEnergie}
                      className="h-[5rem]"
                      alt=""
                    />
                  </div>
                  <div className="flex justify-start pt-2">
                    <a
                      className="text-[#00A3DA] text-left text-[14px] pt-4 font-semibold flex"
                      href="https://energie.wallonie.be/fr/guichets-energie-wallonie.html?IDC=6946"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="">
                        <img
                          src={BlueArrow}
                          alt=""
                          className="h-6 rotate-[-90deg]"
                        />
                      </span>
                      <span className="w-[95%] pl-[3px] pt-[2px] lg:pl-[10px]">
                        {state?.language?.result?.informations?.box14}
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full block lg:hidden bg-white border border rounded-xl p-6 mt-4">
              <div className="flex w-full">
                <div className="w-4/12">
                  <img src={CertificatPeb} className="ml-[-10px]" alt="" />
                </div>
                <div className="w-full w-8/12">
                  <div>
                    <p className="text-[#3A484D] text-left text-[16px] font-semibold">
                      {state?.language?.result?.informations?.box4}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex justify-start">
                <a
                  className="text-[#00A3DA] text-left text-[14px] pt-4 font-semibold flex w-full"
                  href="https://energie.wallonie.be/fr/liste-des-certificateurs-peb-agrees.html?IDC=7233"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="">
                    <img
                      src={BlueArrow}
                      alt=""
                      className="h-6 rotate-[-90deg]"
                    />
                  </span>
                  <span className="w-[95%] pt-[2px] pl-[10px]">
                    {state?.language?.result?.informations?.box41}
                  </span>
                </a>
              </div>
            </div>
          </div>
          {/* right side */}
          <div className="lg:pl-2 lg:w-6/12 pt-4 lg:pt-0">
            <div className="w-full hidden lg:block bg-white border border rounded-xl p-6">
              <div className="flex w-full">
                <div className="w-4/12">
                  <img src={CertificatPeb} className="ml-[-10px]" alt="" />
                </div>
                <div className="w-full w-8/12">
                  <div>
                    <p className="text-[#3A484D] text-left text-[16px] font-semibold">
                      {state?.language?.result?.informations?.box4}
                    </p>
                  </div>
                  <div className="flex justify-end">
                    <a
                      className="text-[#00A3DA] text-left text-[14px]  pt-4 font-semibold flex"
                      href="https://energie.wallonie.be/fr/liste-des-certificateurs-peb-agrees.html?IDC=7233"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="">
                        <img
                          src={BlueArrow}
                          alt=""
                          className="h-6 rotate-[-90deg]"
                        />
                      </span>
                      <span className="w-[95%] pt-[2px] pl-[10px]">
                        {state?.language?.result?.informations?.box41}
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full block lg:hidden bg-white border border rounded-xl p-6">
              <div className="flex w-full">
                <div className="w-full">
                  <div>
                    <p className="text-[#3A484D] text-left text-[16px] font-semibold">
                      {state?.language?.result?.informations?.box1New}
                    </p>
                  </div>
                  <div className="flex justify-center lg:pl-10 py-2">
                    <img
                      src={GuichetsEnergie}
                      className="h-20"
                      alt=""
                    />
                  </div>
                  <div className="flex justify-start pt-2">
                    <a
                      className="text-[#00A3DA] text-left text-[14px] pt-4 font-semibold flex"
                      href="https://energie.wallonie.be/fr/guichets-energie-wallonie.html?IDC=6946"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="">
                        <img
                          src={BlueArrow}
                          alt=""
                          className="h-6 rotate-[-90deg]"
                        />
                      </span>
                      <span className="w-[95%] pt-[2px] pl-[10px]">
                        {state?.language?.result?.informations?.box14}
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full bg-white border border rounded-xl p-6 mt-4">
              <div className="flex w-full">
                <div className="w-full">
                  <div>
                    <p className="text-[#3A484D] lg:text-left text-[16px] font-semibold lg:px-5">
                      {state?.language?.result?.informations?.box5A}
                      <a
                        href="https://www.walloreno.be/assets/dist/documents/guide-pratique-pour-renover-votre-logement-avec-walloreno.pdf"
                        className="text-[#00A3DA]"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {state?.language?.result?.informations?.box5B}
                      </a>
                      {state?.language?.result?.informations?.box5C}
                    </p>
                  </div>
                  <div className="flex justify-center pt-2">
                    <a
                      href="https://www.walloreno.be/"
                      className="text-[#00A3DA]"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={Walloreno} className="h-20" alt="" />
                    </a>
                  </div>
                  <div className="flex justify-start pt-2 lg:px-5">
                    <a
                      className="text-[#00A3DA] text-left text-[14px] pt-4 font-semibold flex w-full"
                      href="https://energie.wallonie.be/fr/renover-mes-outils.html?IDC=6024&amp;IDD=81592"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span>
                        <img
                          src={BlueArrow}
                          alt=""
                          className="h-6 rotate-[-90deg]"
                        />
                      </span>
                      <span className="w-[95%] pt-[2px] pl-[10px]">
                        {state?.language?.result?.informations?.box22}
                      </span>
                    </a>
                  </div>
                  <div className="pt-2">
                    <div className="flex">
                      <span className="w-2 h-2 bg-[#00A3DA] rounded-full mt-2"></span>
                      <p className="text-[#3A484D] text-[14px] m-0 px-2 w-11/12">
                        {state?.language?.result?.informations?.box22Point1}
                      </p>
                    </div>
                    <div className="flex py-1">
                      <span className="w-2 h-2 bg-[#00A3DA] rounded-full mt-2"></span>
                      <p className="text-[#3A484D] text-[14px] m-0 px-2 w-11/12">
                        {state?.language?.result?.informations?.box22Point2}
                      </p>
                    </div>
                    <div className="flex">
                      <span className="w-2 h-2 bg-[#00A3DA] rounded-full mt-2"></span>
                      <p className="text-[#3A484D] text-[14px] m-0 px-2 w-11/12">
                        {state?.language?.result?.informations?.box22Point3}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
