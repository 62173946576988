import React, { FC, useContext, useState, useEffect } from "react";
import { GlobalContext } from "../../context/globalContext/globalContext";
import { setModalHandler } from "../../context/actions/modal";
import { storeStepValues } from "../../utils/forms";
import { setStepFormValues } from "../../context/actions/formsAction";
import { storelocalStorageData } from "../../utils/forms";
// section 1 icons
import HouseQ1 from "../../assets/icons/questionsForm/step4/ventillation.svg";
// section 2 icons
import HouseQ2 from "../../assets/icons/questionsForm/step4/air.svg";
// section 3 icons
import HouseQ3 from "../../assets/icons/questionsForm/step4/humidity.svg";
// Sections icons of this page
import BlueArrow from "../../assets/icons/questionsForm/step1/arrow-down-blue.svg";
import TransparentArrow from "../../assets/icons/questionsForm/step1/arrow-down-transparency.svg";
import NextArrow from "../../assets/icons/questionsForm/step1/arrow-next.svg";
import InfoIcon from "../../assets/icons/information-button.svg";
import { smoothScroll } from "../../utils/smoothScroll";
import { ScreenSize } from "../../utils/windowDimension";

interface Step4Interface {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  setShowAnimation: React.Dispatch<React.SetStateAction<string>>;
  selectedQuestion?: number;
  setSelectedQuestion?: any;
  formsRightQuestionsHeight?: any;
  formsLeftQuestionsHeight?: any;
  formHeightHandler?: any;
}

// FormOptionTypes types
type FormOptionTypes = {
  id: number;
  name: string;
  selected: string;
};

const Step4: FC<Step4Interface> = ({
  setStep,
  setShowAnimation,
  selectedQuestion,
  setSelectedQuestion,
  formsRightQuestionsHeight,
  formsLeftQuestionsHeight,
  formHeightHandler,
}) => {
  // global state
  const { state, dispatch }: any = useContext(GlobalContext);
  // list of step 1 options
  const formOptions: FormOptionTypes[] = [
    {
      id: 1,
      name:
        state?.language?.language === "fr"
          ? "Ventilation"
          : "Belüftung",
      selected:
        selectedQuestion === 1 ||
        selectedQuestion === 2 ||
        selectedQuestion === 3
          ? "true"
          : "false",
    },
    {
      id: 2,
      name: state?.language?.language === "fr" ? "Etanchéité à l’air" : "Luftdichtigkeit",
      selected:
        selectedQuestion === 2 || selectedQuestion === 3 ? "true" : "false",
    },
    {
      id: 3,
      name:
        state?.language?.language === "fr"
          ? "Humidité / Condensation"
          : "Feuchtigkeit/Kondensation",
      selected: selectedQuestion === 3 ? "true" : "false",
    },
  ];
  const screenSize: any = ScreenSize();
  const [step4, setStep4] = useState({
    question_one: {
      question_id: 26,
      question_title: "Y a-t-il un système de ventilation dans le logement ?",
      answer_id: 0,
      answer_title: "",
    },
    question_two: {
      question_id: 27,
      question_title:
        "Avez-vous l'impression que votre habitation présente des courants d'air ? ",
      answer_id: 0,
      answer_title: "",
    },
    question_three: {
      question_id: 28,
      question_title:
        "Votre habitation présente-t-elle des problèmes d'humidité ou de condensation ? ",
      answer_id: 0,
      answer_title: "",
    },
  });
  // variable for screen zoom
  const browserZoomLevel: any =
    (window.outerWidth / window.document.documentElement.clientWidth) * 100;

  // useEffect to check if data exsit in localStorage to show selectedData
  useEffect(() => {
    let localStorageData: any = localStorage.getItem("formData");
    let formData = JSON.parse(localStorageData);
    if (formData?.stepfour) {
      // to store in local and Global states
      setStep4(formData?.stepfour);
      setStepFormValues(formData?.stepfour, dispatch);
    }
    // window.scrollTo(0, 0);
  }, [dispatch]);
  useEffect(() =>{
    // window.scrollTo(0,0)
    document.getElementById('root')?.scrollIntoView();
  },[]);
  // handler to store values into Global state
  const submitHandler = () => {
    if (
      step4?.question_one?.answer_id === 0 ||
      step4?.question_two?.answer_id === 0 ||
      step4?.question_three?.answer_id === 0
    ) {
      setModalHandler(
        {
          content: "",
          modal: "formError",
        },
        dispatch
      );
    } else {
      setShowAnimation("animate-left5");
      let stepfour = { stepfour: step4, formStep: 5 };
      storelocalStorageData(stepfour);
      // to store in local and global states
      setStepFormValues(stepfour, dispatch);
      setStep(5);
      setSelectedQuestion(1);
    }
  };

  return (
    <div className="container mx-auto py-8 step1-container px-4">
      <div
        className={`flex flex-col lg:flex-row lg:justify-between items-center lg:items-start lg:flex-row ${
          screenSize?.width < 1500 && formHeightHandler("main")
        }`}
      >
        {/* left side */}
        <div
          className={`w-12/12 lg:w-[70%] lg:overflow-y-auto ${
            parseInt(browserZoomLevel) === 100 ||
            parseInt(browserZoomLevel) === 90 ||
            parseInt(browserZoomLevel) === 80
              ? formHeightHandler("left")
              : formsLeftQuestionsHeight("lg:h-[73vh]")
          }`}
        >
          {/* 1st card */}
          <div
            className={`w-full py-4 pl-4 pr-7 border-2 rounded-2xl mb-6 ${
              selectedQuestion === 1 ? "border-[#00A3DA]" : "border-[#E1F4FB]"
            }
          `}
          >
            {/* header */}
            <div className="w-full flex items-center justify-between">
              <div className="flex items-center w-11/12">
                <img src={HouseQ1} alt="" className="h-12" />
                <h5 className="text-[#4A4A4A] font-[600] px-6 text-[17px]">
                  {state?.language?.stepFour?.questionOne?.title} 
                  {/* --- {`{"question_id": ${step4.question_one.question_id}, "answer_id": ${step4.question_one.answer_id}}`} */}
                </h5>
              </div>
              <div
                className="cursor-pointer w-1/12 flex justify-end"
                onClick={() => {
                  setModalHandler(
                    {
                      content: "stepFour.questionOne",
                      modal: "step4",
                    },
                    dispatch
                  );
                }}
              >
                <img src={InfoIcon} alt="" className="h-5" />
              </div>
            </div>
            <div>
              {/*  */}
              <div className="pt-2 lg:px-6 lg:px-12">
                <label
                  className="radio-container mt-4"
                  onClick={() => {
                    storeStepValues(step4, "question_one", "", 73, setStep4);
                    setSelectedQuestion(2);
                    smoothScroll("content2");
                  }}
                >
                  <span className="text-[14px] font-normal text-[#3A484D] pt-[4px]">
                    {state?.language?.stepFour.questionOne?.answerOne}
                  </span>
                  <input
                    type="radio"
                    className=""
                    name="radio1"
                    onChange={() => {
                      storeStepValues(step4, "question_one", "", 73, setStep4);
                      setSelectedQuestion(2);
                      smoothScroll("content2");
                    }}
                    checked={
                      step4?.question_one?.answer_id === 73 ? true : false
                    }
                  />
                  <span className="checkmark checkbox-input mt-1"></span>
                </label>
                <label
                  className="radio-container mt-4"
                  onClick={() => {
                    storeStepValues(step4, "question_one", "", 74, setStep4);
                    setSelectedQuestion(2);
                    smoothScroll("content2");
                  }}
                >
                  <span className="text-[14px] font-normal text-[#3A484D] pt-[4px]">
                    {state?.language?.stepFour?.questionOne?.answerTwo}
                  </span>
                  <input
                    type="radio"
                    className=""
                    name="radio1"
                    onChange={() => {
                      storeStepValues(step4, "question_one", "", 74, setStep4);
                      setSelectedQuestion(2);
                      smoothScroll("content2");
                    }}
                    checked={
                      step4?.question_one?.answer_id === 74 ? true : false
                    }
                  />
                  <span className="checkmark checkbox-input mt-1"></span>
                </label>
                <label
                  className="radio-container mt-4"
                  onClick={() => {
                    storeStepValues(step4, "question_one", "", 75, setStep4);
                    setSelectedQuestion(2);
                    smoothScroll("content2");
                  }}
                >
                  <span className="text-[14px] font-normal text-[#3A484D] pt-[4px]">
                    {state?.language?.stepFour?.questionOne?.answerThree}
                  </span>
                  <input
                    type="radio"
                    className=""
                    name="radio1"
                    onChange={() => {
                      storeStepValues(step4, "question_one", "", 75, setStep4);
                      setSelectedQuestion(2);
                      smoothScroll("content2");
                    }}
                    checked={
                      step4?.question_one?.answer_id === 75 ? true : false
                    }
                  />
                  <span className="checkmark checkbox-input mt-1"></span>
                </label>
                <label
                  className="radio-container mt-4"
                  onClick={() => {
                    storeStepValues(step4, "question_one", "", 76, setStep4);
                    setSelectedQuestion(2);
                    smoothScroll("content2");
                  }}
                >
                  <span className="text-[14px] font-normal text-[#3A484D] pt-[4px]">
                    {state?.language?.stepFour?.questionOne?.answerFour}
                  </span>
                  <input
                    type="radio"
                    className=""
                    name="radio1"
                    onChange={() => {
                      storeStepValues(step4, "question_one", "", 76, setStep4);
                      setSelectedQuestion(2);
                      smoothScroll("content2");
                    }}
                    checked={
                      step4?.question_one?.answer_id === 76 ? true : false
                    }
                  />
                  <span className="checkmark checkbox-input mt-1"></span>
                </label>
              </div>
            </div>
          </div>
          {/* 2nd card */}
          <div
            className={`w-full py-4 pl-4 pr-7 border-2 rounded-2xl mb-6  ${
              selectedQuestion === 2 ? "border-[#00A3DA]" : "border-[#E1F4FB]"
            }
          `}
            id="content2"
          >
            {/* header */}
            <div className="w-full flex items-center justify-between">
              <div className="flex items-center w-11/12">
                <img src={HouseQ2} alt="" className="h-12" />
                <h5 className="text-[#4A4A4A] font-[600] px-6 text-[17px]">
                  {state?.language?.stepFour?.questionTwo?.title} 
                  {/* --- {`{"question_id": ${step4.question_two.question_id}, "answer_id": ${step4.question_two.answer_id}}`} */}
                </h5>
              </div>
              <div
                className="cursor-pointer w-1/12 flex justify-end"
                onClick={() => {
                  setModalHandler(
                    {
                      content: "stepFour.questionTwo",
                      modal: "step4",
                    },
                    dispatch
                  );
                }}
              >
                <img src={InfoIcon} alt="" className="h-5" />
              </div>
            </div>
            <div>
              {/*  */}
              <div className="pt-2 lg:px-6 lg:px-12">
                <label
                  className="radio-container mt-4"
                  onClick={() => {
                    storeStepValues(step4, "question_two", "", 77, setStep4);
                    setSelectedQuestion(3);
                    smoothScroll("content3");
                  }}
                >
                  <span className="text-[14px] font-normal text-[#3A484D] pt-[3px]">
                    {state?.language?.stepFour.questionTwo?.answerOne}
                  </span>
                  <input
                    type="radio"
                    className=""
                    name="radio2"
                    onChange={() => {
                      storeStepValues(step4, "question_two", "", 77, setStep4);
                      setSelectedQuestion(3);
                      smoothScroll("content3");
                    }}
                    checked={
                      step4?.question_two?.answer_id === 77 ? true : false
                    }
                  />
                  <span className="checkmark checkbox-input mt-1"></span>
                </label>
                <label
                  className="radio-container mt-4"
                  onClick={() => {
                    storeStepValues(step4, "question_two", "", 78, setStep4);
                    setSelectedQuestion(3);
                    smoothScroll("content3");
                  }}
                >
                  <span className="text-[14px] font-normal text-[#3A484D] pt-[3px]">
                    {state?.language?.stepFour?.questionTwo?.answerTwo}
                  </span>
                  <input
                    type="radio"
                    className=""
                    name="radio2"
                    onChange={() => {
                      storeStepValues(step4, "question_two", "", 78, setStep4);
                      setSelectedQuestion(3);
                      smoothScroll("content3");
                    }}
                    checked={
                      step4?.question_two?.answer_id === 78 ? true : false
                    }
                  />
                  <span className="checkmark checkbox-input mt-1"></span>
                </label>
                <label
                  className="radio-container mt-4"
                  onClick={() => {
                    storeStepValues(step4, "question_two", "", 79, setStep4);
                    setSelectedQuestion(3);
                    smoothScroll("content3");
                  }}
                >
                  <span className="text-[14px] font-normal text-[#3A484D] pt-[3px]">
                    {state?.language?.stepFour?.questionTwo?.answerThree}
                  </span>
                  <input
                    type="radio"
                    className=""
                    name="radio2"
                    onChange={() => {
                      storeStepValues(step4, "question_two", "", 79, setStep4);
                      setSelectedQuestion(3);
                      smoothScroll("content3");
                    }}
                    checked={
                      step4?.question_two?.answer_id === 79 ? true : false
                    }
                  />
                  <span className="checkmark checkbox-input mt-1"></span>
                </label>
                <label
                  className="radio-container mt-4"
                  onClick={() => {
                    storeStepValues(step4, "question_two", "", 80, setStep4);
                    setSelectedQuestion(3);
                    smoothScroll("content3");
                  }}
                >
                  <span className="text-[14px] font-normal text-[#3A484D] pt-[3px]">
                    {state?.language?.stepFour?.questionTwo?.answerFour}
                  </span>
                  <input
                    type="radio"
                    className=""
                    name="radio2"
                    onChange={() => {
                      storeStepValues(step4, "question_two", "", 80, setStep4);
                      setSelectedQuestion(3);
                      smoothScroll("content3");
                    }}
                    checked={
                      step4?.question_two?.answer_id === 80 ? true : false
                    }
                  />
                  <span className="checkmark checkbox-input mt-1"></span>
                </label>
                <label
                  className="radio-container mt-4"
                  onClick={() => {
                    storeStepValues(step4, "question_two", "", 81, setStep4);
                    setSelectedQuestion(3);
                    smoothScroll("content3");
                  }}
                >
                  <span className="text-[14px] font-normal text-[#3A484D] pt-[3px]">
                    {state?.language?.stepFour?.questionTwo?.answerFive}
                  </span>
                  <input
                    type="radio"
                    className=""
                    name="radio2"
                    onChange={() => {
                      storeStepValues(step4, "question_two", "", 81, setStep4);
                      setSelectedQuestion(3);
                      smoothScroll("content3");
                    }}
                    checked={
                      step4?.question_two?.answer_id === 81 ? true : false
                    }
                  />
                  <span className="checkmark checkbox-input mt-1"></span>
                </label>
              </div>
            </div>
          </div>
          {/* 3rd card */}
          <div
            className={`w-full py-4 pl-4 pr-7 border-2 rounded-2xl mb-6 ${
              selectedQuestion === 3 ? "border-[#00A3DA]" : "border-[#E1F4FB]"
            }`}
            id="content3"
          >
            {/* header */}
            <div className="w-full flex items-center justify-between">
              <div className="flex items-center w-11/12">
                <img src={HouseQ3} alt="" className="h-12" />
                <h5 className="text-[#4A4A4A] font-[600] px-6 text-[17px]">
                  {state?.language?.stepFour?.questionThree?.title}
                   {/* --- {`{"question_id": ${step4.question_three.question_id}, "answer_id": ${step4.question_three.answer_id}}`} */}
                </h5>
              </div>
              <div
                className="cursor-pointer w-1/12 flex justify-end"
                onClick={() => {
                  setModalHandler(
                    {
                      content: "stepFour.questionThree",
                      modal: "step4",
                    },
                    dispatch
                  );
                }}
              >
                <img src={InfoIcon} alt="" className="h-5" />
              </div>
            </div>
            <div>
              {/*  */}
              <div className="pt-2 lg:px-6 lg:px-12">
                <label
                  className="radio-container mt-4"
                  onClick={() => {
                    storeStepValues(step4, "question_three", "", 82, setStep4);
                    smoothScroll("content4");
                  }}
                >
                  <span className="text-[14px] font-normal text-[#3A484D] pt-[3px]">
                    {state?.language?.stepFour.questionThree?.answerOne}
                  </span>
                  <input
                    type="radio"
                    className=""
                    name="radio3"
                    onChange={() => {
                      storeStepValues(
                        step4,
                        "question_three",
                        "",
                        82,
                        setStep4
                      );
                      smoothScroll("content4");
                    }}
                    checked={
                      step4?.question_three?.answer_id === 82 ? true : false
                    }
                  />
                  <span className="checkmark checkbox-input mt-1"></span>
                </label>
                <label
                  className="radio-container mt-4"
                  onClick={() => {
                    storeStepValues(step4, "question_three", "", 83, setStep4);
                    smoothScroll("content4");
                  }}
                >
                  <span className="text-[14px] font-normal text-[#3A484D] pt-[3px]">
                    {state?.language?.stepFour?.questionThree?.answerTwo}
                  </span>
                  <input
                    type="radio"
                    className=""
                    name="radio3"
                    onChange={() => {
                      storeStepValues(
                        step4,
                        "question_three",
                        "",
                        83,
                        setStep4
                      );
                      smoothScroll("content4");
                    }}
                    checked={
                      step4?.question_three?.answer_id === 83 ? true : false
                    }
                  />
                  <span className="checkmark checkbox-input mt-1"></span>
                </label>
                <label
                  className="radio-container mt-4"
                  onClick={() => {
                    storeStepValues(step4, "question_three", "", 84, setStep4);
                    smoothScroll("content4");
                  }}
                >
                  <span className="text-[14px] font-normal text-[#3A484D] pt-[3px]">
                    {state?.language?.stepFour?.questionThree?.answerThree}
                  </span>
                  <input
                    type="radio"
                    className=""
                    name="radio3"
                    onChange={() => {
                      storeStepValues(
                        step4,
                        "question_three",
                        "",
                        84,
                        setStep4
                      );
                      smoothScroll("content4");
                    }}
                    checked={
                      step4?.question_three?.answer_id === 84 ? true : false
                    }
                  />
                  <span className="checkmark checkbox-input mt-1"></span>
                </label>
                <label
                  className="radio-container mt-4"
                  onClick={() => {
                    storeStepValues(step4, "question_three", "", 85, setStep4);
                    smoothScroll("content4");
                  }}
                >
                  <span className="text-[14px] font-normal text-[#3A484D] pt-[3px]">
                    {state?.language?.stepFour?.questionThree?.answerFour}
                  </span>
                  <input
                    type="radio"
                    className=""
                    name="radio3"
                    onChange={() => {
                      storeStepValues(
                        step4,
                        "question_three",
                        "",
                        85,
                        setStep4
                      );
                      smoothScroll("content4");
                    }}
                    checked={
                      step4?.question_three?.answer_id === 85 ? true : false
                    }
                  />
                  <span className="checkmark checkbox-input mt-1"></span>
                </label>
                <label
                  className="radio-container mt-4"
                  onChange={() => {
                    storeStepValues(step4, "question_three", "", 86, setStep4);
                    smoothScroll("content4");
                  }}
                >
                  <span className="text-[14px] font-normal text-[#3A484D] pt-[3px]">
                    {state?.language?.stepFour?.questionThree?.answerFive}
                  </span>
                  <input
                    type="radio"
                    className=""
                    name="radio3"
                    onChange={() => {
                      storeStepValues(
                        step4,
                        "question_three",
                        "",
                        86,
                        setStep4
                      );
                      smoothScroll("content4");
                    }}
                    checked={
                      step4?.question_three?.answer_id === 86 ? true : false
                    }
                  />
                  <span className="checkmark checkbox-input mt-1"></span>
                </label>
              </div>
            </div>
          </div>
        </div>
        {/* right side */}
        <div
          className={`w-full lg:w-3/12 flex flex-col justify-between items-center lg:pb-6 ${formsRightQuestionsHeight()}`}
          id="content4"
        >
          <div className="hidden lg:block">
            {/*active arrow */}
            {formOptions?.map((item, index) => (
              <div className="flex" key={item?.id}>
                <div>
                  <img
                    src={
                      item?.selected === "true" ? BlueArrow : TransparentArrow
                    }
                    className="h-8"
                    alt=""
                  />
                  {/* line between image */}
                  {item?.id !== 3 && (
                    <div className="flex justify-center">
                      <div
                        className={`w-[2px] h-6 
                         ${
                           item?.selected === "true" &&
                           formOptions[index + 1].selected === "true"
                             ? "bg-[#01a2da]"
                             : "bg-[#E7F6FD]"
                         }
                      `}
                      ></div>
                    </div>
                  )}
                </div>
                {
                  <div className="px-4 pt-1">
                    <p className="text-[#3A484D]">{item?.name}</p>
                  </div>
                }
              </div>
            ))}
          </div>
          {/* buttons */}
          <div className="flex flex-row lg:flex-col justify-between w-full lg:w-8/12 px-2">
            <div className="lg:w-12/12 lg:py-4">
              <button
                className="bg-white hover:bg-[#eee] border w-full rounded-full py-[10px] text-[#3A484D] text-[15px] px-10"
                onClick={() => {
                  setStep(3);
                  setShowAnimation("animate-right3");
                }}
              >
                {state?.language?.stepButton?.previous}
              </button>
            </div>
            <div className="lg:w-12/12">
              <button
                className="bg-[#01a2da] hover:bg-[#0590c1] w-full rounded-full py-[10px] text-white text-[15px] pr-10 pl-5"
                onClick={() => submitHandler()}
              >
                {state?.language?.stepButton?.next}
                <span className="absolute ml-[8px] lg:ml-[20px] mt-[6.5px]">
                  <img src={NextArrow} className="h-3" alt="" />
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step4;
