import React, { FC, useContext } from "react";
import { GlobalContext } from "../../context/globalContext/globalContext";
// images of step1
import HouseQ1 from "../../assets/icons/pdfStep/solar-panels.png";
import { epcColorHandler } from "../../utils/epcColors";
import { epcImageHandler } from "../../utils/result";


interface Step3Interface {}

const Step3: FC<Step3Interface> = () => {
  // Global state
  const { state }: any = useContext(GlobalContext);
  return (
    <div className={`mr-2 ml-2 mx-auto pt-7 pb-[140px]`}>
      {/* step 1 title box */}
      <div
        className={`w-full rounded-3xl py-3 `}
        style={{
          backgroundColor: epcColorHandler(
            state?.visitedUserData?.visitor_result?.ecolabel_step3
          ),
        }}
      >
        <div className="pb-2">
          <img
            className="absolute right-0 h-10 font-semibold text-white mr-[10%] mt-[7px]"
            alt="epc-label"
            src={epcImageHandler(
              state?.visitedUserData?.visitor_result?.ecolabel_step3?.toLowerCase()
            )}
          />
          <h5 className="text-center text-[30px] font-semibold text-white m-0">
            {state?.language?.pdf?.step3Title}
          </h5>
        </div>
      </div>
      <div className="py-4 flex flex-wrap">
        {/* 1st */}
        <div className="w-6/12 pr-2">
          <div className="w-full pt-4 pb-2 border-2 border-[#E1F4FB] px-6 rounded-xl h-full ">
            <div className="w-full flex flex-row  pb-6">
              {/* left side */}
              <div className="w-full lg:w-2/12 flex justify-center">
                <img src={HouseQ1} alt="" className="h-20" />
              </div>
              {/* right side */}
              <div className="w-full px-6 pt-4 w-10/12">
                <h5 className="text-[#3A484D] text-[18px] font-semibold">
                  {state?.language?.pdf?.step3Box1Title}
                </h5>
                {state?.visitedUserData?.pdf_data?.text12 !== undefined &&
                  state?.visitedUserData?.pdf_data?.text12 !== false && (
                    <p className="text-[#3A484D] text-[16px] pt-2">
                      {state?.language?.language === "fr" ? state?.visitedUserData?.pdf_data?.text12 : state?.visitedUserData?.pdf_data_de?.text12}
                    </p>
                  )}
                <p className="text-[#3A484D] text-[16px] pt-2">
                  {state?.visitedUserData?.pdf_data?.textstep3 !== false
                    ? state?.language?.language === "fr"
                      ? state?.visitedUserData?.pdf_data?.textstep3
                      : state?.visitedUserData?.pdf_data_de?.textstep3
                    : state?.language?.pdf?.step3Box1Content2}
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* 2nd */}
        <div className="w-6/12 pl-2">
          <div
            className={`w-full py-2 border-2 px-6 rounded-xl h-full `}
            style={{
              borderColor: epcColorHandler(
                state?.visitedUserData?.visitor_result?.ecolabel_step3
              ),
            }}
          >
            <div className="w-full pt-4">
              <div className="w-full">
                <h5
                  className="text-center text-[20px] font-semibold"
                  style={{
                    color: epcColorHandler(
                      state?.visitedUserData?.visitor_result?.ecolabel_step3
                    ),
                  }}
                >
                  {state?.language?.pdf?.step3Box2Title}
                </h5>
                <p
                  className="text-center text-[30px] pt-2"
                  style={{
                    color: epcColorHandler(
                      state?.visitedUserData?.visitor_result?.ecolabel_step3
                    ),
                  }}
                >
                  {state?.visitedUserData?.visitor_result?.ecolabel_step3_value}{" "}
                  {state?.language?.language === "fr" ? "kWh/m².an" : "kWh/m².Jahr"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step3;
