// validations for different steps
// step 3
export const step3ValidationHandler = (step3: any, toggle: any, state: any) => {


  let validationOptions: string[] = [];
  //  section 1
  if (step3?.question_one?.answer_id === 0) {
    validationOptions?.push(state?.language?.stepThree.questionOne.title);
  }
  //  section 2
  //  flow 1
  if (step3?.question_two?.answer_id === 0) {
    validationOptions?.push(state?.language?.stepThree.questionTwo.title);
  }
  //  flow 2
  else if (step3?.question_two?.answer_id !== 0) {
    if (step3?.question_two?.answer_id === 30 && toggle?.sectioTwoCheckBoxOne) {
      validationOptions?.push(state?.language?.stepThree.questionTwoSubOne.title);
    }
    if (
      step3?.question_two?.answer_id === 30 &&
      !toggle?.sectioTwoCheckBoxOne &&
      step3?.question_three?.answer_id === 0 &&
      !toggle?.sectioTwoCheckBoxTwo
    ) {
      validationOptions?.push(state?.language?.stepThree.questionTwoSubOne.title);
    }
    if (
      step3?.question_two?.answer_id === 30 &&
      !toggle?.sectioTwoCheckBoxOne &&
      step3?.question_three?.answer_id === 0 &&
      toggle?.sectioTwoCheckBoxTwo &&
      step3?.question_four?.answer_id === 0
    ) {
      validationOptions?.push(state?.language?.stepThree.questionTwoSubTwo.title);
    }
  }
  //  section 3
  if (
    //  flow 1
    step3?.question_six?.answer_id === 0
  ) {
    validationOptions?.push(state?.language?.stepThree.questionThree.title);
  } else if (step3?.question_six?.answer_id !== 0) {
    if (
      step3?.question_six?.answer_id === 42 &&
      toggle?.sectioThreeCheckBoxOne
    ) {
      validationOptions?.push(state?.language?.stepThree.questionThreeSubOne.title);
    }
    if (
      //  flow 2
      step3?.question_six?.answer_id === 42 &&
      !toggle?.sectioThreeCheckBoxOne &&
      step3?.question_seven?.answer_id === 0 &&
      !toggle?.sectioThreeCheckBoxTwo
    ) {
      validationOptions?.push(state?.language?.stepThree.questionThreeSubOne.title);
    }
    if (
      //  flow 3
      step3?.question_six?.answer_id === 42 &&
      !toggle?.sectioThreeCheckBoxOne &&
      step3?.question_seven?.answer_id === 0 &&
      toggle?.sectioThreeCheckBoxTwo &&
      step3?.question_eight?.answer_id === 0
    ) {
      validationOptions?.push(state?.language?.stepThree.questionThreeSubTwo.title);
    }
    if (
      step3?.question_six?.answer_id === 42 &&
      !toggle?.sectioThreeCheckBoxOne &&
      step3?.question_seven?.answer_id === 0 &&
      toggle?.sectioThreeCheckBoxTwo &&
      step3?.question_eight?.answer_id === 50 &&
      step3?.question_nine?.answer_id === 0
    ) {
      validationOptions?.push(state?.language?.stepThree.questionThreeSubThree.title);
    }
  }
  //  section 4
  if (step3?.question_ten?.answer_id === 0) {
    validationOptions?.push(state?.language?.stepThree.questionFour.title);
  } else if (step3?.question_ten?.answer_id !== 0) {
    if (
      step3?.question_ten?.answer_id === 56 &&
      toggle?.sectioFourCheckBoxOne
    ) {
      validationOptions?.push(state?.language?.stepThree.questionFourSubOne.title);
    }
    if (
      step3?.question_ten?.answer_id === 56 &&
      !toggle?.sectioFourCheckBoxOne &&
      step3?.question_eleven?.answer_id === 0 &&
      !toggle?.sectioFourCheckBoxTwo
    ) {
      validationOptions?.push(state?.language?.stepThree.questionFourSubOne.title);
    }
    if (
      step3?.question_ten?.answer_id === 56 &&
      !toggle?.sectioFourCheckBoxOne &&
      step3?.question_eleven?.answer_id === 0 &&
      toggle?.sectioFourCheckBoxTwo &&
      step3?.question_twelve?.answer_id === 0
    ) {
      validationOptions?.push(state?.language?.stepThree.questionFourSubTwo.title);
    }
    if (
      step3?.question_ten?.answer_id === 56 &&
      !toggle?.sectioFourCheckBoxOne &&
      step3?.question_eleven?.answer_id === 0 &&
      toggle?.sectioFourCheckBoxTwo &&
      step3?.question_twelve?.answer_id === 66 &&
      step3?.question_thirteen?.answer_id === 0
    ) {
      validationOptions?.push(state?.language?.stepThree.questionFourSubThree.title);
    }
  }
  return validationOptions;
};
// step 7
export const step4ValidationHandler = (step7: any, toggle: any, state: any) => {
  let validationOptions: string[] = [];
  if (step7?.question_two?.answer_id === 0) {
    validationOptions?.push(state?.language?.stepSeven.questionOne.title);
  } else if (step7?.question_two?.answer_id !== 0) {
    if (
      step7?.question_two?.answer_id === 112 &&
      step7?.question_three?.answer_id === 0 &&
      !toggle?.sectioOneCheckBoxTwo
    ) {
      validationOptions?.push(state?.language?.stepSeven.questionTwoSubOne.title);
    }
    if (
      step7?.question_two?.answer_id === 112 &&
      toggle?.sectioOneCheckBoxTwo &&
      step7?.question_four?.answer_id === 0
    ) {
      validationOptions?.push(state?.language?.stepSeven.questionTwoSubTwo.title);
    }
  }
  return validationOptions;
};
