import React, { FC, useContext } from "react";
import { GlobalContext } from "../../context/globalContext/globalContext";
// import Logo from "../../assets/logo/quickscan_color.png";
import Logo from "../../assets/logo/quickscan_color.png";
import Img1 from "../../assets/icons/bereel.png";
import Img2 from "../../assets/icons/walloreno.png";
import Img3 from "../../assets/icons/spw.png";
import Img4 from "../../assets/icons/life.png";

interface FooterInterface {}

const Footer: FC<FooterInterface> = () => {
  // global state
  const { state }: any = useContext(GlobalContext);
  return (
    <div className="pt-5 fixed bottom-0 w-full z-10" id="footer">
      <div className="w-full px-6 py-7 bg-[#E7F6FB]" style={{paddingRight:"2.5cm", paddingLeft:"2.5cm"}}>
        <div className="w-full mx-auto" >
          <div className="flex justify-between items-center">
            <div className="w-5/12">
              <div>
                <img src={Logo} alt="" className="h-12" />
              </div>
              <div>
                <p className="text-left font-medium pt-2 text-[#3A484D] text-[14px]">
                  {state?.language?.pdf?.footerLink} : www.monquickscan.be
                </p>
              </div>
            </div>
            <div className="flex justify-around items-center pl-3 pr-10 w-6/12">
              <a
                className="cursor-pointer"
                href="https://www.be-reel.be/home"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={Img1}
                  // src={require("../../assets/icons/bereel.png")}
                  alt=""
                  className="h-[6rem]"
                />
              </a>
              <a
                className="cursor-pointer"
                href="https://www.walloreno.be/fr/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={Img2}
                  // src={require("../../assets/icons/walloreno.png")}
                  alt=""
                  className="h-[6rem]"
                />
              </a>
              <a
                className="cursor-pointer"
                href="https://energie.wallonie.be/fr/index.html?IDC=6018"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={Img3}
                  // src={require("../../assets/icons/spw.png")}
                  alt=""
                  className="h-[5.5rem]"
                />
              </a>
              <a
                className="cursor-pointer"
                href="https://cinea.ec.europa.eu/programmes/life_en"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={Img4}
                  // src={require("../../assets/icons/life.png")}
                  alt=""
                  className="h-[4rem]"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
