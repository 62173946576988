import { GetAPIService, PostAPIService } from "../services";

//  to store visited user data of result page data
export const visitedUserData = (data: any, dispatch: any) => {
  dispatch({
    type: "SET_VISITED_USER_DATA",
    payload: data,
  });
};

//  handler to get all step 2 houses data
export const getVisitedUserData = (id: any) => {
  return GetAPIService(`/visitors/results/${id}`);
};

//  handler to get all step 2 houses data
export const sendEmailByService = (payload: any) => {
  return PostAPIService(`/sendEmail`, payload);
};

//  handler to get all step 2 houses data
export const downloadPDF = (payload: any) => {
  return PostAPIService(`/pdf_generate`, payload);
};
