import React, { FC } from "react";
import Header from "./header";
import Footer from "./footer";
import HouseScale from "./houseScale";
import HomeImprovement from "./homeImprovement";
import Projects from "./projects";
import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";

interface PDFInterface {}

const PDF: FC<PDFInterface> = () => {
  return (
    <>
      {/* page 1 */}
      <div style={{ pageBreakInside: "avoid", marginRight:"2.5cm",marginLeft:"2.5cm" }}>
        <Header />
        <HouseScale />
        <HomeImprovement />
        <Projects />
      </div>
      {/* page 2 */}
      <div style={{ pageBreakAfter: "always" }} />

      <div style={{ pageBreakInside: "avoid",marginRight:"2.5cm",marginLeft:"2.5cm" }}>
        <Header />
        <Step1 />
        <Step2 />
        <Step3 />
      </div>

      <Footer />
    </>
  );
};

export default PDF;
