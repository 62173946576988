import React, { FC, useContext } from "react";
import { GlobalContext } from "../../context/globalContext/globalContext";
import { useLocation } from "react-router-dom";

interface FooterInterface {}

const Footer: FC<FooterInterface> = () => {
  const location = useLocation();
  // global state
  const { state }: any = useContext(GlobalContext);
  return (
    <div>
      {/* footer section 1 */}
      <div className="py-1 px-2 bg-[#3a484d]">
        {/* desktop */}
        <div className="desktop-footerContent-container lg:container lg:mx-auto w-full hidden lg:flex flex-wrap justify-around text-white lg:items-center">
          {/* lists */}
          <div className="w-fit specificScreen-width">
            <a
              className="text-[15px] hover:text-[#dfdbdb] font-[400] cursor-pointer"
              href="https://www.autoriteprotectiondonnees.be/citoyen/vie-privee/quels-sont-mes-droits-"
              target="_blank"
              rel="noreferrer"
            >
              {
                state?.language?.OfficialObjectives?.footerboxheader?.site
                  ?.title
              }
              <span className="pl-5 pr-2">
                {
                  state?.language?.OfficialObjectives?.footerboxheader?.site
                    ?.site1
                }
              </span>
            </a>
          </div>
          <div className="specificScreen-width lg:w-2/12 px-7 lg:border-r lg:border-l lg:border-white text-right lg:text-left">
            <a
              className="text-[15px] hover:text-[#dfdbdb] font-[400] cursor-pointer"
              href="mailto:celine.renard@spw.wallonie.be"
              target="_blank"
              rel="noreferrer"
            >
              {
                state?.language?.OfficialObjectives?.footerboxheader?.site
                  ?.site3
              }
            </a>
          </div>
          <div className="specificScreen-width w-full lg:w-4/12 py-4 lg:py-0 lg:px-7 font-[400] lg:border-r lg:border-white flex flex-wrap justify-between">
            <p className="text-[15px] pr-5">
              {
                state?.language?.OfficialObjectives?.footerboxheader?.utiles
                  ?.title
              }
            </p>
            <a
              className="text-[15px] hover:text-[#dfdbdb] cursor-pointer"
              href="https://www.walloreno.be/fr/"
              target="_blank"
              rel="noreferrer"
            >
              {
                state?.language?.OfficialObjectives?.footerboxheader?.utiles
                  ?.array[0]
              }
            </a>
            <a
              className="text-[15px] hover:text-[#dfdbdb] cursor-pointer"
              href="https://energie.wallonie.be/fr/index.html?IDC=6018"
              target="_blank"
              rel="noreferrer"
            >
              {
                state?.language?.OfficialObjectives?.footerboxheader?.utiles
                  ?.array[1]
              }
            </a>
            <a
              className="text-[15px] hover:text-[#dfdbdb] cursor-pointer"
              href="https://www.be-reel.be/home"
              target="_blank"
              rel="noreferrer"
            >
              {
                state?.language?.OfficialObjectives?.footerboxheader?.utiles
                  ?.array[3]
              }
            </a>
          </div>
          <div className="specificScreen-width  lg:px-2 flex flex-wrap font-[400] justify-between lg:items-center">
            <a
              href="https://www.agifly.be/"
              target="_blank"
              rel="noreferrer"
              className="text-[15px] hover:text-[#dfdbdb] cursor-pointer flex"
            >
              <span className="text-[15px] hover:text-[#dfdbdb] cursor-pointer pr-2">
                {
                  state?.language?.OfficialObjectives?.footerboxheader
                    ?.developer?.title
                }
              </span>
              <span>
                <img
                  src={require("../../assets/icons/agifly.png")}
                  alt=""
                  className="h-5"
                />
              </span>
            </a>
          </div>
          <div className="specificScreen-width lg:px-2 flex flex-wrap font-[400] justify-between lg:items-center">
            <a
              className="flex lg:items-center text-[15px] hover:text-[#dfdbdb] cursor-pointer"
              href="https://www.maisonpassive.be/"
              target="_blank"
              rel="noreferrer"
            >
              <span className="text-[15px] pl-1 pr-2">
                {
                  state?.language?.OfficialObjectives?.footerboxheader
                    ?.developer?.bottom
                }
              </span>
              <span>
                <img
                  src={require("../../assets/icons/pmp.png")}
                  alt=""
                  className="h-12 mt-[-8px] lg:mt-[-3px]"
                />
              </span>
            </a>
          </div>
        </div>
        {/* mobile */}
        <div className="container mx-auto w-full flex flex-wrap lg:hidden justify-between text-white items-center">
          {/* lists */}
          <a
            className="text-[14px] hover:text-[#dfdbdb] cursor-pointer lg:px-2 py-1"
            href="https://www.autoriteprotectiondonnees.be/citoyen/vie-privee/quels-sont-mes-droits-"
            target="_blank"
            rel="noreferrer"
          >
            {state?.language?.OfficialObjectives?.footerboxheader?.site?.title}
            <span className="px-2">
              {
                state?.language?.OfficialObjectives?.footerboxheader?.site
                  ?.site1
              }
            </span>
          </a>
          <a
            className="text-[14px] hover:text-[#dfdbdb] cursor-pointer px-[0.8rem] lg:px-2 py-1 border-l border-white border-r"
            href="mailto:celine.renard@spw.wallonie.be"
            target="_blank"
            rel="noreferrer"
          >
            {state?.language?.OfficialObjectives?.footerboxheader?.site?.site3}
          </a>
          <p className="text-[14px] lg:pr-2 py-1 ">
            {
              state?.language?.OfficialObjectives?.footerboxheader?.utiles
                ?.title
            }
          </p>
          <a
            className="text-[14px] hover:text-[#dfdbdb] cursor-pointer lg:pl-2 py-2"
            href="https://www.walloreno.be/fr/"
            target="_blank"
            rel="noreferrer"
          >
            {
              state?.language?.OfficialObjectives?.footerboxheader?.utiles
                ?.array[0]
            }
          </a>
          <a
            className="text-[14px] hover:text-[#dfdbdb] cursor-pointer lg:pl-2 py-1"
            href="https://energie.wallonie.be/fr/index.html?IDC=6018"
            target="_blank"
            rel="noreferrer"
          >
            {
              state?.language?.OfficialObjectives?.footerboxheader?.utiles
                ?.array[1]
            }
          </a>
          <a
            className="text-[14px] hover:text-[#dfdbdb] cursor-pointer lg:pl-2 py-1"
            href="https://www.be-reel.be/home"
            target="_blank"
            rel="noreferrer"
          >
            {
              state?.language?.OfficialObjectives?.footerboxheader?.utiles
                ?.array[3]
            }
          </a>
          <a
            href="https://www.agifly.be/"
            target="_blank"
            rel="noreferrer"
            className="text-[14px] hover:text-[#dfdbdb] cursor-pointer flex lg:pl-2 py-1"
          >
            <span className="text-[14px] hover:text-[#dfdbdb] cursor-pointer pr-2">
              {
                state?.language?.OfficialObjectives?.footerboxheader?.developer
                  ?.title
              }
            </span>
            <span>
              <img
                src={require("../../assets/icons/agifly.png")}
                alt=""
                className="h-5"
              />
            </span>
          </a>
          <a
            className="flex items-center text-[14px] hover:text-[#dfdbdb] lg:pl-2 cursor-pointer"
            href="https://www.maisonpassive.be/"
            target="_blank"
            rel="noreferrer"
          >
            <span className="text-[14px] pr-2">
              {
                state?.language?.OfficialObjectives?.footerboxheader?.developer
                  ?.bottom
              }
            </span>
            <span>
              <img
                src={require("../../assets/icons/pmp.png")}
                alt=""
                className="h-8"
              />
            </span>
          </a>
        </div>
      </div>
      {/* footer section 2 */}
      {location?.pathname === "/" && (
        <div className="w-full px-2 lg:px-6 py-4 bg-white">
          <div>
            <p className="text-center font-normal py-2 text-[#3A484D] font-[300]">
              {
                state?.language?.OfficialObjectives?.footerboxheader
                  ?.bottomMessage?.title
              }
            </p>
          </div>
          <div className="flex flex-wrap justify-around py-7 px-3">
            <a
              className="cursor-pointer"
              href="https://www.be-reel.be/home"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={require("../../assets/icons/bereel.png")}
                alt=""
                className="h-12 lg:h-[4rem]"
              />
            </a>
            <a
              className="cursor-pointer"
              href="https://www.walloreno.be/fr/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={require("../../assets/icons/walloreno.png")}
                alt=""
                className="h-10 lg:h-[4rem]"
              />
            </a>
            <a
              className="cursor-pointer"
              href="https://energie.wallonie.be/fr/index.html?IDC=6018"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={require("../../assets/icons/spw.png")}
                alt=""
                className="h-14 lg:h-[4.5rem]"
              />
            </a>
            <a
              className="cursor-pointer"
              href="https://cinea.ec.europa.eu/programmes/life_en"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={require("../../assets/icons/life.png")}
                alt=""
                className="h-12 lg:h-[4rem]"
              />
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default Footer;
