import React, { FC, useContext, useState } from "react";
import { GlobalContext } from "../../context/globalContext/globalContext";
import { useParams } from "react-router-dom";
import { sendEmailByService } from "../../context/actions/resultPage";
import { setModalHandler } from "../../context/actions/modal";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
// icnon
import SendIcon from "../../assets/icons/social_icons/send.png";
import Facebook from "../../assets/icons/social_icons/facebook.png";
import Link from "../../assets/icons/social_icons/link.png";
import LinkedIn from "../../assets/icons/social_icons/linkedin.png";
import Twitter from "../../assets/icons/social_icons/twitter.png";
// EPC icons
import EPC_APlus_Plus from "../../assets/icons/epc_scales/peb-aplusplus.svg";
import EPC_APlus from "../../assets/icons/epc_scales/peb-aplus.svg";
import EPC_A from "../../assets/icons/epc_scales/peb-a.svg";
import EPC_B from "../../assets/icons/epc_scales/peb-b.svg";
import EPC_C from "../../assets/icons/epc_scales/peb-c.svg";
import EPC_D from "../../assets/icons/epc_scales/peb-d.svg";
import EPC_E from "../../assets/icons/epc_scales/peb-e.svg";
import EPC_F from "../../assets/icons/epc_scales/peb-f.svg";
import EPC_G from "../../assets/icons/epc_scales/peb-g.svg";

interface BannerInterface {
  setNotificationState?: any;
}

const Banner: FC<BannerInterface> = ({ setNotificationState }) => {
  // global state
  const { state, dispatch }: any = useContext(GlobalContext);
  // to get id from url
  const params = useParams();
  // common url to share with social accounts
  const shareUrl = `${process.env.REACT_APP_STAGING_URL}`;
  const title = "Quickscan - Résultat de mon habitation";
  // state to save email
  const [email, setEmail] = useState();
  const [loader, setLoader] = useState(false);
  console.log(
    "process.env.REACT_APP_DEVELOP_URL",
    process.env.REACT_APP_DEVELOP_URL
  );
  // send email handler
  const sendEmailHandler = () => {
    setLoader(true);
    let payload = {
      email: email,
      id: params?.id,
      language: state?.language?.language,
    };
    if (!email) {
      setModalHandler(
        {
          content: "",
          modal: "sendEmailError",
        },
        dispatch
      );
      setLoader(false);
    } else {
      sendEmailByService(payload)
        .then((response) => {
          setModalHandler(
            {
              content: "",
              modal: "sendEmailSuccess",
            },
            dispatch
          );
          setLoader(false);
        })
        .catch((error) => {
          console.log("error", error);
          setModalHandler(
            {
              content: "sqvvds",
              modal: "sendEmailError2",
            },
            dispatch
          );
          setLoader(false);
        });
    }
  };
  // function to copy url
  const copyHandler = () => {
    const el = document.createElement("textarea");
    el.value = process.env.REACT_APP_DEVELOP_URL + "/result/" + params?.id;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setNotificationState(true);
  };
  // function to return selected epc
  const epcImageHandler = (name: string) => {
    switch (name) {
      case "a++":
        return EPC_APlus_Plus;
      case "a+":
        return EPC_APlus;
      case "a":
        return EPC_A;
      case "b":
        return EPC_B;
      case "c":
        return EPC_C;
      case "d":
        return EPC_D;
      case "e":
        return EPC_E;
      case "f":
        return EPC_F;
      case "g":
        return EPC_G;
      default:
        return EPC_G;
    }
  };

  return (
    <>
      {loader && (
        <div className="spinner-container">
          <div className="spinner">
            <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
              <circle cx="50" cy="50" r="46" />
            </svg>
          </div>
        </div>
      )}
      <div className="container mx-auto px-4 lg:px-16 flex justify-center">
        <div className="w-full lg:w-[98%]">
          <div className="px-4 lg:px-[75px] pb-2">
            <h6 className="text-[#3A484D] text-left text-[14px] font-normal">
              {state?.language?.result?.date}
            </h6>
            <h6 className="text-[#3A484D] text-left text-[14px] font-semibold">
              {state?.visitedUserData?.visitor_result?.added
                ? state?.visitedUserData?.visitor_result?.added
                : "-"}
            </h6>
          </div>
          <div className="flex flex-col lg:flex-row justify-center w-full">
            {/* left box */}
            <div className="w-full lg:w-[45%] bg-white rounded-xl p-6 lg:mx-2">
              <div>
                <p className="text-[#4A4A4A] text-center text-[18px] font-semibold">
                  {state?.language?.result?.pageTitle}
                </p>
              </div>
              <div className="flex justify-center w-full lg:py-4">
                <img
                  src={epcImageHandler(
                    state?.visitedUserData?.visitor_result?.ecolabel_now?.toLowerCase()
                  )}
                  alt=""
                  className="h-12"
                />
              </div>
            </div>
            {/* right box */}
            <div className="w-full lg:w-[45%] bg-white rounded-xl p-6 lg:mx-2 mt-4 lg:mt-0">
              <div>
                <h6 className="text-[#4A4A4A] text-center text-[18px] font-semibold">
                  {state?.language?.result?.share}
                </h6>
                <h6 className="text-[#3A484D] text-center text-[13px] py-1 fonr-semibold">
                  {state?.language?.result?.email}
                </h6>
              </div>
              <div className="flex justify-center w-full py-1">
                <div className="w-10/12 lg:w-7/12">
                  <div className="flex justify-end">
                    <img
                      src={SendIcon}
                      alt=""
                      className="h-7 absolute mt-[5px] ml-[-5px] z-10 cursor-pointer"
                      onClick={() => sendEmailHandler()}
                    />
                  </div>
                  <div>
                    <input
                      placeholder="example@test.com"
                      className="w-full text-[#3A484D] text-center text-[13px] rounded-full border py-2 px-4 border-[#00A3DA] outline-none"
                      onChange={(e: any) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </div>
                  <div>
                    <p className="text-[#00A3DA] text-center text-[12px] py-1 fonr-semibold">
                      {state?.language?.result?.optionSection1}
                    </p>
                  </div>
                  {/* social icons */}
                  <div className="flex justify-between pt-1 px-2">
                    <FacebookShareButton
                      url={shareUrl}
                      quote={title}
                      className="px-2"
                    >
                      <img src={Facebook} alt="" className="h-8" />
                    </FacebookShareButton>
                    <LinkedinShareButton
                      url={window.location.href}
                      title={title}
                      className="px-2"
                    >
                      <img src={LinkedIn} alt="" className="h-8" />
                    </LinkedinShareButton>

                    <TwitterShareButton
                      url={window.location.href}
                      title={title}
                      className="px-2"
                    >
                      <img src={Twitter} alt="" className="h-8" />
                    </TwitterShareButton>
                    <div className="">
                      <img
                        src={Link}
                        alt=""
                        className="h-8 cursor-pointer"
                        onClick={() => copyHandler()}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;
