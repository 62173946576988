import { GetAPIService, PostAPIService } from "../services";

//  handler to store data of homepage form data
export const step2AllHousesTypes = (payload: any, dispatch: any) => {
  dispatch({
    type: "SET_ALL_HOUSES_TYPES",
    payload: payload,
  });
};

//  handler to store data of homepage form data
export const setStepFormValues = (payload: any, dispatch: any) => {
  dispatch({
    type: "SET_FORM_STEP_DATA",
    payload: payload,
  });
};

//  handler to store data of homepage form data
export const setStepTwoHouseData = (payload: any, dispatch: any) => {
  dispatch({
    type: "SET_STEP_TWO_SELCETEDHOUSE",
    payload: payload,
  });
};

//  handler to get all step 2 houses data
export const step2HousesTypes = (language: any, payload: any) => {
  // return PostAPIService(`/calculate_typology?lg=${language}`, payload);
  return PostAPIService(`/calculate_typology?lg=${language}`, payload);
};

//  handler to get all step 2 houses data
export const step2TypologyHousesTypes = (language: any) => {
  // return PostAPIService(`/calculate_typology?lg=${language}`, payload);
  return GetAPIService(`/typologies?lg=${language}`);
};

//  handler to save all steps data
export const addStepsFormData = (payload: any) => {
  return PostAPIService(`/save_quickscan`, payload);
};
