import React, { FC, useContext, useEffect } from "react";
import { now } from "jquery";
import PDF from "../../components/pdfResult";
import { GlobalContext } from "../../context/globalContext/globalContext";
import { setLanguageHandler } from "../../context/actions/language";
import { useParams, useLocation } from "react-router-dom";
import {
  getVisitedUserData,
  visitedUserData,
} from "../../context/actions/resultPage";
import { setModalHandler } from "../../context/actions/modal";
// languages
import French from "../../languages/fr";
import Dutch from "../../languages/de";

interface PDFResultPageInterface {}

const PDFPAGE: FC<PDFResultPageInterface> = () => {
  // to get id from url
  let params = useParams();
  let location = useLocation();
  // global state
  const { dispatch }: any = useContext(GlobalContext);
  // const [loader, setLoader] = useState(true);

  // useEffect to set language
  useEffect(() => {
    const language = location?.search?.split("=")[1] === "de" ? Dutch : French;
    setLanguageHandler(language, dispatch);
  }, [location?.search, dispatch]);

  // useEffect to get UserVisted data and store into Global state
  useEffect(() => {
    let id = params?.id + "?" + now();
    getVisitedUserData(id)
      .then((response) => {
        if (response?.data?.data?.visitor_answer?.length === 0) {
          setModalHandler(
            {
              content: "",
              modal: "resultPage",
            },
            dispatch
          );
        } else {
          visitedUserData(response?.data?.data, dispatch);
        }
        // setLoader(false);
      })
      .catch(() => {
        setModalHandler(
          {
            content: "",
            modal: "resultPage",
          },
          dispatch
        );
        // setLoader(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id]);
  return <PDF />;
};

export default PDFPAGE;
