import Routes from "./routes";
import { GlobalProvider } from "./context/globalContext/globalContext";
import "./styles/index.css";
// Google Analytics tracking code
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import ReactGA from "react-ga";
ReactGA.initialize("UA-180663289-1");
ReactGA.pageview(window.location.pathname + window.location.search);


const App = () => {
  return (
    <GlobalProvider>
      <Routes />
    </GlobalProvider>
  );
};

export default App;
