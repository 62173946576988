import React, { FC, useContext } from "react";
import { GlobalContext } from "../../context/globalContext/globalContext";
import CrossIcon from "../../assets/icons/close.png";
import { setModalHandler } from "../../context/actions/modal";
import Loader from "../../assets/gifs/Gear-0.2s-200px.gif";

interface StepsModalInterface {}

const StepsModal: FC<StepsModalInterface> = () => {
  // global state
  const { state, dispatch }: any = useContext(GlobalContext);
  /*  to show selected section information content and on basis of selected language (from header). */
  const contentHandler = () => {
    /* condition for form page step 1,2,3,4 => to show title and description */
    if (state?.modal?.modal === "formLoader") {
      return {
        title: state?.language?.modal?.calculation,
        description: state?.language?.modal?.calculationSub,
      };
    } else if (
      state?.modal?.modal === "step1" ||
      state?.modal?.modal === "step2" ||
      state?.modal?.modal === "step3" ||
      state?.modal?.modal === "step4" ||
      state?.modal?.modal === "step5" ||
      state?.modal?.modal === "step6" ||
      state?.modal?.modal === "step7"
    ) {
      return {
        title:
          state?.language[state?.modal?.content?.split(".")[0]][
            state?.modal?.content?.split(".")[1]
          ]?.infoboxHeader,
        description:
          state?.language[state?.modal?.content?.split(".")[0]][
            state?.modal?.content?.split(".")[1]
          ].infoboxDescription,
      };
    }
  };
  return (
    <div
      className={`relative z-10 modal-container ${
        state.modal?.modal !== "" ? "opacity-100" : "opacity-0"
      }`}
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      {/* condition to show modal when it's active */}
      {state.modal?.modal !== "" && (
        <div>
          <div className="fixed inset-0 bg-[#E9F6FD] bg-opacity-75 transition-opacity"></div>
          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex sm:items-center justify-center lg:min-h-full p-4 text-center sm:p-0">
              <div className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 w-full lg:w-7/12">
                {/* header */}
                {state?.modal?.modal !== "formLoader" && (
                  <div
                    className="flex justify-end absolute right-0 m-3 cursor-pointer w-fit"
                    onClick={() => {
                      setModalHandler(
                        {
                          content: "",
                          modal: "",
                        },
                        dispatch
                      );
                    }}
                  >
                    <img src={CrossIcon} alt="cross-icon" className="h-3" />
                  </div>
                )}
                {/* body */}
                <div>
                  <div className="bg-white px-4 pt-5 pb-5">
                    <div className="sm:flex sm:items-start">
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                        <h3
                          className={`text-[18px] leading-6 font-semibold text-[#4C4D4D] ${
                            state?.modal?.modal === "formLoader" &&
                            "text-center"
                          }`}
                          id="modal-title"
                        >
                          {contentHandler()?.title}
                        </h3>
                        {/* description */}
                        <div
                          className={`pt-5 text-sm text-[#4C4D4D] ${
                            state?.modal?.modal === "formLoader" &&
                            "text-center"
                          }`}
                          dangerouslySetInnerHTML={{
                            __html: contentHandler()?.description,
                          }}
                        ></div>
                        {state?.modal?.modal === "formLoader" && (
                          <div className="flex justify-center w-full pt-5">
                            <img src={Loader} alt="" className="h-[40px]" />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StepsModal;
