import React, { FC, useContext, useEffect, useState } from "react";
// layout
import Layout from "../../components/layout";
import { GlobalContext } from "../../context/globalContext/globalContext";
import { setModalHandler } from "../../context/actions/modal";
import {
  setStepFormValues,
  step2HousesTypes,
  step2TypologyHousesTypes,
} from "../../context/actions/formsAction";
import Step1Form from "../../components/forms/step1";
import Step2Form from "../../components/forms/step2";
import Step3Form from "../../components/forms/step3";
import Step4Form from "../../components/forms/step4";
import Step5Form from "../../components/forms/step5";
import Step6Form from "../../components/forms/step6";
import Step7Form from "../../components/forms/step7";
// Actions
import {
  step2AllHousesTypes,
  setStepTwoHouseData,
} from "../../context/actions/formsAction";
// StepsModal
import StepsModal from "../../shared/modals/stepsFormModal";
import FormErrorModal from "../../shared/modals/validationsFormModal";
import { ScreenSize } from "../../utils/windowDimension";
// steps images of desktop
import Step1 from "../../assets/images/forms/1.svg";
import Step2 from "../../assets/images/forms/2.svg";
import Step3 from "../../assets/images/forms/3.svg";
import Step4 from "../../assets/images/forms/4.svg";
import Step5 from "../../assets/images/forms/5.svg";
import Step6 from "../../assets/images/forms/6.svg";
import Step7 from "../../assets/images/forms/7.svg";
// steps images of responsive
import ResponsiveStep1 from "../../assets/images/responsiveStepsIcons/1.svg";
import ResponsiveStep2 from "../../assets/images/responsiveStepsIcons/2.svg";
import ResponsiveStep3 from "../../assets/images/responsiveStepsIcons/3.svg";
import ResponsiveStep4 from "../../assets/images/responsiveStepsIcons/4.svg";
import ResponsiveStep5 from "../../assets/images/responsiveStepsIcons/5.svg";
import ResponsiveStep6 from "../../assets/images/responsiveStepsIcons/6.svg";
import ResponsiveStep7 from "../../assets/images/responsiveStepsIcons/7.svg";

interface FormInterface {}

const Form: FC<FormInterface> = () => {
  const screenSize: any = ScreenSize();
  // global state
  const { state, dispatch }: any = useContext(GlobalContext);
  // state for selected questions in steps
  const [selectedQuestion, setSelectedQuestion] = useState(1);
  // state for 7 different steps to save number and show selected step
  const [step, setStep] = useState(1);
  // state for animation
  const [showAnimation, setShowAnimation] = useState("animate-left");
  // state for loader
  const [loader, setLoader] = useState("");
  // variable for screen zoom
  const browserZoomLevel: any =
    (window.outerWidth / window.document.documentElement.clientWidth) * 100;

  // forms right side panels and buttons height
  const formsRightQuestionsHeight = () => {
    if (screenSize?.height >= 840 && screenSize?.height < 860) {
      return `lg:h-[60vh]`;
    } else if (screenSize?.height >= 860 && screenSize?.height <= 1166) {
      return `lg:h-[65vh]`;
    } else if (screenSize?.height > 1166) {
      return `lg:h-[71vh]`;
    } else {
      return `lg:h-[57vh]`;
    }
  };
  // forms right side panels and buttons height
  const formsLeftQuestionsHeight = (value: string) => {
    if (screenSize?.width < 1500) {
      return `lg:h-[53vh]`;
    } else {
      return value;
    }
  };

  // useEffect to get form steps data if exist
  useEffect(() => {
    let localStorageData: any = localStorage.getItem("formData");
    let formData = JSON.parse(localStorageData);
    if (!formData?.homepageFormData) {
      setModalHandler(
        {
          content: "/",
          modal: "formError",
        },
        dispatch
      );
    } else {
      if (formData && Object.keys(formData).length > 0) {
        setStep(formData?.formStep);
      }
      if (Object.keys(formData).length > 0 && formData?.stepone) {
        submitHouseDetailHandler(formData?.stepone);
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // API call for to get selectedHouseDetail
  const submitHouseDetailHandler = (stepOne: any) => {
    setLoader("step2");
    let payload = {
      stepone: stepOne,
    };
    step2HousesTypes(
      `${state?.language?.language}&ti=${new Date().getTime()}`,
      payload
    ).then((response: any) => {
      setStepTwoHouseData(response?.data?.data[0], dispatch);
      setStepFormValues(response?.data?.data[0], dispatch);
      setLoader("");
    });
  };

  // formHeightHandler to show equal content of form for 100 , 90 and 80 screen size
  const formHeightHandler = (key: any) => {
    if (parseInt(browserZoomLevel) === 100 && key === "main") {
      return " lg:h-[54vh]";
    }
    if (
      (parseInt(browserZoomLevel) === 100 ||
        parseInt(browserZoomLevel) === 90) &&
      key === "left"
    ) {
      return " lg:h-[53vh]";
    }
    if (parseInt(browserZoomLevel) === 80 && key === "left") {
      return " lg:h-[62vh]";
    }
  };

  // forms steps handler to show selected form
  const formStepsHandler = (value: number) => {
    switch (value) {
      case 1:
        return (
          <Step1Form
            setStep={setStep}
            setShowAnimation={setShowAnimation}
            selectedQuestion={selectedQuestion}
            setSelectedQuestion={setSelectedQuestion}
            submitHouseDetailHandler={submitHouseDetailHandler}
            formsRightQuestionsHeight={formsRightQuestionsHeight}
            formsLeftQuestionsHeight={formsLeftQuestionsHeight}
            formHeightHandler={formHeightHandler}
          />
        );
      case 2:
        return (
          <Step2Form
            setStep={setStep}
            setShowAnimation={setShowAnimation}
            setSelectedQuestion={setSelectedQuestion}
            loader={loader}
          />
        );
      case 3:
        return (
          <Step3Form
            setStep={setStep}
            setShowAnimation={setShowAnimation}
            selectedQuestion={selectedQuestion}
            setSelectedQuestion={setSelectedQuestion}
            formsRightQuestionsHeight={formsRightQuestionsHeight}
            formsLeftQuestionsHeight={formsLeftQuestionsHeight}
            formHeightHandler={formHeightHandler}
          />
        );
      case 4:
        return (
          <Step4Form
            setStep={setStep}
            setShowAnimation={setShowAnimation}
            selectedQuestion={selectedQuestion}
            setSelectedQuestion={setSelectedQuestion}
            formsRightQuestionsHeight={formsRightQuestionsHeight}
            formsLeftQuestionsHeight={formsLeftQuestionsHeight}
            formHeightHandler={formHeightHandler}
          />
        );
      case 5:
        return (
          <Step5Form
            setStep={setStep}
            setShowAnimation={setShowAnimation}
            selectedQuestion={selectedQuestion}
            setSelectedQuestion={setSelectedQuestion}
            formsRightQuestionsHeight={formsRightQuestionsHeight}
            formsLeftQuestionsHeight={formsLeftQuestionsHeight}
            formHeightHandler={formHeightHandler}
          />
        );
      case 6:
        return (
          <Step6Form
            setStep={setStep}
            setShowAnimation={setShowAnimation}
            selectedQuestion={selectedQuestion}
            setSelectedQuestion={setSelectedQuestion}
            formsRightQuestionsHeight={formsRightQuestionsHeight}
            formsLeftQuestionsHeight={formsLeftQuestionsHeight}
            formHeightHandler={formHeightHandler}
          />
        );
      case 7:
        return (
          <Step7Form setStep={setStep} setShowAnimation={setShowAnimation} />
        );
      default:
        return (
          <Step1Form
            setStep={setStep}
            setShowAnimation={setShowAnimation}
            selectedQuestion={selectedQuestion}
            setSelectedQuestion={setSelectedQuestion}
            submitHouseDetailHandler={submitHouseDetailHandler}
            formsRightQuestionsHeight={formsRightQuestionsHeight}
            formsLeftQuestionsHeight={formsLeftQuestionsHeight}
            formHeightHandler={formHeightHandler}
          />
        );
    }
  };

  // forms steps handler to show selected form
  const formStepsTitleHandler = (step: number) => {
    switch (step) {
      case 1:
        return state?.language?.stepOne?.stepTitle;
      case 2:
        return state?.language?.stepTwo?.stepTitle;
      case 3:
        return state?.language?.stepThree?.stepTitle;
      case 4:
        return state?.language?.stepFour?.stepTitle;
      case 5:
        return state?.language?.stepFive?.stepTitle;
      case 6:
        return state?.language?.stepSix?.stepTitle;
      case 7:
        return state?.language?.stepSeven?.stepTitle;
      default:
        return state?.language?.stepOne?.stepTitle;
    }
  };

  // stepsImagesHandler to show selected form images
  const stepsImagesHandler = (value: number) => {
    switch (value) {
      case 1:
        return screenSize.width > 1023 ? Step1 : ResponsiveStep1;
      case 2:
        return screenSize.width > 1023 ? Step2 : ResponsiveStep2;
      case 3:
        return screenSize.width > 1023 ? Step3 : ResponsiveStep3;
      case 4:
        return screenSize.width > 1023 ? Step4 : ResponsiveStep4;
      case 5:
        return screenSize.width > 1023 ? Step5 : ResponsiveStep5;
      case 6:
        return screenSize.width > 1023 ? Step6 : ResponsiveStep6;
      case 7:
        return screenSize.width > 1023 ? Step7 : ResponsiveStep7;
      default:
        return screenSize.width > 1023 ? Step1 : ResponsiveStep1;
    }
  };

  // API Call for second step
  useEffect(() => {
    step2TypologyHousesTypes(
      `${state?.language?.language}&ti=${new Date().getTime()}`
    ).then((response) => {
      // to store in global state all houses types
      step2AllHousesTypes(response?.data?.data, dispatch);
    });
  }, [state?.language?.language, dispatch]);

  return (
    <>
      {/* Notification modal */}
      <StepsModal />
      {/* modal to show error if nothing is selected in any steps */}
      {state?.modal?.modal === "formError" && <FormErrorModal />}
      <div className={`${showAnimation}`}>
        <Layout>
          <>
            <div className={`forms-container`}>
              {/* header */}
              <div className="bg-[#E1F4FB] py-4 form-header">
                <div className="p-4 container mx-auto">
                  {/* step image of current active forms */}
                  <div className="flex justify-center">
                    <img src={stepsImagesHandler(step)} alt="" />
                  </div>
                  {/* title */}
                  <div className="pt-7 pb-2">
                    <h4 className="text-[#3A484D] text-center text-[18px] lg:text-[22px] font-semibold">
                      {formStepsTitleHandler(step)}
                    </h4>
                  </div>
                </div>
              </div>
              {/* forms */}
              <div>{formStepsHandler(step)}</div>
            </div>
          </>
        </Layout>
      </div>
    </>
  );
};

export default Form;
