import React, { FC, useContext, useState, useEffect } from "react";
import { GlobalContext } from "../../context/globalContext/globalContext";
import { setModalHandler } from "../../context/actions/modal";
import { storeStepValues } from "../../utils/forms";
import { setStepFormValues } from "../../context/actions/formsAction";
import { storelocalStorageData } from "../../utils/forms";
import { smoothScroll } from "../../utils/smoothScroll";
// section 1 icons
import HouseQ1 from "../../assets/icons/questionsForm/step6/heater__2_.svg";
import Imag1Off from "../../assets/icons/questionsForm/step6/off-step6.svg";
import Imag1ON from "../../assets/icons/questionsForm/step6/on-step6.svg";
import Imag2OFF from "../../assets/icons/questionsForm/step6/off_Q1_16_.svg";
import Imag2ON from "../../assets/icons/questionsForm/step6/on_Q1_16_.svg";
import Imag3OFF from "../../assets/icons/questionsForm/step5/off_Q1_12_.svg";
import Imag3ON from "../../assets/icons/questionsForm/step5/on_Q1_12_.svg";
import Imag4OFF from "../../assets/icons/questionsForm/step6/recent-boiler-off.svg";
import Imag4ON from "../../assets/icons/questionsForm/step6/recent-boiler-on.svg";
import Imag5OFF from "../../assets/icons/questionsForm/step6/off__14_.svg";
import Imag5ON from "../../assets/icons/questionsForm/step6/on__14_.svg";
// section 2 icons
import HouseQ2 from "../../assets/icons/questionsForm/step6/panels-water.svg";
// Sections icons of this page
import BlueArrow from "../../assets/icons/questionsForm/step1/arrow-down-blue.svg";
import TransparentArrow from "../../assets/icons/questionsForm/step1/arrow-down-transparency.svg";
import NextArrow from "../../assets/icons/questionsForm/step1/arrow-next.svg";
import InfoIcon from "../../assets/icons/information-button.svg";
import { ScreenSize } from "../../utils/windowDimension";

interface Step6Interface {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  setShowAnimation: React.Dispatch<React.SetStateAction<string>>;
  selectedQuestion?: number;
  setSelectedQuestion?: any;
  formsRightQuestionsHeight?: any;
  formsLeftQuestionsHeight?: any;
  formHeightHandler?: any;
}

// FormOptionTypes types
type FormOptionTypes = {
  id: number;
  name: string;
  selected: string;
};

const Step6: FC<Step6Interface> = ({
  setStep,
  setShowAnimation,
  selectedQuestion,
  setSelectedQuestion,
  formsRightQuestionsHeight,
  formsLeftQuestionsHeight,
  formHeightHandler,
}) => {
  // global state
  const { state, dispatch }: any = useContext(GlobalContext);
  // list of step 1 options
  const formOptions: FormOptionTypes[] = [
    {
      id: 1,
      name: state?.language?.language === "fr" ? "Eau chaude sanitaire" : "Sanitärwarmwasser",
      selected:
        selectedQuestion === 1 || selectedQuestion === 2 ? "true" : "false",
    },
    {
      id: 2,
      name:
        state?.language?.language === "fr"
          ? "Panneaux solaires thermiques"
          : "Thermische Solarmodule",
      selected: selectedQuestion === 2 ? "true" : "false",
    },
  ];
  const screenSize: any = ScreenSize();
  const browserZoomLevel: any =
    (window.outerWidth / window.document.documentElement.clientWidth) * 100;
  const [step6, setStep6] = useState({
    question_one: {
      answer_id: 0,
      answer_title: "",
      question_id: 32,
      question_title: state?.language?.stepSix.questionOne?.title,
    },
    question_two: {
      question_id: 33,
      question_title:
        "L'habitation possède-t-elle des panneaux solaires pour la production d'eau chaude ?",
      answer_id: 0,
      answer_title: "",
    },
  });
  // useEffect to check if data exsit in localStorage to show selectedData
  useEffect(() => {
    let localStorageData: any = localStorage.getItem("formData");
    let formData = JSON.parse(localStorageData);
    if (formData?.stepsix) {
      // to store in local and Global states
      setStep6(formData?.stepsix);
      setStepFormValues(formData?.stepsix, dispatch);
    }
    // window.scrollTo(0, 0);
  }, [dispatch]);
  useEffect(() =>{
    // window.scrollTo(0,0)
    document.getElementById('root')?.scrollIntoView();
  },[]);
  // handler to store values into Global state
  const submitHandler = () => {
    if (
      step6?.question_one?.answer_id === 0 ||
      step6?.question_two?.answer_id === 0
    ) {
      setModalHandler(
        {
          content: "",
          modal: "formError",
        },
        dispatch
      );
    } else {
      setShowAnimation("animate-left7");
      let stepsix = { stepsix: step6, formStep: 7 };
      storelocalStorageData(stepsix);
      // to store in local and global states
      setStepFormValues(stepsix, dispatch);
      setStep(7);
      setSelectedQuestion(1);
    }
  };

  return (
    <div className="container mx-auto py-8 step1-container px-4">
      <div
        className={`flex flex-col lg:flex-row lg:justify-between items-center lg:items-start lg:flex-row ${
          screenSize?.width < 1500 && formHeightHandler("main")
        }`}
      >
        {/* left side */}
        <div
          className={`w-12/12 lg:w-[70%] lg:overflow-y-auto ${
            parseInt(browserZoomLevel) === 100 ||
            parseInt(browserZoomLevel) === 90 ||
            parseInt(browserZoomLevel) === 80
              ? formHeightHandler("left")
              : formsLeftQuestionsHeight("lg:h-[73vh]")
          }`}
        >
          {/* 1st card */}
          <div
            className={`w-full py-4 pl-4 pr-7 border-2 rounded-2xl mb-6 ${
              selectedQuestion === 1 ? "border-[#00A3DA]" : "border-[#E1F4FB]"
            } `}
          >
            {/* header */}
            <div className="w-full flex items-center justify-between">
              <div className="flex items-center w-11/12">
                <img src={HouseQ1} alt="" className="h-12" />
                <h5 className="text-[#3A484D] font-semibold px-6 text-[17px]">
                  {state?.language?.stepSix.questionOne?.title} 
                  {/* --- {`{"question_id": ${step6.question_one.question_id}, "answer_id": ${step6.question_one.answer_id}}`} */}
                </h5>
              </div>
              <div
                className="cursor-pointer w-1/12 flex justify-end"
                onClick={() => {
                  setModalHandler(
                    {
                      content: "stepSix.questionOne",
                      modal: "step6",
                    },
                    dispatch
                  );
                }}
              >
                <img src={InfoIcon} alt="" className="h-5" />
              </div>
            </div>
            {/* content */}
            <div className="flex flex-wrap justify-between pt-8 lg:px-6">
              {/* 1 */}
              <div className="w-full py-2 lg:px-2 lg:w-6/12">
                <div
                  className={`steps-box-hover border rounded-2xl px-4 py-4 w-full h-full lg:w-12/12 hover:bg-[#01a2da] text-[#3A484D] hover:text-[#fff] cursor-pointer
                  ${
                    step6?.question_one?.answer_id === 105 &&
                    "bg-[#01a2da]  text-[#fff]"
                  }
                  `}
                  onClick={() => {
                    storeStepValues(step6, "question_one", "", 105, setStep6);
                    setSelectedQuestion(2);
                    smoothScroll("content2");
                  }}
                >
                  <div className="flex flex-row justify-start items-center">
                    <div className="w-4/12 flex justify-center">
                    {window.screen.availWidth < 1024 ? 
                          <img src={Imag1Off} alt="" className="h-16" />
                          :
                      step6?.question_one?.answer_id === 105 ? (
                        <img src={Imag1ON} alt="" className="h-16" />
                      ) : (
                        <>
                          <img
                            src={Imag1Off}
                            alt=""
                            className="h-16 hide-on-hover"
                          />
                          <img
                            src={Imag1ON}
                            alt=""
                            className="h-16 show-on-hover"
                          />
                        </>
                      )}
                    </div>
                    <div className="w-8/12 lg:w-full">
                      <p className="text-[14px] font-normal py-3 px-2">
                        {state?.language?.stepSix?.questionOne?.answerOne}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* 2 */}
              <div className="w-full py-2 lg:px-2 lg:w-6/12">
                <div
                  className={`steps-box-hover border rounded-2xl px-4 py-4 w-full h-full lg:w-12/12 hover:bg-[#01a2da] text-[#3A484D] hover:text-[#fff] cursor-pointer
                  ${
                    step6?.question_one?.answer_id === 106 &&
                    "bg-[#01a2da]  text-[#fff]"
                  }
                  `}
                  onClick={() => {
                    storeStepValues(step6, "question_one", "", 106, setStep6);
                    setSelectedQuestion(2);
                    smoothScroll("content2");
                  }}
                >
                  <div className="flex flex-row justify-start items-center">
                    <div className="w-4/12 flex justify-center">
                    {window.screen.availWidth < 1024 ? 
                          <img src={Imag2OFF} alt="" className="h-16" />
                          :
                      step6?.question_one?.answer_id === 106 ? (
                        <img src={Imag2ON} alt="" className="h-16" />
                      ) : (
                        <>
                          <img
                            src={Imag2OFF}
                            alt=""
                            className="h-16 hide-on-hover"
                          />
                          <img
                            src={Imag2ON}
                            alt=""
                            className="h-16 show-on-hover"
                          />
                        </>
                      )}
                    </div>
                    <div className="w-8/12 lg:w-full">
                      <p className="text-[14px] font-normal py-3 px-2">
                        {state?.language?.stepSix?.questionOne?.answerTwo}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* 3 */}
              <div className="w-full py-2 lg:px-2 lg:w-6/12">
                <div
                  className={`steps-box-hover border rounded-2xl px-4 py-4 w-full h-full lg:w-12/12 hover:bg-[#01a2da] text-[#3A484D] hover:text-[#fff] cursor-pointer flex items-center 
                  ${
                    step6?.question_one?.answer_id === 107 &&
                    "bg-[#01a2da] text-[#fff]"
                  }
                  `}
                  onClick={() => {
                    storeStepValues(step6, "question_one", "", 107, setStep6);
                    setSelectedQuestion(2);
                    smoothScroll("content2");
                  }}
                >
                  <div className="flex flex-row justify-start items-center">
                    <div className="w-4/12 flex justify-center">
                    {window.screen.availWidth < 1024 ? 
                          <img src={Imag3OFF} alt="" className="h-16" />
                          :
                      step6?.question_one?.answer_id === 107 ? (
                        <img src={Imag3ON} alt="" className="h-16" />
                      ) : (
                        <>
                          <img
                            src={Imag3OFF}
                            alt=""
                            className="h-16 hide-on-hover"
                          />
                          <img
                            src={Imag3ON}
                            alt=""
                            className="h-16 show-on-hover"
                          />
                        </>
                      )}
                    </div>
                    <div className="w-8/12 lg:w-full">
                      <p className="text-[14px] font-normal py-3 px-2">
                        {state?.language?.stepSix?.questionOne?.answerThree}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* 4 */}
              <div className="w-full py-2 lg:px-2 lg:w-6/12">
                <div
                  className={`steps-box-hover border rounded-2xl px-4 py-4 w-full h-full lg:w-12/12 hover:bg-[#01a2da] text-[#3A484D] hover:text-[#fff] cursor-pointer
                  ${
                    step6?.question_one?.answer_id === 108 &&
                    "bg-[#01a2da] text-[#fff]"
                  }
                  `}
                  onClick={() => {
                    storeStepValues(step6, "question_one", "", 108, setStep6);
                    setSelectedQuestion(2);
                    smoothScroll("content2");
                  }}
                >
                  <div className="flex flex-row justify-start items-center">
                    <div className="w-4/12 flex justify-center">
                    {window.screen.availWidth < 1024 ? 
                          <img src={Imag4OFF} alt="" className="h-16" />
                          :
                      step6?.question_one?.answer_id === 108 ? (
                        <img src={Imag4ON} alt="" className="h-16" />
                      ) : (
                        <>
                          <img
                            src={Imag4OFF}
                            alt=""
                            className="h-16 hide-on-hover"
                          />
                          <img
                            src={Imag4ON}
                            alt=""
                            className="h-16 show-on-hover"
                          />
                        </>
                      )}
                    </div>
                    <div className="w-8/12 lg:w-full">
                      <p className="text-[14px] font-normal py-3 px-2">
                        {state?.language?.stepSix?.questionOne?.answerFour}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* 5 */}
              <div className="w-full py-2 lg:px-2 lg:w-6/12">
                <div
                  className={`steps-box-hover border rounded-2xl px-4 py-4 w-full h-full lg:w-12/12 hover:bg-[#01a2da] text-[#3A484D] hover:text-[#fff] cursor-pointer
                  ${
                    step6?.question_one?.answer_id === 109 &&
                    "bg-[#01a2da] text-[#fff]"
                  }
                  `}
                  onClick={() => {
                    storeStepValues(step6, "question_one", "", 109, setStep6);
                    setSelectedQuestion(2);
                    smoothScroll("content2");
                  }}
                >
                  <div className="flex flex-row justify-start items-center">
                    <div className="w-4/12 flex justify-center">
                    {window.screen.availWidth < 1024 ? 
                          <img src={Imag5OFF} alt="" className="h-16" />
                          :
                      step6?.question_one?.answer_id === 109 ? (
                        <img src={Imag5ON} alt="" className="h-16" />
                      ) : (
                        <>
                          <img
                            src={Imag5OFF}
                            alt=""
                            className="h-16 hide-on-hover"
                          />
                          <img
                            src={Imag5ON}
                            alt=""
                            className="h-16 show-on-hover"
                          />{" "}
                        </>
                      )}
                    </div>
                    <div className="w-8/12 lg:w-full">
                      <p className="text-[14px] font-normal py-3 px-2">
                        {state?.language?.stepSix?.questionOne?.answerFive}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* 2nd card */}
          <div
            className={`w-full py-4 pl-4 pr-7 border-2 rounded-2xl mb-6 ${
              selectedQuestion === 2 ? "border-[#00A3DA]" : "border-[#E1F4FB]"
            }`}
            id="content2"
          >
            {/* header */}
            <div className="w-full flex items-center justify-between">
              <div className="flex items-center w-11/12">
                <img src={HouseQ2} alt="" className="h-12" />
                <h5 className="text-[#4A4A4A] font-[600] px-6 text-[17px]">
                  {state?.language?.stepSeven.questionOne?.title}  
                  {/* --- {`{"question_id": ${step6.question_two.question_id}, "answer_id": ${step6.question_two.answer_id}}`} */}
                </h5>
              </div>
              <div
                className="cursor-pointer w-1/12 flex justify-end"
                onClick={() => {
                  setModalHandler(
                    {
                      content: "stepSeven.questionOne",
                      modal: "step7",
                    },
                    dispatch
                  );
                }}
              >
                <img src={InfoIcon} alt="" className="h-5" />
              </div>
            </div>
            <div>
              <div>
                {/* content */}
                <div className="flex flex-row lg:flex-row justify-center pt-6 lg:px-6">
                  <div className="py-3 lg:px-3 px-1 w-full lg:w-4/12">
                    <div
                      className={`border rounded-2xl h-full px-3 py-6 w-full hover:bg-[#01a2da] text-[#3A484D] hover:text-[#fff] cursor-pointer flex items-center justify-center
                      ${
                        step6?.question_two?.answer_id === 110 &&
                        "bg-[#01a2da] text-[#fff]"
                      }
                      `}
                      onClick={() => {
                        storeStepValues(
                          step6,
                          "question_two",
                          "",
                          110,
                          setStep6
                        );
                        smoothScroll("content3");
                      }}
                    >
                      <p className="text-[16px] text-center">
                        {state?.language?.stepSeven?.questionTwo?.answerOne}
                      </p>
                    </div>
                  </div>
                  <div className="py-3 lg:px-3 px-1 w-full lg:w-4/12">
                    <div
                      className={`border rounded-2xl px-3 py-6 w-full h-full hover:bg-[#01a2da] text-[#3A484D] hover:text-[#fff] cursor-pointer flex items-center justify-center
                      ${
                        step6?.question_two?.answer_id === 111 &&
                        "bg-[#01a2da] text-[#fff]"
                      }
                      `}
                      onClick={() => {
                        storeStepValues(
                          step6,
                          "question_two",
                          "",
                          111,
                          setStep6
                        );
                        smoothScroll("content3");
                      }}
                    >
                      <p className="text-[16px] text-center my-1">
                        {state?.language?.stepSeven?.questionTwo?.answerTwo}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* right side */}
        <div
          className={`w-full lg:w-3/12 flex flex-col justify-between items-center lg:pb-6 ${formsRightQuestionsHeight()}`}
          id="content3"
        >
          <div className="hidden lg:block">
            {/*active arrow */}
            {formOptions?.map((item, index) => (
              <div className="flex" key={item?.id}>
                <div>
                  <img
                    src={
                      item?.selected === "true" ? BlueArrow : TransparentArrow
                    }
                    className="h-8"
                    alt=""
                  />
                  {/* line between image */}
                  {item?.id !== 2 && (
                    <div className="flex justify-center">
                      <div
                        className={`w-[2px] h-6 
                         ${
                           item?.selected === "true" &&
                           formOptions[index + 1].selected === "true"
                             ? "bg-[#01a2da]"
                             : "bg-[#E7F6FD]"
                         }
                      `}
                      ></div>
                    </div>
                  )}
                </div>
                {
                  <div className="px-4 pt-1 w-10/12">
                    <p className="text-[#3A484D]">{item?.name}</p>
                  </div>
                }
              </div>
            ))}
          </div>
          {/* buttons */}
          <div className="flex flex-row lg:flex-col justify-between w-full lg:w-8/12 px-2">
            <div className="lg:w-12/12 lg:py-4">
              <button
                className="bg-white hover:bg-[#eee] border w-full rounded-full py-[10px] text-[#3A484D] text-[15px] px-10"
                onClick={() => {
                  setStep(5);
                  setShowAnimation("animate-right5");
                }}
              >
                {state?.language?.stepButton?.previous}
              </button>
            </div>
            <div className="lg:w-12/12">
              <button
                className="bg-[#01a2da] hover:bg-[#0590c1] w-full rounded-full py-[10px] text-white text-[15px] pr-10 pl-5"
                onClick={() => submitHandler()}
              >
                {state?.language?.stepButton?.next}
                <span className="absolute ml-[8px] lg:ml-[20px]  mt-[6.5px]">
                  <img src={NextArrow} className="h-3" alt="" />
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step6;
