/**
 * activeOptionHandler @param state = all data , key = e.g question # , value = store data
 */
export const activeOptionHandler = (state: Object, key: any, value: any) => {
  let duplicate: any = { ...state };
  return duplicate[key].answer_title === value ? true : false;
};
/**
 * storeStepValues @param stepOne = all data , key = e.g question # , value = store data, setStepOne = to update state
 */
export const storeStepValues = (
  stepOne: any,
  key: any,
  value: any,
  answerId: any,
  setStepOne: any
) => {
  
  let duplicate: any = { ...stepOne };
  duplicate[key].answer_title = value;
  duplicate[key].answer_id = answerId;
  setStepOne(duplicate);
};

/**
 * storelocalStorageData @param stepData = object (form data)
 */
export const storelocalStorageData = (stepData: any) => {
  // to getPreviousData as well and store this form data
  const localStorageData: any = localStorage.getItem("formData");
  const oldFormData: any = JSON.parse(localStorageData);
  if (oldFormData) {
    // to add previous and new data
    const newFormData: any = { ...oldFormData, ...stepData };
    const locaStorageData = JSON.stringify(newFormData);
    localStorage.setItem("formData", locaStorageData);
  } else {
    // to current data if previous not exist
    const newFormData: any = { ...stepData };
    const locaStorageData = JSON.stringify(newFormData);
    localStorage.setItem("formData", locaStorageData);
  }
};
