const french = {
  language: "fr",
  plusdinfo: "Plus d'information",
  headerLanguage: "Sélectionner ma langue",
  presentationScreen: {
    title: "Bienvenue sur Quickscan.",
    title1: "Bienvenue sur",
    description: `Cet outil convivial vous aidera à évaluer votre habitation d'un point de vue énergétique. Il vous informera sur les travaux de rénovation à effectuer pour améliorer votre confort, réduire vos consommations et ainsi participer activement à un meilleur environnement.`,
    Button: {
      title1: "ÉVALUER LA PERFORMANCE",
      title2: "ÉNERGÉTIQUE DE MON HABITATION",
    },
    informationText:
      "Quickscan est indicatif et ne remplace pas un certificat PEB.",
  },
  HomePageForm: {
    title:
      "Évaluez en quelques clics la performance énergétique de votre habitation et recevez gratuitement des recommandations en vue de l’améliorer.",
    form: {
      title: "Code postal du bâtiment",
      checkBoxGroup: {
        title: "Votre statut",
        radioBtn1Text: "Je suis propriétaire",
        radioBtn2Text: "Je suis locataire",
        radioBtn3Text: "Autre",
      },
      dropDown: {
        title: `Votre tranche d'âge`,
      },
      submitBtn: {
        title: "COMMENCER LE TEST",
      },
      select1829:"18-29 ans",
      select3039:"30-39 ans",
      select4049:"40-49 ans",
      select5059:"50-59 ans",
      select60:"60+ ans",
    },
    age: "ans",
    bottomMessage:
      "L’application est actuellement uniquement destinée aux maisons unifamiliales existantes construites avant 2010; elle n’est pas applicable aux appartements.",
    bottomMessageTwo:
      "Conformément à la réglementation en matière de protection des données, les informations personnelles communiquées ne seront utilisées par le Service Public de Wallonie que pour les finalités suivantes : statistiques et évolution de l’outil « Quickscan ». Ces données ne seront ni vendues ni utilisées à des fins de marketing. Ces données ne seront pas transmises à des tiers sans que votre consentement n’ait été donné préalablement. Ces données seront conservées aussi longtemps que nécessaire pour l’utilisation et le développement de l’outil. Vous pouvez, dans certains cas spécifiques, rectifier, demander à faire transmettre vos données ou vous opposer au traitement de celles-ci en contactant le SPW.",
    objectives: {
      title: "En route vers le label A",
      description: `La Wallonie veut vous aider ! Une grande partie des habitations de notre région est très ancienne et ne répond pas aux normes de performances énergétiques. La Wallonie a décidé d'agir en fixant un objectif de rénovation ambitieux : d'ici 2050, les habitations wallonnes devront atteindre en moyenne le label PEB A. Cette action de grande envergure s'inscrit dans les objectifs de l'Europe pour réduire les émissions de gaz à effet de serre.`,
    },
  },
  KeyPoint: {
    title: "Améliorer votre habitation en 3 étapes",
    subHeader: {
      header1: {
        title: "ISOLER ET VENTILER",
        description: `ISOLER toutes les parois en contact avec l'extérieur et VENTILER correctement sont les priorités pour améliorer durablement le logement, en augmenter le confort et réduire les besoins en chauffage`,
      },
      header2: {
        title: "CHAUFFER",
        description:
          "CHAUFFER votre habitation avec un chauffage à haut rendement améliorera votre confort et réduira vos consommations.",
      },
      header3: {
        title: `PRODUIRE DE L'ÉNERGIE`,
        description:
          "PRODUIRE votre énergie en installant un ou des systèmes à énergies renouvelables vous conduira vers le ` ZÉRO ÉMISSIONS`",
      },
    },
    bottomMessageParagraph: {
      title: `La PEB, c'est quoi?`,
      description: `La règlementation PEB est la règlementation sur  la Performance Energétique des Bâtiments. Lorsque    vous    construisez    ou    rénovez,    des exigences    de performance énergétique doivent être respectées.<br/>Cette performance est attestée par un certificat PEB. Celui-ci constitue la fiche signalétique énergétique de votre bâtiment. Il en indique la classe énergétique, allant de A++ (neutre en énergie) à G (très énergivore) et renseigne sur sa consommation en énergie. Il  s’agit  d’un  document  qui  permet  de  comparer  de  manière  objective  la  performance  énergétique  des  bâtiments sur le marché. Celle-ci est exprimée par plusieurs indicateurs ; des mesures d’amélioration sont  également proposées.<br/>Un certificat PEB est obligatoire dès qu'un logement (maison ou appartement) est mis en vente ou en location. Il doit être réalisé par un certificateur agréé.<br/>Attention, Quickscan évalue la performance énergétique de votre bâtiment selon une méthode simplifiée mais ne vous fournit pas un certificat PEB!`,
    },
  },
  OfficialObjectives: {
    bodyoneheader: {
      title: "En route vers le label A en 2050!",
      description: `La Wallonie veut vous aider ! Une grande partie des habitations de notre région est très ancienne et ne répond pas aux normes de performances énergétiques. La Wallonie a décidé d'agir en fixant un objectif de rénovation ambitieux : d'ici 2050, les habitations wallonnes devront atteindre en moyenne le label A. Cette action de grande envergure s'inscrit dans les objectifs de l'Europe pour réduire les émissions de gaz à effet de serre.`,
    },
    footerboxheader: {
      site: {
        title: "Site",
        site3: "Signaler un problème",
        site1: "Vie privée / GDPR",
        site2: `Mentions légales`,
      },
      utiles: {
        title: `Liens utiles`,
        array: ["Walloreno", "SPW Energie", "PMP asbl", "Be-Reel!"],
      },
      developer: {
        title: "Développé par",
        bottom: "Étude menée par",
      },
      bottomMessage: {
        title: `Projet développé par pmp asbl à l’initiative de la Région wallonne avec l’aide de l’instrument financier LIFE de la commission européenne – LIFE IP CA 2016 BE-REEL !`,
      },
    },
  },
  stepButton: {
    previous: "PRÉCÉDENT",
    next: "SUIVANT",
  },
  modal: {
    error: `<p>Désolé. Un problème est survenu. Veuillez réessayer plus tard.</p><p> Si l'erreur persiste, veuillez contacter <a href="mailto:celine.renard@spw.wallonie.be">le SPW</a></p>`,
    form: "Assurez-vous de répondre à chaque question.",
    email: "Un email vous a été envoyé",
    button: "Continuer",
    calculation: "Calcul de votre résultat en cours...",
    calculationSub: "Nous vous redirigerons lorsque le calcul sera effectué.",
  },
  infobox: {
    button: `D'accord`,
  },
  switch: {
    yes: "Oui",
    no: "Non",
    idk: "Je ne sais pas",
  },
  stepOne: {
    stepTitle: "De quel type est votre habitation?",
    questionOne: {
      title: "De quand date la construction de votre habitation?",
      infoboxHeader: "De quand date la construction de votre habitation?",
      infoboxDescription: `La date de construction est un élément essentiel pour déterminer le bilan énergétique de votre habitation. En effet, les exigences de performances énergétiques ont évolué avec le temps et Quickscan se basera sur ces exigences pour déterminer les valeurs par défaut notamment lorsque la présence d'isolation n'est pas connue. Si vous n’avez aucune idée de la date de construction, sélectionnez la case « je ne sais pas » ; différentes typologies possibles vous seront alors proposées et vous pourrez sélectionner celle qui vous semble la plus approchante de votre habitation.<br/><br/>
      Bon à savoir : votre administration communale peut sans doute vous aider pour déterminer la date de construction.`,
      answerOne: `Je ne sais pas`,
      answerlistOne:"Avant 1918",

    },
    questionTwo: {
      title: "Mon habitation se situe",
      infoboxHeader: "Mon habitation se situe",
      infoboxDescription: `La localisation de votre habitation nous permet de vous proposer la typologie la plus adaptée. Si vous estimez que votre habitation n’est ni vraiment en ville, ni vraiment à la campagne, sélectionnez « autre ».`,
      answerOne: `En ville`,
      answerTwo: `À la campagne ou dans un village`,
      answerThree: `Autre`,
    },
    questionThree: {
      title: "Mon habitation est ...",
      infoboxHeader: "Mon habitation est...",
      infoboxDescription: `La question permet de déterminer le nombre de façades de déperditions de chaleur du logement, en contact avec l’extérieur ou des espaces non chauffés. Si l'habitation possède 4 façades complètement indépendantes ou si l'une d'entre elles est accolée à un garage, une grange ou une étable, veuillez cocher la réponse "indépendante (4 façades)". Si l'habitation possède une seule façade accolée à une autre habitation ou un commerce, veuillez cocher la réponse "semi-mitoyenne (3 façades)". Si l'habitation possède deux façades accolées à des habitations ou des commerces, veuillez cocher la réponse "mitoyenne (2 façades)"`,
      answerOne: `Mitoyenne (2 façades)`,
      answerTwo: `Semi-mitoyenne  (3 façades)`,
      answerThree: `Indépendante (4 façades)`,
    },
    questionFour: {
      title: "Nombre de niveaux habitables (hors grenier non aménagé et cave)?",
      infoboxHeader: "Nombre de niveaux habitables?",
      infoboxDescription:
        "Le nombre de niveaux habitables comprend tous les étages de votre habitation (rez-de-chaussée compris) que vous occupez régulièrement et qui sont chauffés directement ou indirectement. Ne considérez donc pas les caves et le grenier non aménagé. Si le niveau grenier est aménagé, il faut par contre le prendre en compte.",
      answerOne: `1 niveau (rez)`,
      answerTwo: `2 niveaux (rez+1)`,
      answerThree: `3 niveaux ou + (rez+2 ou plus)`,
    },
  },
  stepTwo: {
    stepTitle: "Validation de votre type d’habitation",
    questionOne: {
      title:
        "Votre habitation est-elle conforme à la description type choisie pour vous ?",
      infoboxHeader: "Typologie",
      infoboxDescription: `L’association de votre habitation à une typologie de référence permet de répondre à 2 objectifs : <br/> 1. déterminer des surfaces de déperditions représentatives du type de logement pour permettre le calcul du bilan énergétique<br/> 2. connaître les compositions de parois probables en fonction de l’époque de construction<br/> Votre habitation ne doit pas ressembler strictement à la typologie de référence. Le plus important est qu’elle corresponde à la bonne période constructive et présente le bon nombre de façades.`,
      select: `Sélectionner`,
      backProposal: "Revenir à la proposition initiale",
      proposal: "Autres propositions",
      allTypologies: "Toutes les typologies",
      allTypologiesLong: "Voir toutes les typologies existantes",
      defaultButtonText:
        "Je n’ai pas trouvé le type de maison qui me correspond",
    },
  },
  stepThree: {
    stepTitle: "Isolation",
    modal: "Veuillez indiquer une valeur pour",
    questionOne: {
      title: "Quel est le type de vitrage principal?",
      infoboxHeader: "Quel est le type de vitrage principal?",
      infoboxDescription: `Si vous disposez de différents types de vitrage, sélectionnez le type de vitrage dont la surface est la plus importante. <br/><br/>
      Considérez un double vitrage d’avant 2000 comme un « ancien » double vitrage. S’il est plus récent, considérez- le comme un double vitrage récent.`,
      answerOne: `Simple vitrage`,
      answerTwo: `Double vitrage`,
      answerThree: `Triple vitrage`,
      answerFour: `Double vitrage assez ancien (Avant 2000)`,
      answerFive: `Double vitrage assez récent (A partir de 2000)`,
    },
    questionTwo: {
      title: `Un isolant est-il présent dans l'épaisseur de la toiture ou du plafond?`,
      infoboxHeader: `Un isolant est-il présent dans l'épaisseur de la toiture ou du plafond?`,
      infoboxDescription: `La plupart des isolants offrent un niveau de performance similaire : laine de roche, laine de verre, polystyrène expansé, polystyrène extrudé, cellulose, laine de bois ou de chanvre.<br/><br/> C’est leur épaisseur qui permettra de définir le niveau d’isolation de la paroi.<br/><br/> Le polyuréthane en panneaux est toutefois un isolant qui offre un niveau de performance un peu supérieur. Si vous en avez placé, vous pouvez considérer pour celui-ci une épaisseur un peu supérieure à celle placée. Par exemple, si vous avez placé un panneau de 12 cm de polyuréthane, vous pouvez sélectionner « entre 14 et 18 cm » pour cette paroi. <br/><br/> L’obtention des primes de la Région wallonne implique le respect de niveaux de performances minimales. C’est sur cette performance que Quickscan se base si vous avez obtenu ce type de primes.`,
      answerOne: `Oui`,
      answerTwo: `Non`,
      answerThree: `Je ne sais pas`,
      answerFour: `Certaines mais pas toutes`,
      answerFive: `Seulement dans certaines parties de toitures/plafonds`,
    },
    questionTwoSubOne: {
      title: `Quelle est l'épaisseur approximative de l'isolant?`,
      answerFive: `Je ne sais pas `,
    },
    questionTwoSubTwo: {
      title: `Savez-vous si des primes à l’isolation ont été demandées pour ces travaux? `,
      answerOne: `Oui, j'ai bénéficié des primes de la Région wallonne et/ou de réductions d'impôts`,
      answerTwo: `Non, je n'ai jamais bénéficié de primes  et/ou de réductions d'impôts`,
    },
    questionTwoSubThree: {
      text: `Quickscan ne permet pas de simuler de façon exacte une habitation présentant des parois partiellement isolées ou d'épaisseurs d'isolant différentes. Choisir la réponse adaptée à la plus grande part des parois vous permettra d’approcher de la réalité. Si vous souhaitez une approche plus précise, il est nécessaire de faire réaliser un audit logement.`,
    },
    questionThree: {
      title: `Un  isolant est-il présent dans l'épaisseur des murs?`,
      infoboxHeader: `Un  isolant est-il présent dans l'épaisseur des murs?`,
      infoboxDescription: `La plupart des isolants offrent un niveau de performance similaire : laine de roche, laine de verre, polystyrène expansé, polystyrène extrudé, cellulose, laine de bois ou de chanvre.<br/><br/>
      C’est leur épaisseur qui permettra de définir le niveau d’isolation de la paroi.<br/><br/>
      Le polyuréthane en panneaux est toutefois un isolant qui offre un niveau de performance un peu supérieur. Si vous en avez placé, vous pouvez considérer pour celui-ci une épaisseur un peu supérieure à celle qui a été placée. Par exemple, si vous avez placé un panneau de 8 cm de polyuréthane, vous pouvez sélectionner « 10 cm et plus » pour cette paroi.<br/><br/>
      L’obtention des primes de la Région wallonne impliquait le respect de niveaux de performances minimales. C’est sur cette performance que nous nous basons si vous avez obtenu ce type de primes.`,
      answerOne: `Oui`,
      answerTwo: `Non`,
      answerThree: `Je ne sais pas`,
      answerFour: `Certains mais pas tous`,
      answerFive: `Seulement dans certains murs extérieurs`,
    },
    questionThreeSubOne: {
      title: `Quelle est l'épaisseur approximative de l'isolant?`,
      answerFour: `Je ne sais pas `,
    },
    questionThreeSubTwo: {
      title: `Savez-vous si des primes à l’isolation ont été demandées pour ces travaux?`,
      answerOne: `Oui, j'ai bénéficié de primes et/ou de réductions d'impôts`,
      answerTwo: `Non`,
      answerThree: `Je ne sais pas`,
    },
    questionThreeSubThree: {
      title: `S'agit-il d'une isolation …`,
      answerOne: `Côté intérieur du mur`,
      answerTwo: `A l'intérieur du mur (dans le cas des murs creux)`,
      answerThree: `Côté extérieur du mur`,
    },
    questionThreeSubFour: {
      text: `Quickscan ne permet pas de simuler de façon pertinente une habitation présentant des parois « mixtes » (parois possédant des épaisseurs d'isolant différentes). Choisir la réponse adaptée à la plus grande part des parois vous permettra d’approcher de la réalité. Si vous souhaitez une approche plus précise, il est nécessaire de faire réaliser un audit logement.`,
    },
    questionFour: {
      title: `Un isolant est-il présent dans le plancher? (ne considérez que les planchers sur le sol, vers l'extérieur ou vers une cave/vide ventilé)`,
      infoboxHeader: `Un isolant est-il présent dans le plancher? (ne considérez que les planchers sur le sol, vers l'extérieur ou vers une cave/vide ventilé)`,
      infoboxDescription: `La plupart des isolants offrent un niveau de performance similaire : laine de roche, laine de verre, polystyrène expansé, polystyrène extrudé, cellulose, laine de bois ou de chanvre.<br/><br/> C’est leur épaisseur qui permettra de définir le niveau d’isolation de la paroi. <br/><br/>
      Le polyuréthane en panneaux est toutefois un isolant qui offre un niveau de performance un peu supérieur. Si vous en avez placé, vous pouvez considérer pour celui-ci une épaisseur un peu supérieure à celle placée. Par exemple, si vous avez placé un panneau de 8 cm de polyuréthane, vous pouvez sélectionner « 10 cm et plus » pour cette paroi.<br/><br/> L’obtention des primes de la Région wallonne impliquait le respect de niveaux de performances minimales. C’est sur cette performance que nous nous basons si vous avez obtenu ce type de primes.`,
      answerOne: `Oui`,
      answerTwo: `Non`,
      answerThree: `Je ne sais pas`,
      answerFour: `Certains mais pas tous`,
      answerFive: `Seulement dans certains planchers`,
    },
    questionFourSubOne: {
      title: `Quelle est l'épaisseur approximative de l'isolant?`,
      answerFour: `Je ne sais pas `,
    },
    questionFourSubTwo: {
      title: `Savez-vous si des primes à l’isolation ont été demandées pour ces travaux?`,
      answerOne: `Oui, j'ai bénéficié de primes de la Région wallonne`,
      answerTwo: `Non`,
      answerThree: `Je ne sais pas`,
    },
    questionFourSubThree: {
      title: `S'agit-il d'une isolation …`,
      answerOne: `par le dessus`,
      answerTwo: `par le dessous (plafond des caves et des vides ventilés)`,
    },
    questionFourSubFour: {
      title: `Avez-vous du chauffage par le sol?`,
      answerOne: `Oui`,
      answerTwo: `Non`,
    },
    questionFourSubFive: {
      text: `Quickscan ne permet pas de simuler de façon pertinente une habitation présentant des parois « mixtes ». Choisir la réponse adaptée à la plus grande part des parois vous permettra d’approcher la réalité. Si vous souhaitez une approche plus précise, il est nécessaire de faire réaliser un audit logement.`,
    },
  },
  stepFour: {
    stepTitle: `Ventilation et étanchéité à l'air`,
    questionOne: {
      title: "Y a-t-il un système de ventilation dans le logement?",
      infoboxHeader: "Y a-t-il un système de ventilation dans le logement?",
      infoboxDescription: `Depuis 1996, une norme impose la mise en place d’un système de ventilation conforme pour toutes les habitations neuves. Des exigences partielles s’appliquent également pour les bâtiments existants en cas de rénovation. Dans la pratique toutefois, très peu d’habitations disposent d’un système de ventilation complet et conforme.<br/><br/>
      L’objectif de l’exigence de ventilation est de garantir la qualité de l’air intérieur dans les logements, pour le confort des occupants, et de limiter les risques de condensation liés à l’excès d’humidité, qui pourraient nuire à la salubrité du logement (apparition de moisissures).<br/><br/>
      Un bâtiment peu étanche à l’air peut ne pas nécessiter de système de ventilation pour être « sain » mais entraine des pertes énergétiques importantes liées à ce renouvellement d’air permanent, involontaire et incontrôlable.<br/><br/>
      Lorsqu’un bâtiment fait l’objet de travaux de rénovation successifs amenant progressivement l’amélioration de l’étanchéité à l’air de celui-ci, la mise en place d’un système de ventilation devient nécessaire pour éviter des phénomènes de condensation superficielle (moisissures – taches noires) ou interne (destruction progressive de certains matériaux sous l’effet de l’humidité).<br/><br/>
      Ces travaux d’amélioration de l’étanchéité à l’air de l’enveloppe peuvent être liés à des travaux d’isolation, mais ne le sont pas forcément ; citons notamment : remplacement des châssis, pose de membranes pare-vapeur, rénovation des plafonnages, mise en peinture spécifique...`,
      answerOne: `Non, on ouvre les fenêtres si nécessaire  `,
      answerTwo: `Il y a des extracteurs d'air dans la salle de bains et/ou le wc`,
      answerThree: `Il y a des grilles d'amenée d'air dans la partie supérieure de certains vitrages et des extracteurs d'air dans la salle de bains et le wc`,
      answerFour: `Il y a un groupe de ventilation double flux avec récupération de chaleur et des bouches de pulsion ou d'extraction dans la plupart des pièces`,
    },
    questionTwo: {
      title: `Avez-vous l'impression que votre habitation présente des courants d'air?`,
      infoboxHeader: `Avez-vous l'impression que votre habitation présente des courants d'air?`,
      infoboxDescription: `L’absence d’étanchéité à l’air des habitations se marque surtout par des fuites d’air au niveau des châssis en eux-mêmes, au niveau des raccords châssis / vitrage et au niveau des raccords châssis / maçonnerie.<br/><br/> Elle se marque souvent également au niveau des portes : bas de portes et/ou périmètre de celles-ci.<br/><br/> Les maçonneries sont généralement recouvertes de plafonnage, assez étanche à l’air. Les revêtements lambrissés par contre peuvent eux aussi être l’objet d’entrées d’air importantes.<br/><br/> Enfin, des entrées d’air peuvent également provenir de certains équipements spécifiques : boîtes aux lettres, chatières, hottes, cheminées ou feux ouverts...`,
      answerOne: `Oui, je ressens très régulièrement des courants d'air inconfortables dans mon logement`,
      answerTwo: `Oui, je ressens parfois des courants d'air inconfortables dans mon habitation, surtout quand il y a beaucoup de vent`,
      answerThree: `Non, cela ne m'occasionne en tout cas pas d'inconfort`,
      answerFour: `Non, j'ai soigné les finitions de mon habitation pour ne plus ressentir de courants d'air`,
      answerFive: `Je ne sais pas`,
    },
    questionThree: {
      title: `Votre habitation présente-t-elle des problèmes d'humidité ou de condensation? `,
      infoboxHeader: `Votre habitation présente-t-elle des problèmes d'humidité ou de condensation?`,
      infoboxDescription: `Les phénomènes de condensation sont dus à la combinaison :<br/>
      - d’un excès de production d’humidité (cuisson, douche ou bain mais aussi simplement respiration)<br/>
      - d’une insuffisance de ventilation<br/>
      - de points « froids » au niveau des parois (toit, murs, sol) du bâtiment lorsque certaines parois sont non-isolées ou mal isolées.<br/>
      Ils se distinguent des phénomènes d’humidité ascensionnelle qui peuvent apparaitre en pied de mur au rez-de- chaussée ou des phénomènes d’entrées d’eau locaux qui peuvent être liés à des défauts d’étanchéité de toiture, de corniches, ...`,
      answerOne: `Non`,
      answerTwo: `Oui, localement, dans la salle de bains, on peut parfois observer des taches de condensation ou de moisissures; les joints des carrelages noircissent`,
      answerThree: `Oui, localement, dans les chambres, on peut voir des taches noires de moisissures sur le pourtour du plafonnage le long des châssis ou derrière certains meubles`,
      answerFour: `Oui, à beaucoup d'endroits différents, on observe des problèmes et on n'arrive pas à trouver la solution`,
      answerFive: `Non, mais en hiver, les vitrages sont régulièrement couverts de buée dans différentes pièces de l'habitation (salle de bains ou cuisine en général, chambres le matin au réveil)`,
    },
  },
  stepFive: {
    stepTitle: `Systèmes de chauffage`,
    questionOne: {
      title: "Quel est le système de chauffage principal?",
      infoboxHeader: "Quel est le système de chauffage principal?",
      infoboxDescription: `Considérez comme système de chauffage principal celui qui permet de chauffer la plus grande partie de l’habitation. En présence d’un système de chauffage central et d’un système de chauffage d’appoint (poêle par exemple), c’est le système de chauffage central qu’il faut sélectionner ici, même si vous estimez utiliser davantage le poêle. Le poêle pourra être sélectionné à la question suivante.`,
      answer1 : "Poêle",
      answer2 : "Poêle au gaz",
      answer3 : "Poêle à mazout",
      answer4 : "Poêle au bois",
      answer5 : "Poêle à pellets",
      answer6 : "Chaudière à condensation",
      answer7 : "Chaudière à condensation au mazout",
      answer8 : "Chaudière à condensation au gaz",
      answer9 : "Chaudière sans condensation",
      answer10 : "Chaudière sans condensation au mazout",
      answer11 : "Chaudière sans condensation au gaz",
      answer12 : "Pompe à chaleur",
      answer13 : "Chauffage central autre",
      answer14 : "Chauffage central au bois",
      answer15 : "Chauffage central aux pellets",
      answer16 : "Chauffage électrique (convecteurs ou chauffage à accumulation)"
    },
    questionTwo: {
      title: `Avez-vous un système de chauffage d'appoint?`,
      infoboxHeader: `Avez-vous un système de chauffage secondaire?`,
      infoboxDescription: `Sélectionnez ici le(s) éventuels(s) système(s) d'appoint, qui ne desser(ven)t que l'une ou l'autre pièce de l'habitation`,
      answerOne: `Oui`,
      answerTwo: `Non`,
    },
    questionTwoSubOne: {
      title: `Quel type de chauffage d'appoint avez-vous?`,
      answerOne: `Chauffage électrique (convecteurs ou chauffage à accumulation)`,
      answerTwo: `Poêle au gaz ou au mazout`,
      answerThree: `Poêle au bois`,
      answerFour: `Poêle à pellets`,
      answerFive: `Pompe à chaleur `,
      answerSix: `Chauffage central `,
    },
  },
  stepSix: {
    stepTitle: `Système de production d'eau chaude`,
    questionOne: {
      title: `Quel est le système de production d'eau chaude de la salle de bains?`,
      infoboxHeader: `Quel est le système de production d'eau chaude de la salle de bains?`,
      infoboxDescription: `Si le système de production d’eau chaude est différent pour la cuisine et pour la salle de bains, choisir ici le système présent pour la salle de bains, qui représente l’essentiel de la consommation d’eau chaude d’une habitation.`,
      answerOne: `Boiler électrique`,
      answerTwo: `Production instantanée au gaz via la chaudière ou via un chauffe-eau`,
      answerThree: `Production par ancienne chaudière au mazout- avec un ballon de stockage `,
      answerFour: ` Production par chaudière gaz ou chaudière récente au mazout - avec un ballon de stockage`,
      answerFive: `Pompe à chaleur pour l'eau chaude sanitaire (boiler thermodynamique)`,
    },
  },
  stepSeven: {
    stepTitle: `Production d'électricité`,
    questionOne: {
      title: `L'habitation possède-t-elle des panneaux solaires pour la production d'eau chaude?`,
      infoboxHeader: `L'habitation possède-t-elle des panneaux solaires pour la production d'eau chaude?`,
      infoboxDescription: `Une installation solaire thermique convertit le rayonnement solaire en chaleur qui alimente généralement un ballon de stockage destiné à l’eau chaude sanitaire. Le calcul est basé sur une installation thermique « standard » soit 2 panneaux de +/- 2m² et un ballon de stockage de +/- 300 litres.`,
      answerOne: `Oui`,
      answerTwo: `Non`,
    },
    questionTwo: {
      title: `L’habitation possède-t-elle des panneaux photovoltaïques pour produire de l’électricité? `,
      infoboxHeader: `L’habitation possède-t-elle des panneaux photovoltaïques pour produire de l’électricité?`,
      infoboxDescription: `Une installation photovoltaïque convertit le rayonnement solaire en électricité et est caractérisée par sa puissance crête, son orientation et son inclinaison.<br/><br/> Si vous connaissez le nombre de panneaux et la puissance crête installée, il est préférable de se baser sur la puissance crête qui est une donnée plus précise.<br/><br/> Attention, puissance crête et production annuelle sont 2 données différentes. Dans des conditions +/- idéales d’ensoleillement, en Région wallonne, une installation présentant une puissance crête de 2500 Wc produira un peu plus de 2000 kwh/an.`,
      answerOne: `Oui`,
      answerTwo: `Non`,
    },
    questionTwoSubOne: {
      title: `Quelle est la puissance crête installée?`,
      answerOne: `Jusqu'à  3000 Wc`,
      answerTwo: `Entre 3000 et 5000 Wc `,
      answerThree: `Entre 5000 et 7000 Wc `,
      answerFour: `Plus de 7000 Wc`,
      answerFive: `Je ne sais pas, mais je connais le nombre de panneaux `,
    },
    questionTwoSubTwo: {
      title: `Quel est le nombre de panneaux installés?`,
      answerOne: `Jusque 12 panneaux`,
      answerTwo: `Entre 13 et 20 panneaux`,
      answerThree: `Entre 21 et 30 panneaux`,
      answerFour: `Plus de 30 panneaux `,
    },
  },
  result: {
    infoboxHeader: "Votre résultat",
    infoboxDescription: `La performance énergétique de votre habitation a été évaluée par l’outil « Quickscan ».
    <br /><br />Cette évaluation peut différer du certificat énergétique éventuellement disponible pour ce logement ; la méthode de calcul étant simplifiée.
    <br /><br />Afin de vous éviter de devoir réaliser des calculs de surface fastidieux, l’évaluation de l’outil « Quickscan » fonctionne par association de votre habitation à une typologie de référence représentative du parc immobilier wallon.
    <br /><br />Le niveau de performance de « Mon habitation à l’origine » repris dans l’évaluation correspond à la situation d’origine de la typologie de référence associée, évaluée selon la méthode règlementaire PEB.
    <br /><br />Le niveau de performance de « Mon habitation aujourd’hui » se base sur les surfaces de déperditions de la typologie de référence mais y applique les compositions de parois et systèmes tels qu’encodés lors des étapes 3 à 7.
    <br /><br />Les niveaux de performance en « situation après travaux » se basent :
    <div style="display:flex; padding-top:5px;">
      <div style="width:6px; height:6px; background:#000; border-radius:50%; margin-top:8px"></div>
      <div style="text-align:left; width:96%; padding-left:10px;">Pour l’étape 1 sur l’ensemble des recommandations « ISOLER ET VENTILER » qui apparaissent dans le rapport, combinées aux systèmes existants dans le logement.</div>
    </div>

    <div style="display:flex">
    <div style="width:6px; height:6px; background:#000; border-radius:50%; margin-top:8px"></div>
      <div style="text-align:left; width:96%; padding-left:10px;">Pour l’étape 2 sur la combinaison des recommandations « ISOLER ET VENTILER » + « CHAUFFER »</div>
    </div>

    <div style="display:flex; padding-bottom:5px;">
    <div style="width:6px; height:6px; background:#000; border-radius:50%; margin-top:8px"></div>
      <div style="text-align:left; width:96%; padding-left:10px;">Pour l’étape 3 sur la combinaison des recommandations « ISOLER ET VENTILER » + « CHAUFFER » + « PRODUIRE DE L'ENERGIE »</div>
    </div>
NB : si des panneaux solaires photovoltaïques sont déjà présents en situation existante, ils seront pris en compte dans les scénarios d’amélioration des différentes étapes et aucune installation n’est ajoutée en étape 3 (résultat étape 2 = résultat étape 3).`,
    date: "Résultat généré le",
    pageTitle: "Votre résultat",
    report: "VOIR LES RÉSULTATS",
    explication: "Explications",
    share: "Partager",
    optionSection1: "OU",
    downloadPDF:
      "Télécharger le rapport avec les recommandations d’amélioration",
    GeneratePDF: "Veuillez patienter Le fichier PDF est en cours de génération",
    epcProgressResult: "Progression",
    epcProgressResultPDF: "Estimation de votre résultat",
    shareLink: "Vous pouvez partager cette page en copiant le lien suivant",
    email: "Envoyer les résultats par mail",
    emailConfirmation: "Un email vous a été envoyé.",
    modifyTitle: "Vos informations ne sont pas correctes ?",
    modifyContent:
      "Avec ce bouton vous pouvez revenir au début du questionnaire. De cette manière, vous pouvez facilement ajuster les réponses souhaitées.",
    modifyButton: "MODIFIER MES INFORMATIONS",
    emailButton: "Fermer",
    informations: {
      title: "Informations",
      box1New:
        "Vous voulez des informations claires sur la réglementation, les primes et aides en matière d’énergie en vigueur en Wallonie, ou tout autre renseignement et conseil technique relatif à l’énergie ?",
      box1: "Besoin d’explications supplémentaires?",
      box12:
        "Envie de connaître les primes disponibles ou les possibilités de financement?",
      box13: `Envie d’avoir une première estimation du coût des travaux?`,
      box14: `Rdv gratuitement dans un des 16 Guichets Énergie Wallonie`,
      box2: "A la recherche d’informations générales?",
      box22: "Et consultez nos fiches thématiques",
      box22Point1:
        "Pour comprendre les principes de base d’une rénovation efficace.",
      box22Point2:
        "Pour vous aider à vous poser les bonnes questions (choix des travaux, techniques, matériaux…)",
      box22Point3:
        "Pour mettre en évidence les précautions à prendre avant, pendant et après les travaux.",
      box3: "Prêts à passer à l’action?",
      box31: `Envie de passer à l’action mais vous ne savez pas par où commencer ?`,
      box32: "Faites appel à un auditeur logement",
      box32Point1:
        "Pour une analyse énergétique approfondie et détaillée de votre bâtiment",
      box32Point2: "Pour des conseils personnalisés et à la carte",
      box32Point3:
        "Pour une estimation des économies d’énergie et des couts des travaux",
      box4: `Vous voulez vendre ou mettre en location un logement ?`,
      box41: "Contactez un certificateur PEB agréé",
      box5A: "Téléchargez le ",
      box5B: "Guide pratique pour rénover votre logement ",
      box5C: "",
    },
    building: {
      title: "Votre bâtiment aujourd’hui",
      habitation: "Habitation",
      isolation: "Isolation",
      ventilation: "Ventilation et étanchéité",
      heater: "Systèmes de chauffage",
      heaterPrimary: "Chauffage principal",
      heaterSecondary: "Chauffage secondaire",
      water: "Système de production d’eau chaude",
      pv: "Énergies renouvelables",
      pvWater: "Panneaux solaires thermiques",
      pvElec: "Production d’électricité",
    },
    question1: "Vitrage principal : ",
    question2: "Épaisseur isolant toiture ou plafond : ",
    question3: "Savez-vous si des primes à l’isolation ont été demandées?",
    question4: "Épaisseur isolant murs :  ",
    question5: "Savez-vous si des primes à l’isolation ont été demandées?",
    question6: "Épaisseur isolant plancher (ou dalle) : ",
    question7: "Savez-vous si des primes à l’isolation ont été demandées?",
    question8: "Système de ventilation : ",
    question9: "Courants d'air : ",
    question10: "Humidité : ",
    question11: "Isolation toiture  : ",
    question12: "Isolation murs : ",
    question13: "Isolation sol/plancher : ",
    question14: "Position Isolant : ",
    modalContent:
      "Aucun résultat trouvé, vous serez redirigé vers la page d'accueil.",
    modalButton: "D'accord",
    emailError: "Veuillez saisir votre e-mail",
    emailError2:
      "Erreur lors de l'envoie de l'e-mail. Veuillez réessayer plus tard.",
    succesMessageTitle: "Succès",
    succesMessageContent: "Lien copié avec succès",
  },
  endlist: "10 cm et +",
  pdf: {
    userTitle: "Numéro de rapport",
    epcSectionTitle: "Estimation de votre résultat",
    epcStep: "Étape",
    ecoLabelOrigin: "Habitation à l’origine",
    ecoLabelNow: "Habitation aujourd’hui",

    stepsTitle: "Scénario de travaux pour atteindre le label A",
    step1Title: "Etape 1 - Amélioration de l’enveloppe",
    step1Box1Title: "Toiture ou plafond:",
    step1Box1Content:
      "Valeur U à atteindre < 0,2 W/m²K soit environ 20 à 25 cm d’isolant dans l’épaisseur de la charpente selon la nature de l’isolant ou ou 15 à 20 cm en pose continue par le dessus (nécessite de refaire la couverture simultanément)",

    step1Box2Title: "Murs:",
    step1Box2Content:
      "Valeur U à atteindre < 0,24 W/m²K soit environ 16 cm d’isolant posé en continu (nécessite de refaire un nouveau revêtement de façade)",
    step1Box3Title: "Sols:",
    step1Box3Content:
      "Valeur U à atteindre < 0,24 W/m²K soit environ 10 cm d’isolant posé en continu s’il s’agit d’une dalle sur sol (nécessite de refaire une nouvelle chape et un nouveau revêtement de sol)",
    step1Box4Title: "Chassis:",
    step1Box4Content:
      "Placez de nouveaux châssis performants équipés de double vitrage à haut rendement U = 1,0 W/m²K",
    step1Box5Title: "Étanchéité de l’air:",
    step1Box5Content:
      "Valeur v50 à atteindre < 2 m³h/m² (valeur visée vérifiable par un test d’étanchéité à l’air ou Blower Door Test) Pare-vapeur placé devant les isolants, raccords soignés entre parois, bandes périphériques d’étanchéité à l’air autour des châssis.",
    step1Box6Title: "Ventilation:",
    step1Box6Content:
      "Placement d'un système de ventilation complet composé de grilles d’amenée d’air dans les châssis des locaux secs (séjour, chambres, bureau) et d’extracteurs d’air dans les locaux humides (cuisine, salle de bain, buanderie et wc) reliés à des sondes garantissant leur fonctionnement aux moments adaptés.",
    step1BottomText: "Performance énergétique estimée après l’étape 1 :",

    step2Title: "Etape 2 – Amélioration des systèmes",
    step2Box1Title: "Chauffage:",
    step2Box1Content:
      "Installation d’un chauffage central comprenant des radiateurs et une chaudière à condensation",
    step2Box2Title: "Eau chaude :",
    step2Box2Content:
      "Production, si possible instantanée (sans stockage), par la chaudière ou un chauffe-eau",
    step2BottomText: "Performance énergétique estimée après l’étape 2 :",

    step3Title: "Etape 3 - Energies Renouvelables",
    step3Box1Title: "Production d'électricité:",
    step3Box1Content:
      "Pour produire de l'électricité - Placement de 4800 Watts-crête soit environ 16 panneaux",
    step3Box1Content2:
      "Pour produire de l’électricité – Placement de 3600 Watts-crête soit environ 12 panneaux",
    step3Box2Title: "Performance énergétique estimée après l’étape 3 :",

    footerLink: "Rendez-vous sur notre site internet",
  },
};
export default french;
