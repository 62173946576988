import React, { FC, useContext } from "react";
import { GlobalContext } from "../../context/globalContext/globalContext";
import CrossIcon from "../../assets/icons/close.png";
import { setModalHandler } from "../../context/actions/modal";
import { useNavigate } from "react-router-dom";

interface HomePageModallInterface {}

const HomePageModal: FC<HomePageModallInterface> = () => {
  const navigate = useNavigate();
  // global state
  const { state, dispatch }: any = useContext(GlobalContext);

  return (
    <div
      className={`relative z-50 modal-container ${
        state.modal?.modal !== "" ? "opacity-100" : "opacity-0"
      }`}
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      {/* condition to show modal when it's active */}
      {state?.modal?.modal !== "" && (
        <div>
          <div className="fixed inset-0 bg-[#E9F6FD] bg-opacity-75 transition-opacity"></div>
          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex sm:items-center justify-center lg:min-h-full p-4 text-center sm:p-0">
              <div className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 w-full lg:w-7/12">
                {/* header */}
                {state?.modal?.content !== "/" && (
                  <div
                    className="flex justify-end absolute right-0 m-3 cursor-pointer w-fit"
                    onClick={() => {
                      setModalHandler(
                        {
                          content: "",
                          modal: "",
                        },
                        dispatch
                      );
                    }}
                  >
                    <img src={CrossIcon} alt="cross-icon" className="h-3" />
                  </div>
                )}
                {/* body of home page modal */}
                <div>
                  <div className="bg-white px-4 py-8">
                    <div>
                      <div className="text-center w-full">
                        <h3
                          className="text-[18px] text-center leading-6 font-semibold text-[#4C4D4D]"
                          id="modal-title"
                        >
                          {state?.language?.modal?.form}
                        </h3>
                        {state?.modal?.content !== "" &&
                          state?.modal?.content !== "/" &&
                          state?.modal?.content?.length > 0 && (
                            <div className="pt-8 pb-4 flex justify-center">
                              <ul className="font-medium">
                                {state?.modal?.content?.map(
                                  (item: string[], index: number) => (
                                    <li key={index} className="py-1">
                                      {item}
                                    </li>
                                  )
                                )}
                              </ul>
                            </div>
                          )}
                        <div className="pt-8 flex justify-center">
                          <button
                            className="text-white font-medium text-[16px] text-center bg-[#01a2da] hover:bg-[#0590c1] rounded-full py-3 px-10 transition"
                            onClick={() => {
                              if (state?.modal?.content === "/") {
                                navigate("/");
                                setModalHandler(
                                  {
                                    content: "",
                                    modal: "",
                                  },
                                  dispatch
                                );
                              } else {
                                setModalHandler(
                                  {
                                    content: "",
                                    modal: "",
                                  },
                                  dispatch
                                );
                              }
                            }}
                          >
                            {state?.language?.modal?.button}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HomePageModal;
