import React, { FC, useContext } from "react";
import { GlobalContext } from "../../context/globalContext/globalContext";
import InfoIcon from "../../assets/icons/information-button.svg";
import { mainPageScroll } from "../../utils/smoothScroll";

interface BannerInterface {
  section1?: any;
  section2?: any;
}

const Banner: FC<BannerInterface> = ({ section1, section2 }) => {
  // global state
  const { state }: any = useContext(GlobalContext);
  // browser zoom level
  const browserZoomLevel: any =
    (window.outerWidth / window.document.documentElement.clientWidth) * 100;
  // image handler for different zoom level
  function imagePaddingHandler() {
    if (parseInt(browserZoomLevel) === 90) {
      return "lg:pb-[10rem]";
    } else if (parseInt(browserZoomLevel) === 80) {
      return "lg:pb-[18rem]";
    } else if (parseInt(browserZoomLevel) === 75) {
      return "h-[1220px]";
    } else if (parseInt(browserZoomLevel) === 66) {
      return "h-[1380px]";
    } else if (parseInt(browserZoomLevel) === 50) {
      return "h-[1840px]";
    } else if (parseInt(browserZoomLevel) === 33) {
      return "h-[2700px]";
    } else if (parseInt(browserZoomLevel) === 25) {
      return "h-[3500px]";
    } else {
      return "lg:pb-12";
    }
  }

  return (
    <>
      <div
        className={`homepage-banner ${imagePaddingHandler()}`}
        ref={section1}
      >
        <div className="container mx-auto lg:pl-14 lg:pr-12 lg:pb-6 flex flex-col items-center lg:items-start">
          <div className="search-image flex items-center">
            {/* content */}
            <div className="lg:px-[90px] pb-12 px-8">
              <div>
                <h3 className="title text-[#3A484D] font-semibold text-[22px] lg:text-[38px] py-1">
                  {state?.language?.presentationScreen?.title1}
                </h3>
              </div>
              <div>
                <img
                  src={require("../../assets/logo/logo.png")}
                  alt=""
                  className="h-6 lg:h-12"
                />
              </div>
              <div className="description">
                <p className="text-[#3A484D] lg:text-[14px] font-normal lg:pr-2 font-normal lg:text-[16px] py-2 lg:py-6">
                  {state?.language?.presentationScreen?.description}
                </p>
              </div>
              {/* dekstop screen buttons */}
              <div className="pt-4 pb-2 hidden lg:block">
                <div className="w-full md:w-10/12 lg:w-10/12">
                  <button
                    className="text-white w-full font-medium text-[18px] lg:text-[22px] text-center bg-[#01a2da] hover:bg-[#0590c1] rounded-lg py-2 px-4 lg:px-8 hover:scale-110 transition"
                    onClick={() => mainPageScroll(section2.current)}
                  >
                    {state?.language?.presentationScreen?.Button?.title1}
                    <br />
                    {state?.language?.presentationScreen?.Button?.title2}
                  </button>
                </div>
              </div>
              <div className="hidden lg:block">
                <div className="flex items-center lg:px-5">
                  <div className="px-2">
                    <img src={InfoIcon} alt="" className="h-4" />
                  </div>
                  <p className="text-[#3A484D] font-normal text-[14px]">
                    {state?.language?.presentationScreen?.informationText}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* button and information text for small screens */}
          <div className="pt-6 pb-2 px-6 block lg:hidden">
            <div className="w-full">
              <button
                className="text-white w-full font-medium text-[18px] text-center bg-[#01a2da] hover:bg-[#0590c1] rounded-2xl py-2 px-4 hover:scale-110 transition"
                onClick={() => mainPageScroll(section2.current)}
              >
                {state?.language?.presentationScreen?.Button?.title1}
                <br />
                {state?.language?.presentationScreen?.Button?.title2}
              </button>
            </div>
          </div>
          <div className="responsvie-info flex items-center px-6 block lg:hidden">
            <div className="px-2">
              <img src={InfoIcon} alt="" className="h-4" />
            </div>
            <p className="text-[#505e61] font-normal text-[14px]">
              {state?.language?.presentationScreen?.informationText}
            </p>
          </div>
        </div>
        <div className="block lg:hidden">
          <img
            src={require("../../assets/images/homepage/responsiveHouseImage.png")}
            alt=""
            className="w-full"
          />
        </div>
      </div>
    </>
  );
};

export default Banner;
