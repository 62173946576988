import React, { FC, useContext } from "react";
import { GlobalContext } from "../../context/globalContext/globalContext";
import { epcColorHandler } from "../../utils/epcColors";
import { epcImageHandler } from "../../utils/result";
// images of step1
import HouseQ1 from "../../assets/icons/pdfStep/Q2_window.png";
import HouseQ2 from "../../assets/icons/pdfStep/Q3_window.png";
import HouseQ3 from "../../assets/icons/pdfStep/house3.png";
import HouseQ4 from "../../assets/icons/pdfStep/Q1_window.png";
import HouseQ5 from "../../assets/icons/pdfStep/air.png";
import HouseWind from "../../assets/icons/pdfStep/house-wind.png";

interface Step1Interface {}

const Step1: FC<Step1Interface> = () => {
  // Global state
  const { state }: any = useContext(GlobalContext);
  // handler for backend text only (for FR language)
  const stepsHandlerText = (content: string) => {
    // if condtions for backend text to show
    if (
      content?.includes(
        "Pour viser le label A, la valeur U de la paroi rénovée devrait être inférieure ou égale à 0,15 W/m²K. Cette valeur dépend de la composition de la paroi, du type d'isolant et de son épaisseur"
      )
    ) {
      return "Pour viser le label A, la valeur U de la paroi rénovée devrait être inférieure ou égale à 0,15 W/m²K. Cette valeur dépend de la composition de la paroi, du type d'isolant et de son épaisseur";
    }
    if (
      content?.includes(
        "Pour viser le label A, la valeur U de la paroi rénovée devrait être inférieure ou égale à 0,2 W/m²K. Cette valeur dépend de la composition de la paroi, du type d'isolant et de son épaisseur"
      )
    ) {
      return "Pour viser le label A, la valeur U de la paroi rénovée devrait être inférieure ou égale à 0,2 W/m²K. Cette valeur dépend de la composition de la paroi, du type d'isolant et de son épaisseur";
    }
    if (
      content?.includes(
        "Pour viser le label A, la valeur U de la paroi rénovée devrait être inférieure ou égale à 0,15 W/m²K. Cette valeur dépend de la composition de la paroi, du type d'isolant et de son épaisseur"
      )
    ) {
      return "Pour viser le label A, la valeur U de la paroi rénovée devrait être inférieure ou égale à 0,15 W/m²K. Cette valeur dépend de la composition de la paroi, du type d'isolant et de son épaisseur";
    }
    if (
      content?.includes(
        "Pour viser le label A, la valeur U de la paroi rénovée devrait être inférieure ou égale à 0,20 W/m²K. Cette valeur dépend de la composition de la paroi, du type d'isolant et de son épaisseur"
      )
    ) {
      return "Pour viser le label A, la valeur U de la paroi rénovée devrait être inférieure ou égale à 0,20 W/m²K. Cette valeur dépend de la composition de la paroi, du type d'isolant et de son épaisseur";
    }
    if (
      content?.includes(
        "Pour viser le label A, la valeur U de la paroi rénovée devrait être inférieure ou égale à 0,24 W/m²K. Cette valeur dépend de la composition de la paroi, du type d'isolant et de son épaisseur"
      )
    ) {
      return "Pour viser le label A, la valeur U de la paroi rénovée devrait être inférieure ou égale à 0,24 W/m²K. Cette valeur dépend de la composition de la paroi, du type d'isolant et de son épaisseur";
    }
    if (
      content?.includes(
        "Pour viser le label A, la valeur U de la paroi rénovée devrait être inférieure ou égale à 0,15 W/m²K. Cette valeur dépend de la composition de la paroi, du type d'isolant et de son épaisseur"
      )
    ) {
      return " Pour viser le label A, la valeur U de la paroi rénovée devrait être inférieure ou égale à 0,15 W/m²K. Cette valeur dépend de la composition de la paroi, du type d'isolant et de son épaisseur";
    }
    if (
      content?.includes(
        "Pour viser le label A, la valeur U de la paroi rénovée devrait être inférieure ou égale à 0,20 W/m²K. Cette valeur dépend de la composition de la paroi, du type d'isolant et de son épaisseur"
      )
    ) {
      return "Pour viser le label A, la valeur U de la paroi rénovée devrait être inférieure ou égale à 0,20 W/m²K. Cette valeur dépend de la composition de la paroi, du type d'isolant et de son épaisseur";
    }
    if (
      content?.includes(
        "Pour viser le label A, la valeur U de la paroi rénovée devrait être inférieure ou égale à 0,24 W/m²K. Cette valeur dépend de la composition de la paroi, du type d'isolant et de son épaisseur"
      )
    ) {
      return "Pour viser le label A, la valeur U de la paroi rénovée devrait être inférieure ou égale à 0,24 W/m²K. Cette valeur dépend de la composition de la paroi, du type d'isolant et de son épaisseur";
    }
    // if not match then show return emty string to show fixed and backend text
    else {
      return "";
    }
  };
  return (
    <div className={`mr-2 ml-2  mx-auto pt-4`}>
      <div className="py-4 w-full pb-8">
        <p className="text-[#3A484D] text-center text-[30px] font-semibold">
          {state?.language?.pdf?.stepsTitle}
        </p>
      </div>
      {/* step 1 title box */}
      <div
        className={`w-full rounded-3xl py-3`}
        style={{
          backgroundColor: epcColorHandler(
            state?.visitedUserData?.visitor_result?.ecolabel_step1
          ),
        }}
      >
        <div className="pb-2">
          <img
            className="absolute right-0 h-10 font-semibold text-white mr-[10%] mt-[7px]"
            alt="epc-label"
            src={epcImageHandler(
              state?.visitedUserData?.visitor_result?.ecolabel_step1?.toLowerCase()
            )}
          />
          <h5 className="text-center text-[30px] font-semibold text-white m-0">
            {state?.language?.pdf?.step1Title}
          </h5>
        </div>
      </div>
      <div className="py-4 flex flex-wrap">
        {/* 1st */}
        <div className="w-6/12 pr-2">
          <div className="w-full py-2 border-2 border-[#E1F4FB] px-6 rounded-xl h-full">
            <div className="w-full flex pt-2 pb-6">
              {/* left side */}
              <div className="w-full lg:w-2/12 flex pt-1 justify-center">
                <img src={HouseQ1} alt="" className="h-20" />
              </div>
              {/* right side */}
              <div className="w-full lg:px-6 pt-4 lg:pt-0 lg:py-0 lg:w-10/12">
                <h5 className="text-[#3A484D] text-[18px] font-semibold">
                  {state?.language?.pdf?.step1Box1Title}
                </h5>
                {state?.visitedUserData?.pdf_data?.text4 !== undefined &&
                  state?.visitedUserData?.pdf_data?.text4 !== false && (
                    <p className="text-[#3A484D] text-[16px] pt-2">
                      {state?.language?.language === "fr" ? state?.visitedUserData?.pdf_data?.text4 : state?.visitedUserData?.pdf_data_de?.text4}
                    </p>
                  )}
                <p className="text-[#3A484D] text-[16px] pt-2">
                  {state?.visitedUserData?.pdf_data?.text16a !== false
                    ? state?.language?.language === "fr" ? state?.visitedUserData?.pdf_data?.text16a : state?.visitedUserData?.pdf_data_de?.text16a :  "-"
                  }
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* 2nd */}
        <div className="w-6/12 pl-2">
          <div className="w-full py-2 border-2 border-[#E1F4FB] px-6 rounded-xl h-full">
            <div className="w-full flex flex-row pt-2 pb-6">
              {/* left side */}
              <div className="w-full lg:w-2/12 pt-1 flex justify-center h-full">
                <img src={HouseQ2} alt="" className="h-20" />
              </div>
              {/* right side */}
              <div className="w-full lg:px-6 pt-4 lg:pt-0 lg:py-0 lg:w-10/12">
                <h5 className="text-[#3A484D] text-[18px] font-semibold">
                  {state?.language?.pdf?.step1Box2Title}
                </h5>
                {state?.visitedUserData?.pdf_data?.text5 !== undefined &&
                  state?.visitedUserData?.pdf_data?.text5 !== false && (
                    <p className="text-[#3A484D] text-[16px] pt-2">
                      {state?.language?.language === "fr" ? state?.visitedUserData?.pdf_data?.text5 : state?.visitedUserData?.pdf_data_de?.text5}
                    </p>
                  )}
                <p className="text-[#3A484D] text-[16px] pt-2">
                  { state?.visitedUserData?.pdf_data?.text16b !== false ? state?.language?.language === "fr" ? state?.visitedUserData?.pdf_data?.text16b : state?.visitedUserData?.pdf_data_de?.text16b : "-"}
                  {/* {state?.visitedUserData?.pdf_data?.text16b !== false
                    ? state?.language?.language === "fr"
                      ? stepsHandlerText(
                          state?.visitedUserData?.pdf_data?.text16b
                        ) !== ""
                        ? stepsHandlerText(
                            state?.visitedUserData?.pdf_data?.text16b
                          )
                        : `Valeur ${state?.visitedUserData?.pdf_data?.text16b} d’isolant posé en continu (nécessite de refaire un nouveau revêtement de façade)`
                      : `Wert ${state?.visitedUserData?.pdf_data_de?.text16b} der Isolierung, die kontinierlich verlegt ist (die Fassade muss neu verkleidet werden)`
                    : "-"} */}
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* 3rd */}
        <div className="w-6/12 pr-2 pt-3">
          <div className="w-full py-2 border-2 border-[#E1F4FB] px-6 rounded-xl h-full">
            <div className="w-full flex flex-row pt-2 pb-6">
              {/* left side */}
              <div className="w-full lg:w-2/12 flex justify-center">
                <img src={HouseQ3} alt="" className="h-20" />
              </div>
              {/* right side */}
              <div className="w-full lg:px-6 pt-4 lg:pt-0 lg:py-0 lg:w-10/12">
                <h5 className="text-[#3A484D] text-[18px] font-semibold">
                  {state?.language?.pdf?.step1Box3Title}
                </h5>
                {state?.visitedUserData?.pdf_data?.text7 !== undefined &&
                  state?.visitedUserData?.pdf_data?.text7 !== false && (
                    <p className="text-[#3A484D] text-[16px] pt-2">
                      {state?.language?.language === "fr" ? state?.visitedUserData?.pdf_data?.text7 : state?.visitedUserData?.pdf_data_de?.text7}
                    </p>
                  )}
                <p className="text-[#3A484D] text-[16px] pt-2">
                  {state?.visitedUserData?.pdf_data?.text16c !== false ? state?.language?.language === "fr" ? state?.visitedUserData?.pdf_data?.text16c : state?.visitedUserData?.pdf_data_de?.text16c : "-"}
                  {/* {state?.visitedUserData?.pdf_data?.text16c !== false
                    ? state?.language?.language === "fr"
                      ? stepsHandlerText(
                          state?.visitedUserData?.pdf_data?.text16c
                        ) !== ""
                        ? stepsHandlerText(
                            state?.visitedUserData?.pdf_data?.text16c
                          )
                        : `Valeur ${state?.visitedUserData?.pdf_data?.text16c} d’isolant posé en continu s’il s’agit d’une dalle sur sol (nécessite de refaire une nouvelle chape et un nouveau revêtement de sol)`
                      : `Wert ${state?.visitedUserData?.pdf_data_de?.text16c} der Isolierung, die kontinierlich verlegt ist, wenn es sich um Bodenfliesen handelt (Estrich und Bodenbelag müssen erneuert werden)`
                    : "-"} */}
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* 4th */}
        <div className="w-6/12 pl-2 pt-3">
          <div className="w-full py-2 border-2 border-[#E1F4FB] px-6 rounded-xl h-full">
            <div className="w-full flex flex-row pt-2 pb-6">
              {/* left side */}
              <div className="w-full lg:w-2/12 flex justify-center">
                <img src={HouseQ4} alt="" className="h-20" />
              </div>
              {/* right side */}
              <div className="w-full lg:px-6 pt-4 lg:pt-0 lg:py-0 lg:w-10/12">
                <h5 className="text-[#3A484D] text-[18px] font-semibold">
                  {state?.language?.pdf?.step1Box4Title}
                </h5>
                {state?.visitedUserData?.pdf_data?.text6 !== undefined &&
                  state?.visitedUserData?.pdf_data?.text6 !== false && (
                    <p className="text-[#3A484D] text-[16px] pt-2">
                      {state?.language?.language === "fr" ? state?.visitedUserData?.pdf_data?.text6 : state?.visitedUserData?.pdf_data_de?.text6}
                    </p>
                  )}
                <p className="text-[#3A484D] text-[16px] pt-2">
                  {state?.visitedUserData?.pdf_data?.text16replacement !== false ? state?.language?.language === "fr" ? state?.visitedUserData?.pdf_data?.text16replacement : state?.visitedUserData?.pdf_data_de?.text16replacement : "-"}
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* 5th */}
        <div className="w-6/12 pr-2 pt-3">
          <div className="w-full py-2 border-2 border-[#E1F4FB] px-6 rounded-xl h-full">
            <div className="w-full flex flex-row pt-2 pb-6">
              {/* left side */}
              <div className="w-full lg:w-2/12 flex justify-center">
                <img src={HouseQ5} alt="" className="h-20" />
              </div>
              {/* right side */}
              <div className="w-full lg:px-6 pt-4 lg:pt-0 lg:py-0 lg:w-10/12">
                <h5 className="text-[#3A484D] text-[18px] font-semibold">
                  {state?.language?.pdf?.step1Box5Title}
                </h5>
                <p className="text-[#3A484D] text-[16px] pt-2">
                {state?.language?.language === "fr" ? 
                  "Supprimez les fuites d’air involontaires, surtout au niveau des raccords et des portes et fenêtres"
                    :
                  "Entfernen Sie unbeabsichtigte Luftlecks, insbesondere an Verbindungen und Türen und Fenstern."
                }

                </p>
                <p className="text-[#3A484D] text-[16px] pt-2">
                  {state?.language?.pdf?.step1Box5Content}
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* 6th */}
        <div className="w-6/12 pl-2 pt-3">
          <div className="w-full py-2 border-2 border-[#E1F4FB] px-6 rounded-xl h-full">
            <div className="w-full flex flex-row pt-2 pb-6">
              {/* left side */}
              <div className="w-full lg:w-2/12 flex justify-center">
                <img src={HouseWind} alt="" className="h-20" />
              </div>
              {/* right side */}
              <div className="w-full lg:px-6 pt-4 lg:pt-0 lg:py-0 lg:w-10/12">
                <h5 className="text-[#3A484D] text-[18px] font-semibold">
                  {state?.language?.pdf?.step1Box6Title}
                </h5>
                {state?.visitedUserData?.pdf_data?.text8 !== undefined &&
                  state?.visitedUserData?.pdf_data?.text8 !== false && (
                    <p className="text-[#3A484D] text-[16px] pt-2">
                      {state?.language?.language === "fr" ? state?.visitedUserData?.pdf_data?.text8 : state?.visitedUserData?.pdf_data_de?.text8}
                    </p>
                  )}
                <p className="text-[#3A484D] text-[16px] pt-2">
                  {state?.visitedUserData?.pdf_data?.text16Ventilation !== false
                    ? state?.language?.language === "fr"
                      ? state?.visitedUserData?.pdf_data?.text16Ventilation
                      : state?.visitedUserData?.pdf_data_de?.text16Ventilation
                    : "-"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`w-full rounded-3xl bg-[#fff] border-2 py-4 `}
        style={{
          borderColor: epcColorHandler(
            state?.visitedUserData?.visitor_result?.ecolabel_step1
          ),
        }}
      >
        <div className="flex justify-between px-14 items-center">
          <h5
            className={`text-left text-[24px] font-semibold`}
            style={{
              color: epcColorHandler(
                state?.visitedUserData?.visitor_result?.ecolabel_step1
              ),
            }}
          >
            {state?.language?.pdf?.step1BottomText}
          </h5>
          <h5
            className={`text-[35px] font-semibold `}
            style={{
              color: epcColorHandler(
                state?.visitedUserData?.visitor_result?.ecolabel_step1
              ),
            }}
          >
            {state?.visitedUserData?.visitor_result?.ecolabel_step1_value}{" "}
            {state?.language?.language === "fr" ? "kWh/m².an" : "kWh/m².Jahr"}
          </h5>
        </div>
      </div>
    </div>
  );
};

export default Step1;
