import EPC_APlus_Plus from "../assets/icons/epc_scales/peb-aplusplus.svg";
import EPC_APlus from "../assets/icons/epc_scales/peb-aplus.svg";
import EPC_A from "../assets/icons/epc_scales/peb-a.svg";
import EPC_B from "../assets/icons/epc_scales/peb-b.svg";
import EPC_C from "../assets/icons/epc_scales/peb-c.svg";
import EPC_D from "../assets/icons/epc_scales/peb-d.svg";
import EPC_E from "../assets/icons/epc_scales/peb-e.svg";
import EPC_F from "../assets/icons/epc_scales/peb-f.svg";
import EPC_G from "../assets/icons/epc_scales/peb-g.svg";

// payload handler if data is not save then to get it from localStorage
export const getPDFPayloadHandler = (
  localStorageData: any,
  visitorData: any
) => {
  const payload = {
    // age
    age: localStorageData?.homepageFormData?.age[0] + 1,
    // ownership_status
    ownership_status: localStorageData?.homepageFormData?.button,
    // 5
    stepfive: localStorageData.stepfive,
    // 4
    stepfour: localStorageData.stepfour,
    // 1
    stepone: localStorageData.stepone,
    // 7
    stepseven: {
      question_one: localStorageData.stepsix?.question_two,
    },
    // 6
    stepsix: {
      question_one: localStorageData.stepsix?.question_one,
    },
    // 3
    stepthree: localStorageData.stepthree,
    // 2
    steptwo: localStorageData?.steptwo,
    // typology_id
    typology_id: localStorageData?.steptwo?.selectedData?.typology_id,
    // typology_title
    typology_title: localStorageData?.steptwo?.question_one?.answer_id,
    // visitor_id
    visitor_id: visitorData?.data?.id,
    // zipcode
    zipcode: localStorageData?.homepageFormData?.address,
  };
  return payload;
};

// function to return selected epc
export const epcImageHandler = (name: string) => {
  switch (name) {
    case "a++":
      return EPC_APlus_Plus;
    case "a+":
      return EPC_APlus;
    case "a":
      return EPC_A;
    case "b":
      return EPC_B;
    case "c":
      return EPC_C;
    case "d":
      return EPC_D;
    case "e":
      return EPC_E;
    case "f":
      return EPC_F;
    case "g":
      return EPC_G;
    default:
      return EPC_G;
  }
};
