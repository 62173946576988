import React, { FC, useContext } from "react";
import { GlobalContext } from "../../context/globalContext/globalContext";
import EPC from "../../assets/images/homepage/PEB.svg";
import EPCResponsive from "../../assets/images/homepage/PEB-responsive.svg";
// Boxes images
import Heater from "../../assets/images/homepage/heater.svg";
import SolarPanel from "../../assets/images/homepage/solarpanel.svg";
import House from "../../assets/images/homepage/house.svg";
import HeaterTransparency from "../../assets/images/homepage/heater-transparency.svg";
import HouseTransparency from "../../assets/images/homepage/house-transparency.svg";
import SolarPanelTransparency from "../../assets/images/homepage/solarpanel-transparency.svg";

interface ImproveHomeInterface {
  section3?: any;
}

// boxes types
type BoxesType = {
  id: number;
  title: string;
  description: string;
  image: string;
  hoverImage: string;
};

const ImproveHome: FC<ImproveHomeInterface> = ({ section3 }) => {
  // global state
  const { state }: any = useContext(GlobalContext);

  const boxes: BoxesType[] = [
    {
      id: 1,
      title: state?.language?.KeyPoint?.subHeader?.header1?.title,
      description: state?.language?.KeyPoint?.subHeader?.header1?.description,
      image: House,
      hoverImage: HouseTransparency,
    },
    {
      id: 2,
      title: state?.language?.KeyPoint?.subHeader?.header2?.title,
      description: state?.language?.KeyPoint?.subHeader?.header2?.description,
      image: Heater,
      hoverImage: HeaterTransparency,
    },
    {
      id: 3,
      title: state?.language?.KeyPoint?.subHeader?.header3?.title,
      description: state?.language?.KeyPoint?.subHeader?.header3?.description,
      image: SolarPanel,
      hoverImage: SolarPanelTransparency,
    },
  ];
  return (
    <>
      <div className="improve-home-container" ref={section3}>
        <div className="lg:container lg:mx-auto">
          <div className="pt-12 pb-6 px-6 lg:px-16">
            <h2 className="text-center text-[36px] font-[600] text-[#39484d] pt-6 pb-4">
              {state?.language?.KeyPoint?.title}
            </h2>
          </div>
          {/* boxes */}
          <div className="flex flex-col lg:flex-row items-center justify-center py-14">
            {/* boxes */}
            {boxes?.map((item) => (
              <div
                className="w-full py-2 lg:w-4/12 lg:px-16 flex flex-col lg:flex-row justify-center items-center"
                key={item.id}
              >
                <div className="zoom bg-[#daf1f9] rounded-full flex items-center justify-center cursor-pointer z-10">
                  <div>
                    <div className="hvrbox-content-container">
                      <h6 className="text-center font-semibold py-3 text-[#39484d] text-[12px]">
                        {item?.title}
                      </h6>
                      <p className="text-center text-[#39484d] text-[10px]">
                        {item?.description}
                      </p>
                    </div>
                    <div className="flex items-center justify-center img-container">
                      {/* wihtout hover image */}
                      <img
                        src={item?.image}
                        alt=""
                        className="hover-hide-img"
                      />
                      {/* hover image */}
                      <img
                        src={item?.hoverImage}
                        alt=""
                        className="hover-show-img"
                      />
                    </div>
                  </div>
                </div>
                {/* mobile content */}
                <div className="py-2 block lg:hidden w-8/12">
                  <h6 className="text-center font-semibold text-[#39484d] text-[16px]">
                    {item?.title}
                  </h6>
                  <p className="text-center text-[#39484d] py-3 text-[14px]">
                    {item?.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
          {/* card */}
          <div className="pt-8 lg:pb-8 lg:pl-6 lg:pr-4">
            <div className="bg-[#D9F1F9] lg:rounded-2xl">
              <div className="information-content-container px-8 lg:px-[80px] py-9 flex flex-row-reverse lg:flex-col justify-between">
                <div className="w-10/12 lg:w-full h-fit">
                  <div>
                    <h1 className="text-[32px] font-[600] text-[#3A484D]">
                      {state?.language?.KeyPoint?.bottomMessageParagraph?.title}
                    </h1>
                  </div>
                  <div className="py-4">
                    <p
                      className="text-[16px] m-0 font-[400] text-[#3A484D]"
                      dangerouslySetInnerHTML={{
                        __html:
                          state?.language?.KeyPoint?.bottomMessageParagraph
                            ?.description,
                      }}
                    ></p>
                  </div>
                </div>
                <div className="py-2 w-2/12 lg:w-full h-fit responsiveScreen-img-height">
                  <img src={EPC} className="w-full hidden lg:flex" alt="" />
                  <img
                    src={EPCResponsive}
                    className="w-full block lg:hidden "
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImproveHome;
