import React, { FC, useContext } from "react";
import { GlobalContext } from "../../context/globalContext/globalContext";
import { epcColorHandler } from "../../utils/epcColors";
import EPCGRaph from "../../assets/images/result/epc-graph.png";
import { useLocation } from "react-router-dom";

interface HouseScaleInterface {}

const CommonPackBox: FC<HouseScaleInterface> = () => {
  // global state
  const { state }: any = useContext(GlobalContext);
  const location = useLocation();

  // positionPin percentage value handler
  const positionPin = (bigPin: any, grade: any, value: any) => {
    //  Function that calculate the right position for each PIN.
    //  Exact copy of the function in backend.
    //
    //  ***** WARNING *****
    //  Modify it only if you modify the backend function same way.
    //  *******************
    //
    //  There is two king of PIN defined by there size :
    //   - Big (bigPin ===1) : Pin Origin & Pin today
    //   - Small (bigPin !=1) :  Pin step 1 / 2 / 3
    //
    //  For each PEB label, we use the exact same logic :
    //  1. we define in purcentage the min value for left position of pin (for big and small) - Note : This min and max value are based of the position of the vertical line of the PIN.
    //  2. We calculate the relative value of the PEB label result : value - value max of that PEB label
    //  3. We calculate the equartype between relative value and the different between min and max value of the label : (value max PEB label - value min PEB label) - relative value
    //  4. We convert that equartype in purcentage : 100 / (value max PEB label - value min PEB label) * equartype
    //  5. We calculate the position on % of the label : (% of length of that PEB inside graduation picture) / 100 * purcentage step 4
    //  6. We add min value to the position to get definitive positionnement of the PIN : min + left position
    //  7. We return the value and if the value is bigger than max, we set the value to max, if value is lower than min, we set it to min
    //

    if (grade === "g") {
      let min = bigPin === 1 ? -9.6 : -4.6,
        max = bigPin === 1 ? 22.9 : 28;

      let relativeValue = value - 510;
      let equart = 499 - relativeValue;
      let purcent = (100 / 499) * equart;
      let insideLabelPosition = (33 / 100) * purcent;
      let leftPosition = min + insideLabelPosition;

      let finalLeftPosition: any =
        leftPosition > max ? max : leftPosition < min ? min : leftPosition;
      return finalLeftPosition;
    }
    if (grade === "f") {
      let min = bigPin === 1 ? 23.7 : 28.5,
        max = bigPin === 1 ? 33 : 38;

      let relativeValue = value - 425;
      let equart = 84 - relativeValue;
      let purcent = (100 / 84) * equart;
      let insideLabelPosition = (9.78 / 100) * purcent;
      let leftPosition = min + insideLabelPosition;

      let finalLeftPosition: any =
        leftPosition > max ? max : leftPosition < min ? min : leftPosition;
      return finalLeftPosition;
    }
    if (grade === "e") {
      let min = bigPin === 1 ? 33.6 : 38.5,
        max = bigPin === 1 ? 43 : 48;

      let relativeValue = value - 340;
      let equart = 84 - relativeValue;
      let purcent = (100 / 84) * equart;
      let insideLabelPosition = (9.78 / 100) * purcent;
      let leftPosition = min + insideLabelPosition;

      let finalLeftPosition: any =
        leftPosition > max ? max : leftPosition < min ? min : leftPosition;
      return finalLeftPosition;
    }
    if (grade === "d") {
      let min = bigPin === 1 ? 43.7 : 48.5,
        max = bigPin === 1 ? 53 : 58.2;

      let relativeValue = value - 255;
      let equart = 84 - relativeValue;
      let purcent = (100 / 84) * equart;
      let insideLabelPosition = (9.78 / 100) * purcent;
      let leftPosition = min + insideLabelPosition;

      let finalLeftPosition: any =
        leftPosition > max ? max : leftPosition < min ? min : leftPosition;
      return finalLeftPosition;
    }
    if (grade === "c") {
      let min = bigPin === 1 ? 53.7 : 58.5,
        max = bigPin === 1 ? 63.1 : 68.1;

      let relativeValue = value - 170;
      let equart = 84 - relativeValue;
      let purcent = (100 / 84) * equart;
      let insideLabelPosition = (9.78 / 100) * purcent;
      let leftPosition = min + insideLabelPosition;

      let finalLeftPosition: any =
        leftPosition > max ? max : leftPosition < min ? min : leftPosition;
      return finalLeftPosition;
    }
    if (grade === "b") {
      let min = bigPin === 1 ? 63.7 : 68.6,
        max = bigPin === 1 ? 71.1 : 76.1;

      let relativeValue = value - 85;
      let equart = 84 - relativeValue;
      let purcent = (100 / 84) * equart;
      let insideLabelPosition = (7.77 / 100) * purcent;
      let leftPosition = min + insideLabelPosition;

      let finalLeftPosition: any =
        leftPosition > max ? max : leftPosition < min ? min : leftPosition;
      return finalLeftPosition;
    }
    if (grade === "a") {
      let min = bigPin === 1 ? 71.8 : 76.6,
        max = bigPin === 1 ? 77.1 : 82.2;

      let relativeValue = value - 45;
      let equart = 39 - relativeValue;
      let purcent = (100 / 39) * equart;
      let insideLabelPosition = (5.77 / 100) * purcent;
      let leftPosition = min + insideLabelPosition;

      let finalLeftPosition: any =
        leftPosition > max ? max : leftPosition < min ? min : leftPosition;
      return finalLeftPosition;
    }
    if (grade === "a+") {
      let min = bigPin === 1 ? 77.7 : 82.6,
        max = bigPin === 1 ? 83.1 : 88.2;

      let relativeValue = value - 0;
      let equart = 44 - relativeValue;
      let purcent = (100 / 44) * equart;
      let insideLabelPosition = (5.77 / 100) * purcent;
      let leftPosition: any = min + insideLabelPosition;
      let finalLeftPosition =
        leftPosition > max ? max : leftPosition < min ? min : leftPosition;
      return finalLeftPosition;
    }
    if (grade === "a++") {
      let leftPosition: any = bigPin === 1 ? 87 : 90;
      return leftPosition;
    }
  };

  const epcTextColorHandler = (epc: string) => {
    if (
      epc === "a" ||
      epc === "a+" ||
      epc === "a++" ||
      epc === "g" ||
      epc === "b"
    ) {
      return "text-[#fff]";
    } else {
      return "text-[#3A484D]";
    }
  };

  return (
    <div className="relative epc-graph-container pt-8">
      <img src={EPCGRaph} alt="" className="absolute w-full mt-[120px]" />
      {/* bottom box - ecoLabelOrigin*/}
      <div
        className={`absolute w-[20%] ${
          location.pathname.includes("/pdf")
            ? "mt-[171px]"
            : "box-bottom-width1 mt-[170px]"
        }`}
        style={{
          left: `${positionPin(
            1,
            state?.visitedUserData?.visitor_result?.ecolabel_origin,
            state?.visitedUserData?.visitor_result?.ecolabel_origin_value
          )}%`,
        }}
      >
        <div className="flex flex-col items-center">
          {/* line */}
          <div
            className={`h-[17px] bottom-box-1 z-10 mt-[-2px] border-dotted border-l-[2px] lg:border-l-[4px] border-r-0 border-l`}
            style={{
              borderColor: epcColorHandler(
                state?.visitedUserData?.visitor_result?.ecolabel_origin
              ),
            }}
          ></div>
          {/* box */}
          <div
            className={`w-full border rounded-lg p-2 z-20`}
            style={{
              backgroundColor: epcColorHandler(
                state?.visitedUserData?.visitor_result?.ecolabel_origin
              ),
              borderColor: epcColorHandler(
                state?.visitedUserData?.visitor_result?.ecolabel_origin
              ),
            }}
          >
            <p
              className={`text-[8px] ${epcTextColorHandler(
                state?.visitedUserData?.visitor_result?.ecolabel_origin
              )} text-center`}
            >
              {state?.language?.pdf?.ecoLabelOrigin}
            </p>
          </div>
        </div>
      </div>
      {/* bottom box - ecolabel now */}
      <div
        className={`absolute w-[20%]   ${
          location.pathname.includes("/pdf")
            ? "mt-[172px]"
            : "box-bottom-width2 mt-[172px]"
        }`}
        style={{
          left: `${positionPin(
            1,
            state?.visitedUserData?.visitor_result?.ecolabel_now,
            state?.visitedUserData?.visitor_result?.ecolabel_now_value
          )}%`,
        }}
      >
        <div className="flex flex-col items-center">
          {/* line */}
          <div
            className={`h-[45px] bottom-box-2 z-10 lg:h-[70px] mt-[-2px] border-dotted border-l-[2px] lg:border-l-[4px] border-r-0 border-l`}
            style={{
              borderColor: epcColorHandler(
                state?.visitedUserData?.visitor_result?.ecolabel_now
              ),
            }}
          ></div>
          {/* box */}
          <div
            className={`w-full border rounded-lg z-20 p-2`}
            style={{
              backgroundColor: epcColorHandler(
                state?.visitedUserData?.visitor_result?.ecolabel_now
              ),
              borderColor: epcColorHandler(
                state?.visitedUserData?.visitor_result?.ecolabel_now
              ),
            }}
          >
            <p
              className={`text-[8px] ${epcTextColorHandler(
                state?.visitedUserData?.visitor_result?.ecolabel_now
              )} text-center`}
            >
              {state?.language?.pdf?.ecoLabelNow}
            </p>
          </div>
        </div>
      </div>
      {/* top box step 1 */}
      <div
        className={`absolute box-width w-[10%] ${
          location.pathname.includes("/pdf") ? "mt-[90px]" : "mt-[88px]"
        } `}
        style={{
          left: `${positionPin(
            0,
            state?.visitedUserData?.visitor_result?.ecolabel_step1,
            state?.visitedUserData?.visitor_result?.ecolabel_step1_value
          )}%`,
        }}
      >
        <div className="flex flex-col items-center">
          {/* box */}
          <div
            className={`w-full border rounded-lg z-20 box-title-container`}
            style={{
              backgroundColor: epcColorHandler(
                state?.visitedUserData?.visitor_result?.ecolabel_step1
              ),
              borderColor: epcColorHandler(
                state?.visitedUserData?.visitor_result?.ecolabel_step1
              ),
            }}
          >
            <p
              className={`${
                location.pathname.includes("/pdf")
                  ? "text-[14px] "
                  : "text-[8px] lg:text-[14px]"
              }  ${epcTextColorHandler(
                state?.visitedUserData?.visitor_result?.ecolabel_step1
              )}  text-center`}
            >
              {state?.language?.pdf?.epcStep} 1
            </p>
          </div>
          {/* line */}
          <div
            className={`h-[15px] mt-[-2px] z-10 border-l-[2px] lg:border-l-[4px] border-r-0 border-dotted border-l `}
            style={{
              borderColor: epcColorHandler(
                state?.visitedUserData?.visitor_result?.ecolabel_step1
              ),
            }}
          ></div>
        </div>
      </div>
      {/* top box step 2 */}
      <div
        className={`absolute box-width w-[10%] ${
          location.pathname.includes("/pdf") ? "mt-[52px]" : "mt-[50px]"
        }  lg:mt-[40px]`}
        style={{
          left: `${positionPin(
            0,
            state?.visitedUserData?.visitor_result?.ecolabel_step2,
            state?.visitedUserData?.visitor_result?.ecolabel_step2_value
          )}%`,
        }}
      >
        <div className="flex flex-col items-center">
          {/* box */}
          <div
            className={`w-full border rounded-lg box-title-container z-20 `}
            style={{
              backgroundColor: epcColorHandler(
                state?.visitedUserData?.visitor_result?.ecolabel_step2
              ),
              borderColor: epcColorHandler(
                state?.visitedUserData?.visitor_result?.ecolabel_step2
              ),
            }}
          >
            <p
              className={`${
                location.pathname.includes("/pdf")
                  ? "text-[14px] "
                  : "text-[8px] lg:text-[14px]"
              } ${epcTextColorHandler(
                state?.visitedUserData?.visitor_result?.ecolabel_step2
              )} text-center`}
            >
              {state?.language?.pdf?.epcStep} 2
            </p>
          </div>
          {/* line */}
          <div
            className={`h-[60px] step2-line border-dotted border-l-[2px] lg:border-l-[4px] border-r-0  border-l z-10 `}
            style={{
              borderColor: epcColorHandler(
                state?.visitedUserData?.visitor_result?.ecolabel_step2
              ),
            }}
          ></div>
        </div>
      </div>
      {/* top box step 3 */}
      <div
        className={`absolute box-width w-[10%] ${
          location.pathname.includes("/pdf") ? "mt-[12px]" : "mt-[10px]"
        } lg:mt-[-10px]`}
        style={{
          left: `${positionPin(
            0,
            state?.visitedUserData?.visitor_result?.ecolabel_step3,
            state?.visitedUserData?.visitor_result?.ecolabel_step3_value
          )}%`,
        }}
      >
        <div className="flex flex-col items-center">
          {/* box */}
          <div
            className={`w-full border rounded-lg box-title-container z-20 `}
            style={{
              backgroundColor: epcColorHandler(
                state?.visitedUserData?.visitor_result?.ecolabel_step3
              ),
              borderColor: epcColorHandler(
                state?.visitedUserData?.visitor_result?.ecolabel_step3
              ),
            }}
          >
            <p
              className={`${
                location.pathname.includes("/pdf")
                  ? "text-[14px] "
                  : "text-[8px] lg:text-[14px]"
              } ${epcTextColorHandler(
                state?.visitedUserData?.visitor_result?.ecolabel_step3
              )}  text-center`}
            >
              {state?.language?.pdf?.epcStep} 3
            </p>
          </div>
          {/* line */}
          <div
            className={` h-[102px] step3-line border-dotted z-10 border-l-[2px] lg:border-l-[4px] border-r-0  border-l `}
            style={{
              borderColor: epcColorHandler(
                state?.visitedUserData?.visitor_result?.ecolabel_step3
              ),
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default CommonPackBox;
