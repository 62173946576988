import React, { FC, useRef, useEffect } from "react";
// layout
import Layout from "../../components/layout";
import Banner from "../../components/homepage/banner";
import HomeEvaluation from "../../components/homepage/homeEvaluation";
import ImproveHome from "../../components/homepage/improveHome";
import HomePageModal from "../../shared/modals/validationsFormModal";

interface HomePageInterface {}

const HeaderFilter: FC<HomePageInterface> = () => {
  const section1: any = useRef();
  const section2: any = useRef();
  const section3: any = useRef();

  // useEffect to remove localStorage data on homepage
  useEffect(() => {
    localStorage.removeItem("formData");
  }, []);

  return (
    <Layout>
      <>
        {/* modal */}
        <HomePageModal />
        {/* components */}
        <Banner section1={section1} section2={section2} />
        <div className="main-evaluation-container">
          <HomeEvaluation section2={section2} section3={section3} />
        </div>
        <div className="bg-white improve-home-main">
          <ImproveHome section3={section3} />
        </div>
      </>
    </Layout>
  );
};

export default HeaderFilter;
