import React, { FC, useContext } from "react";
import { GlobalContext } from "../../context/globalContext/globalContext";
// EPC icons
import EPC_APlus_Plus from "../../assets/icons/epc_scales/peb-aplusplus.svg";
import EPC_APlus from "../../assets/icons/epc_scales/peb-aplus.svg";
import EPC_A from "../../assets/icons/epc_scales/peb-a.svg";
import EPC_B from "../../assets/icons/epc_scales/peb-b.svg";
import EPC_C from "../../assets/icons/epc_scales/peb-c.svg";
import EPC_D from "../../assets/icons/epc_scales/peb-d.svg";
import EPC_E from "../../assets/icons/epc_scales/peb-e.svg";
import EPC_F from "../../assets/icons/epc_scales/peb-f.svg";
import EPC_G from "../../assets/icons/epc_scales/peb-g.svg";
import EPCGRaph from "./EPCGraph";

interface HouseScaleInterface {}

const HouseScale: FC<HouseScaleInterface> = () => {
  // global state
  const { state }: any = useContext(GlobalContext);
  // function to return selected epc
  const epcImageHandler = (name: string) => {
    switch (name) {
      case "a++":
        return EPC_APlus_Plus;
      case "a+":
        return EPC_APlus;
      case "a":
        return EPC_A;
      case "b":
        return EPC_B;
      case "c":
        return EPC_C;
      case "d":
        return EPC_D;
      case "e":
        return EPC_E;
      case "f":
        return EPC_F;
      case "g":
        return EPC_G;
      default:
        return EPC_G;
    }
  };

  return (
    <div className="mr-2 ml-2  mx-auto pb-4 pt-6 flex justify-center">
      <div className="w-full w-full border-2 rounded-2xl border-[#E1F4FB]">
        <div className="w-full relative h-[540px] bg-white rounded-xl px-6 py-6">
          <div className="flex items-center justify-center lg:pl-4">
            <h1 className="text-[#3A484D] text-[42px] font-[600] pt-1">
              {state?.language?.pdf?.epcSectionTitle}
            </h1>
          </div>
          <div className="flex justify-center pt-4">
            <img
              src={epcImageHandler(
                state?.visitedUserData?.visitor_result?.ecolabel_now?.toLowerCase()
              )}
              className="h-20 ml-[13px]"
              alt=""
            />
          </div>
          <div>
            <EPCGRaph />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HouseScale;
