import React, { FC, useEffect, useContext } from "react";
import { GlobalContext } from "../context/globalContext/globalContext";
import { Routes, Route } from "react-router-dom";
import { visitedUser } from "../utils/visitedUser";
// pages
import HomePage from "../containers/home";
import Form from "../containers/form";
import Result from "../containers/result";
import PDF from "../containers/pdfResult";

interface RoutesInterface {}

/**
 * routes page to define all pages routes here
 */
const PagesRoutes: FC<RoutesInterface> = () => {
  // global state
  const { dispatch }: any = useContext(GlobalContext);
  //useEffect to create new visited user Id which requires at the end for form submission
  useEffect(() => {
    visitedUser(dispatch);
  }, [dispatch]);

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/form" element={<Form />} />
      <Route path="/result/:id" element={<Result />} />
      <Route path="/pdf/:id" element={<PDF />} />
    </Routes>
  );
};
export default PagesRoutes;
