import React, { FC, useContext, useEffect } from "react";
import { GlobalContext } from "../../context/globalContext/globalContext";

interface ResultPageInterface {
  message?: string;
  setState?: any;
  show?: any;
}

const SuccessMessage: FC<ResultPageInterface> = ({
  message,
  setState,
  show,
}) => {
  // global state
  const { state }: any = useContext(GlobalContext);

  //useEffect to show notification for 2 sec
  useEffect(() => {
    const timeoutID = setTimeout(() => {
      setState(false);
    }, 2000);

    return () => window.clearTimeout(timeoutID);
  }, [show, setState]);
  return (
    <div
      className={`absolute top-5  transition-all duration-300 ${
        show
          ? "w-[300px] opacity-100 right-5 z-50"
          : "w-[300px] right-0 opacity-0 "
      }`}
    >
      <div
        className="bg-[#dff8d3] border border-[#a7d590] text-[#40871D] px-4 py-3 rounded relative"
        role="alert"
      >
        <strong className="font-bold ">
          {state?.language?.result?.succesMessageTitle}:
        </strong>
        <span className="block sm:inline px-2">{message}</span>
      </div>
    </div>
  );
};

export default SuccessMessage;
