import React, { FC, useState, useContext } from "react";
import { GlobalContext } from "../../context/globalContext/globalContext";
import { ScreenSize } from "../../utils/windowDimension";
import { setLanguageHandler } from "../../context/actions/language";
import { useLocation, useNavigate } from "react-router-dom";
// languages
import French from "../../languages/fr";
import Dutch from "../../languages/de";

interface HeaderInterface {}

const Header: FC<HeaderInterface> = () => {
  // location to get page detail and navigation for redirecting to another page
  const location = useLocation();
  const navigation = useNavigate();
  // global state
  const { state ,  dispatch }: any = useContext(GlobalContext);
  // to Get ScreenSize
  const screenSize = ScreenSize();
  // toggle state to show and hide language option
  const [toggle, setToggle] = useState(false);
  // state to show default selected language
  const [selectedLanguage, setSelectedLanguage] = useState(state?.language?.language.toUpperCase());
  // handler for language width for large and small screens
  const languageWidthHandler = () => {
    // large screen condition
    if (screenSize?.width > 1023) {
      if(state?.language?.language === "fr"){
        return toggle ? "330px" : "270px";
      }else{
        return toggle ? "350px" : "290px";
      }
    }
    // small screen condition
    else {
      return toggle ? "110px" : "48px";
    }
  };
  // select language handler to change language from selected options
  const selectLanguageHandler = (
    selectedLanguage: string,
    languageData: Object
  ) => {
    setLanguageHandler(languageData, dispatch);
    setSelectedLanguage(selectedLanguage);
    setToggle(!toggle);
  };

  // handler for different pages colors of same header
  const classesHandler = () => {
    if (
      (location?.pathname !== "/" && screenSize?.width < 1024) ||
      location?.pathname === "/result"
    ) {
      return "bg-[#e6f4fb] relative";
    } else if (location?.pathname !== "/" && screenSize?.width >= 1024) {
      return "bg-white relative";
    }
  };

  return (
    <div
      className={`${
        location?.pathname === "/" ? "absolute" : classesHandler()
      } w-full py-5 px-4 header-container z-10 flex`}
    >
      <div
        className={`container mx-auto flex ${
          location?.pathname !== "/" ? "justify-between" : "justify-end"
        } items-center`}
      >
        {/* condition to not show only for homepage */}
        {location?.pathname !== "/" && (
          <div
            className="flex items-center cursor-pointer"
            onClick={() => navigation("/")}
          >
            <img
              src={require("../../assets/logo/quickscan_color.png")}
              alt=""
              className="h-5 lg:h-8"
            />
          </div>
        )}
        {/* right side language selector */}
        <div className="flex justify-end">
          <div
            className={`bg-white rounded-full flex items-center language-selector`}
            style={{
              minWidth: languageWidthHandler(),
            }}
          >
            <div
              className={`py-1 lg:py-2 lg:pl-8 font-[600] flex items-center cursor-pointer z-10 ${
                !toggle && "pr-1"
              }`}
              onClick={() => setToggle(!toggle)}
            >
              <p className="hidden lg:block text-[#3A484D]">
              {state?.language?.headerLanguage}
              </p>
              {/* to show selected language option*/}
              <div className="pl-[4px] lg:pl-3 bg-white rounded-full">
                <p className="rounded-full w-[42px] text-center pt-[10px] pb-[8px] bg-[#00A3DA] h-fit text-white text-md">
                  {selectedLanguage}
                </p>
              </div>
            </div>
          </div>
          {/* to show unselected language option*/}
          {toggle && (
            <div
              className={`${
                toggle
                  ? "mt-[5px] mr-[16px] lg:mt-[9px] lg:mr-[28px] pl-2 absolute"
                  : "hidden"
              }`}
            >
              <p
                className="rounded-full w-10 text-center text-[#3A484D] font-[600] py-2 h-fit cursor-pointer text-md"
                onClick={() => {
                  selectedLanguage === "FR"
                    ? selectLanguageHandler("DE", Dutch)
                    : selectLanguageHandler("FR", French);
                }}
              >
                {selectedLanguage === "FR" ? "DE" : "FR"}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
