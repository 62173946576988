const Reducer = (state: any, action: any) => {
  switch (action.type) {
    case "SET_VISITOR_DATA":
      return {
        ...state,
        visitorData: action.payload,
      };
    case "SET_LANGUAGE":
      return {
        ...state,
        language: action.payload,
      };
    case "SET_MODAL":
      return {
        ...state,
        modal: action.payload,
      };
    case "SET_HOMEPAGE_FORM_DATA":
      return {
        ...state,
        homePageForm: action.payload,
      };
    case "SET_ALL_HOUSES_TYPES":
      return {
        ...state,
        allHousesTypes: action.payload,
      };
    case "SET_FORM_STEP_DATA":
      return {
        ...state,
        stepData: { ...state?.stepData, ...action.payload },
      };
    case "SET_STEP_TWO_SELCETEDHOUSE":
      return {
        ...state,
        stepTwoSelectedHouse: action.payload,
      };
    case "SET_VISITED_USER_DATA":
      return {
        ...state,
        visitedUserData: action.payload,
      };
    default:
      return state;
  }
};
export default Reducer;
