// function to return selected epc color
export const epcColorHandler = (name: string) => {
  switch (name) {
    case "a++":
      return "#01a2da";
    case "a+":
      return "#00823f";
    case "a":
      return "#05a652";
    case "b":
      return "#67B654";
    case "c":
      return "#c0d446";
    case "d":
      return "#dfe142";
    case "e":
      return "#f6c02f";
    case "f":
      return "#ec9441";
    case "g":
      return "#d7242a";
    default:
      return "#d7242a";
  }
};
