/**
 * @param {HTMLElement} target
 */
export const smoothScroll = (target: any) => {
  const element: any = document.getElementById(target);
  // element.scrollIntoView();
  element.scrollIntoView({
    behavior: "smooth",
  });
};

/**
 * @param {HTMLElement} target
 */
export const mainPageScroll = (target: any) => {
  const { top } = target.getBoundingClientRect();
  window.scrollTo({
    top: top + window.pageYOffset,
    behavior: "smooth",
  });
};
