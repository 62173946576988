import React, { FC, useContext } from "react";
import { GlobalContext } from "../../context/globalContext/globalContext";
import { setModalHandler } from "../../context/actions/modal";
import InfoIcon from "../../assets/icons/information-button.svg";
import EPCGRaph from "./EPCGraph";

interface HouseScaleInterface {}

const HouseScale: FC<HouseScaleInterface> = () => {
  // global state
  const { state, dispatch }: any = useContext(GlobalContext);
  return (
    <div className="container mx-auto px-4 lg:px-16 py-4 flex justify-center">
      <div className="w-full lg:w-[91%] lg:pr-[18px]">
        <div className="w-full bg-white h-[410px] rounded-xl px-2 lg:px-7 py-6 lg:mx-2">
          <div className="flex items-center justify-center lg:pl-4">
            <p
              className="text-[#4A4A4A] text-center text-[18px] font-semibold"
              onClick={() => {
                setModalHandler(
                  {
                    content: "stepOne.questionOne",
                    modal: "step1",
                  },
                  dispatch
                );
              }}
            >
              {state?.language?.result?.epcProgressResult}
            </p>
            <span
              className="cursor-pointer w-20 px-4"
              onClick={() => {
                setModalHandler(
                  {
                    content: "",
                    modal: "stepsContent",
                  },
                  dispatch
                );
              }}
            >
              <img src={InfoIcon} alt="" className="h-[20px]" />
            </span>
          </div>
          <EPCGRaph />
        </div>
      </div>
    </div>
  );
};

export default HouseScale;
