import React, { FC, useContext } from "react";
import { GlobalContext } from "../../context/globalContext/globalContext";
// Boxes images
import Heater from "../../assets/images/homepage/heater.svg";
import SolarPanel from "../../assets/images/homepage/solarpanel.svg";
import House from "../../assets/images/homepage/house.svg";
import HeaterTransparency from "../../assets/images/homepage/heater-transparency.svg";
import HouseTransparency from "../../assets/images/homepage/house-transparency.svg";
import SolarPanelTransparency from "../../assets/images/homepage/solarpanel-transparency.svg";

interface ImproveHomeInterface {}

// boxes types
type BoxesType = {
  id: number;
  title: string;
  description: string;
  image: string;
  hoverImage: string;
};

const ImproveHome: FC<ImproveHomeInterface> = () => {
  // global state
  const { state }: any = useContext(GlobalContext);

  const boxes: BoxesType[] = [
    {
      id: 1,
      title: state?.language?.KeyPoint?.subHeader?.header1?.title,
      description: state?.language?.KeyPoint?.subHeader?.header1?.description,
      image: House,
      hoverImage: HouseTransparency,
    },
    {
      id: 2,
      title: state?.language?.KeyPoint?.subHeader?.header2?.title,
      description: state?.language?.KeyPoint?.subHeader?.header2?.description,
      image: Heater,
      hoverImage: HeaterTransparency,
    },
    {
      id: 3,
      title: state?.language?.KeyPoint?.subHeader?.header3?.title,
      description: state?.language?.KeyPoint?.subHeader?.header3?.description,
      image: SolarPanel,
      hoverImage: SolarPanelTransparency,
    },
  ];
  return (
    <>
      <div className="py-3 mr-2 ml-2 ">
        <div className=" lg:mx-auto border-2 rounded-xl border-[#E1F4FB]">
          <div className=" px-6 lg:px-16">
            <h2 className="text-center text-[42px] font-[600] text-[#3A484D] pt-6 pb-4">
              {state?.language?.KeyPoint?.title}
            </h2>
          </div>
          {/* boxes */}
          <div className="flex flex-col lg:flex-row justify-center pt-2 pb-4">
            {/* boxes */}
            {boxes?.map((item) => (
              <div
                className="w-full py-2 lg:w-[32%] flex flex-col items-center"
                key={item.id}
              >
                <div
                  className={`${
                    item.id === 2 ? "px-[48px] py-[42px]" : "p-[42px]"
                  } bg-[#daf1f9] rounded-full flex justify-center cursor-pointer z-10`}
                >
                  <div className={item.id === 1 ? "pt-[-2px] pl-2" : ""}>
                    <div className="flex items-center justify-center">
                      {/* wihtout hover image */}
                      <img src={item?.image} alt="" className="h-32" />
                    </div>
                  </div>
                </div>
                {/* mobile content */}
                <div className="py-2 block w-full px-5">
                  <h6 className="text-center font-semibold text-[#39484d] text-[22px]">
                    {item?.title}
                  </h6>
                  <p className="text-center text-[#4A4A4A] py-3 text-[19px]">
                    {item?.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ImproveHome;
