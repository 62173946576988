import React, { FC, useContext } from "react";
import { GlobalContext } from "../../context/globalContext/globalContext";
import { useLocation, useParams } from "react-router-dom";

interface HeaderInterface {}

const Header: FC<HeaderInterface> = () => {
  // location to get page detail and navigation for redirecting to another page
  const location = useLocation();
  // to get id from url
  let params = useParams();
  // global state
  const { state }: any = useContext(GlobalContext);

  return (
    <div
      className={`bg-white relative w-full pt-9 pb-5 px-4 header-container z-10 flex`}
    >
      <div
        className={`w-full mx-auto flex ${
          location?.pathname !== "/" ? "justify-between" : "justify-end"
        } items-center`}
      >
        <div
          className="flex items-center cursor-pointer"
          // onClick={() => navigation("/")}
        >
          <img
            src={require("../../assets/logo/quickscan_color.png")}
            alt=""
            className="h-12"
          />
        </div>

        {/* right side language selector */}
        <div className="flex justify-end">
          <div>
            <h1 className="text-[#3A484D] text-[22px]">
              {state?.language?.result?.date}
            </h1>
            <h6 className="text-[#3A484D] text-[22px] font-semibold">
              {state?.visitedUserData?.visitor_result?.added
                ? state?.visitedUserData?.visitor_result?.added
                : "-"}
            </h6>
          </div>
          <div className="pl-28">
            <h1 className="text-[#00A3DA] text-[22px]">
              {state?.language?.pdf?.userTitle}
            </h1>
            <h6 className="text-[#00A3DA] text-[22px] font-semibold">
              {params?.id ? params?.id : "-"}
            </h6>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
