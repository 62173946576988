import { createdVisitedUser } from "../context/actions/visitedUser";

// method to create visited User
export const visitedUser = (dispatch: any) => {
  let userExist: any = localStorage.getItem("visitor");
  userExist = JSON.parse(userExist);
  if (userExist !== null && userExist !== undefined) {
    let currentDate: any = new Date();
    currentDate = currentDate.getTime() / 1000;
    let creationDate: any = localStorage.getItem("creationDate");
    creationDate = JSON.parse(creationDate);
    if (currentDate - creationDate > 1200) {
      localStorage.clear();
      // the visitor doesn't exist
      let visitorData = {};
      createdVisitedUser()
        .then((response: any) => {
          visitorData = response.data;
          // this.visitorID = visitorData.data.id;
          localStorage.setItem("visitor", JSON.stringify(visitorData));
          let currentime: any = new Date();
          currentime = currentime.getTime() / 1000;
          localStorage.setItem("creationDate", JSON.stringify(currentime));
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      dispatch({
        type: "SET_VISITOR_DATA",
        payload: userExist.data.id,
      });
    }
  } else {
    // if the the visitor doesn't exist, then we call backend API the create a visitor token.
    let visitorData: any = {};
    createdVisitedUser()
      .then((response) => {
        visitorData = response.data;
        dispatch({
          type: "SET_VISITOR_DATA",
          payload: visitorData.data.id,
        });
        localStorage.setItem("visitor", JSON.stringify(visitorData));
        let currentime: any = new Date();
        currentime = currentime.getTime() / 1000;
        localStorage.setItem("creationDate", JSON.stringify(currentime));
      })
      .catch((error) => {
        console.log(error);
      });
  }
};
