import React, { FC, useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../context/globalContext/globalContext";
import { setModalHandler } from "../../context/actions/modal";
import { smoothScroll } from "../../utils/smoothScroll";
import {
  activeOptionHandler,
  storeStepValues,
  storelocalStorageData,
} from "../../utils/forms";
import { setStepFormValues } from "../../context/actions/formsAction";
import { useNavigate } from "react-router-dom";
// section 4 icons
import Charge1 from "../../assets/icons/questionsForm/step1/charge1.svg";
import Charge2 from "../../assets/icons/questionsForm/step1/charge2.svg";
import Charge3 from "../../assets/icons/questionsForm/step1/charge3.svg";
import ChargeOn1 from "../../assets/icons/questionsForm/step1/chargeOn.svg";
import ChargeOn2 from "../../assets/icons/questionsForm/step1/ChargeOn2.svg";
import ChargeOn3 from "../../assets/icons/questionsForm/step1/chargeOn3.svg";
// section 3 icons
import Adjoining1 from "../../assets/icons/questionsForm/step1/off__1_.svg";
import Adjoining1ON from "../../assets/icons/questionsForm/step1/on__1_.svg";
import Adjoining2 from "../../assets/icons/questionsForm/step1/off__2_.svg";
import Adjoining2On from "../../assets/icons/questionsForm/step1/on__2_.svg";
import Adjoining3 from "../../assets/icons/questionsForm/step1/off.svg";
import AdjoiningOn3 from "../../assets/icons/questionsForm/step1/on.svg";
// all sections icons of this page
import BlueArrow from "../../assets/icons/questionsForm/step1/arrow-down-blue.svg";
import TransparentArrow from "../../assets/icons/questionsForm/step1/arrow-down-transparency.svg";
import NextArrow from "../../assets/icons/questionsForm/step1/arrow-next.svg";
import HouseTree from "../../assets/icons/questionsForm/step1/house-tree.svg";
import TwoHouse from "../../assets/icons/questionsForm/step1/2house.svg";
import HouseLevel from "../../assets/icons/questionsForm/step1/house-level.svg";
import Housel from "../../assets/icons/questionsForm/step1/house1.svg";
import InfoIcon from "../../assets/icons/information-button.svg";
import { ScreenSize } from "../../utils/windowDimension";

interface Step1Interface {
  setShowAnimation: React.Dispatch<React.SetStateAction<string>>;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  selectedQuestion?: number;
  setSelectedQuestion?: any;
  submitHouseDetailHandler?: any;
  formsRightQuestionsHeight?: any;
  formsLeftQuestionsHeight?: any;
  formHeightHandler?: any;
}

// FormOptionTypes types
type FormOptionTypes = {
  id: number;
  name: string;
  selected: string;
};

const Step1: FC<Step1Interface> = ({
  setStep,
  setShowAnimation,
  selectedQuestion,
  setSelectedQuestion,
  submitHouseDetailHandler,
  formsRightQuestionsHeight,
  formsLeftQuestionsHeight,
  formHeightHandler
}) => {
  const navigate = useNavigate();
  const screenSize: any = ScreenSize();
  const browserZoomLevel: any =
    (window.outerWidth / window.document.documentElement.clientWidth) * 100;
  // global state
  const { state, dispatch }: any = useContext(GlobalContext);
  // list of step 1 options
  const formOptions: FormOptionTypes[] = [
    {
      id: 1,
      name: state?.language?.language === "fr" ? "Date" : "Datum",
      selected:
        selectedQuestion === 1 ||
        selectedQuestion === 2 ||
        selectedQuestion === 3 ||
        selectedQuestion === 4
          ? "true"
          : "false",
    },
    {
      id: 2,
      name: state?.language?.language === "fr" ? "Situation" : "Situation",
      selected:
        selectedQuestion === 2 ||
        selectedQuestion === 3 ||
        selectedQuestion === 4
          ? "true"
          : "false",
    },
    {
      id: 3,
      name: state?.language?.language === "fr" ? "Typologie" : "Typologie",
      selected:
        selectedQuestion === 3 || selectedQuestion === 4 ? "true" : "false",
    },
    {
      id: 4,
      name: state?.language?.language === "fr" ? "Niveaux" : "Ebenen",
      selected: selectedQuestion === 4 ? "true" : "false",
    },
  ];
  // state for step1 questions
  const [stepOne, setStepOne] = useState({
    question_one: {
      question_id: 5,
      question_title: "De quand date la construction de votre habitation?",
      answer_id: 9,
      answer_title: "",
    },
    question_two: {
      question_id: 6,
      question_title: "Mon habitation se situe",
      answer_id: 0,
      answer_title: "",
    },
    question_three: {
      question_id: 7,
      question_title: "Mon habitation est ... ",
      answer_id: 0,
      answer_title: "",
    },
    question_four: {
      question_id: 8,
      question_title:
        "Nombre de niveaux habitables (hors grenier non aménagé et cave) ? ",
      answer_id: 0,
      answer_title: "",
    },
  });

  // useEffect to check if data exsit in localStorage to show selectedData
  useEffect(() => {
    let localStorageData: any = localStorage.getItem("formData");
    let formData = JSON.parse(localStorageData);
    if (formData?.stepone) {
      // to store in local and Global states
      setStepOne(formData?.stepone);
      setStepFormValues(formData?.stepone, dispatch);
    }
    // window.scrollTo(0, 0);
  }, [dispatch]);
  useEffect(() =>{
    // window.scrollTo(0,0)
    document.getElementById('root')?.scrollIntoView();
  },[]);
  // handler to store values into Global state
  const submitHandler = () => {
    if (
      stepOne?.question_one?.answer_id === 0 ||
      stepOne?.question_two?.answer_id === 0 ||
      stepOne?.question_three?.answer_id === 0 ||
      stepOne?.question_four?.answer_id === 0
    ) {
      setModalHandler(
        {
          content: "",
          modal: "formError",
        },
        dispatch
      );
    } else {
      setShowAnimation("animate-left2");
      let stepone: any = { stepone: stepOne, formStep: 2 };
      storelocalStorageData(stepone);
      // API Call
      submitHouseDetailHandler(stepOne);
      // to store in global state
      setStepFormValues(stepone, dispatch);
      setStep(2);
      setSelectedQuestion(1);
    }
  };

  return (
    <div className="container mx-auto py-8 px-4 step1-container">
      <div
        className={`flex flex-col items-center lg:items-start lg:flex-row ${
          screenSize?.width < 1500 && formHeightHandler("main")
        }`}
      >
        {/* left side */}
        <div
          className={`w-12/12 lg:w-9/12 lg:overflow-y-auto ${
            parseInt(browserZoomLevel) === 100 ||
            parseInt(browserZoomLevel) === 90 ||
            parseInt(browserZoomLevel) === 80
              ? formHeightHandler("left")
              : formsLeftQuestionsHeight("lg:h-[70vh]")
          }`}
        >
          {/* 1st card */}
          <div
            className={`w-full py-4 pl-4 pr-7 border-2 rounded-2xl mb-6 ${
              selectedQuestion === 1 ? "border-[#00A3DA]" : "border-[#E1F4FB]"
            } `}
          >
            {/* header */}
            <div className="w-full flex items-center justify-between ">
              <div className="flex items-center w-11/12">
                <img src={Housel} alt="" className="h-12" />
                <h5 className="text-[#3A484D] font-semibold px-6 text-[17px]">
                  {state?.language?.stepOne?.questionOne?.title}
                   {/* --- {`{"question_id": ${stepOne.question_one.question_id}, "answer_id": ${stepOne.question_one.answer_id}}`} */}
                </h5>
              </div>
              <div
                className="cursor-pointer w-1/12 flex justify-end"
                onClick={() => {
                  setModalHandler(
                    {
                      content: "stepOne.questionOne",
                      modal: "step1",
                    },
                    dispatch
                  );
                }}
              >
                <img src={InfoIcon} alt="" className="h-5" />
              </div>
            </div>
            {/* content */}
            <div className="flex flex-wrap justify-between lg:justify-start lg:flex-row lg:items-center pt-8 lg:px-6">
              <div
                className="py-2 w-5/12 lg:w-fit"
                onClick={() => {
                  storeStepValues(
                    stepOne,
                    "question_one",
                    "Avant 1918",
                    9,
                    setStepOne
                  );
                  setSelectedQuestion(2);
                  smoothScroll("content2");
                }}
              >
                <div
                  className={`border rounded-2xl px-4 py-3 w-full hover:bg-[#01a2da] hover:text-[#fff] cursor-pointer
                                ${
                                  activeOptionHandler(
                                    stepOne,
                                    "question_one",
                                    "Avant 1918"
                                  ) === true
                                    ? "border-[#01a2da] bg-[#01a2da] text-[#fff]"
                                    : "text-[#3A484D]"
                                }
                                
                `}
                >
                  <p className="text-[15px] text-center">{state?.language?.stepOne?.questionOne?.answerlistOne}</p>
                </div>
              </div>
              {/* line */}
              <div
                className={`hidden lg:block w-6 h-1
                ${
                  // stepOne?.question_one?.answer_title === "Avant 1918" ||
                  stepOne?.question_one?.answer_title === "1918 - 1945" ||
                  stepOne?.question_one?.answer_title === "1971 - 1984" ||
                  stepOne?.question_one?.answer_title === "1985 - 1995" ||
                  stepOne?.question_one?.answer_title === "1946 - 1970" ||
                  stepOne?.question_one?.answer_title === "1996 - 2010"
                    ? "bg-[#01a2da]"
                    : "bg-[#DFE1E2]"
                }
              `}
              ></div>

              <div
                className="py-2 w-5/12 lg:w-fit"
                onClick={() => {
                  storeStepValues(
                    stepOne,
                    "question_one",
                    "1918 - 1945",
                    10,
                    setStepOne
                  );
                  setSelectedQuestion(2);
                  smoothScroll("content2");
                }}
              >
                <div
                  className={`border rounded-2xl px-4 py-2 w-full hover:bg-[#01a2da] hover:text-[#fff] cursor-pointer
                  ${
                    activeOptionHandler(
                      stepOne,
                      "question_one",
                      "1918 - 1945"
                    ) === true
                      ? "border-[#01a2da] bg-[#01a2da] text-[#fff]"
                      : "text-[#3A484D]"
                  }
                  
                `}
                >
                  <p className="text-[15px] text-center my-1">1918 - 1945</p>
                </div>
              </div>
              {/* line */}
              <div
                className={`hidden lg:block w-6 h-1
                ${
                  stepOne?.question_one?.answer_title === "1971 - 1984" ||
                  stepOne?.question_one?.answer_title === "1985 - 1995" ||
                  stepOne?.question_one?.answer_title === "1946 - 1970" ||
                  stepOne?.question_one?.answer_title === "1996 - 2010"
                    ? "bg-[#01a2da]"
                    : "bg-[#DFE1E2]"
                }
                `}
              ></div>

              <div
                className="py-2 w-5/12 lg:w-fit"
                onClick={() => {
                  storeStepValues(
                    stepOne,
                    "question_one",
                    "1946 - 1970",
                    11,
                    setStepOne
                  );
                  setSelectedQuestion(2);
                  smoothScroll("content2");
                }}
              >
                <div
                  className={`border rounded-2xl px-4 py-2 w-full hover:bg-[#01a2da] hover:text-[#fff] cursor-pointer
                  ${
                    activeOptionHandler(
                      stepOne,
                      "question_one",
                      "1946 - 1970"
                    ) === true
                      ? "border-[#01a2da]"
                      : "text-[#3A484D]"
                  }
                  ${
                    activeOptionHandler(
                      stepOne,
                      "question_one",
                      "1946 - 1970"
                    ) === true
                      ? "border-[#01a2da] bg-[#01a2da] text-[#fff]"
                      : "text-[#3A484D]"
                  }
                `}
                >
                  <p className="text-[15px] text-center my-1">1946 - 1970</p>
                </div>
              </div>
              {/* line */}
              <div
                className={`hidden lg:block w-6 h-1
                ${
                  stepOne?.question_one?.answer_title === "1996 - 2010" ||
                  stepOne?.question_one?.answer_title === "1985 - 1995" ||
                  stepOne?.question_one?.answer_title === "1971 - 1984"
                    ? "bg-[#01a2da]"
                    : "bg-[#DFE1E2]"
                }
                `}
              ></div>

              <div
                className="py-2 w-5/12 lg:w-fit"
                onClick={() => {
                  storeStepValues(
                    stepOne,
                    "question_one",
                    "1971 - 1984",
                    12,
                    setStepOne
                  );
                  setSelectedQuestion(2);
                  smoothScroll("content2");
                }}
              >
                <div
                  className={`border rounded-2xl px-4 py-2 w-full hover:bg-[#01a2da] text-[#3A484D] hover:text-[#fff] cursor-pointer
                                                ${
                                                  activeOptionHandler(
                                                    stepOne,
                                                    "question_one",
                                                    "1971 - 1984"
                                                  ) === true
                                                    ? "border-[#01a2da] bg-[#01a2da] text-[#fff]"
                                                    : "text-[#3A484D]"
                                                }
                `}
                >
                  <p className="text-[15px] text-center my-1">1971 - 1984</p>
                </div>
              </div>
              {/* line */}
              <div
                className={`hidden lg:block w-6 h-1
                ${
                  // stepOne?.question_one?.answer_title === "1918 - 1945" ||
                  stepOne?.question_one?.answer_title === "1985 - 1995" ||
                  // stepOne?.question_one?.answer_title === "1946 - 1970" ||
                  stepOne?.question_one?.answer_title === "1996 - 2010"
                    ? "bg-[#01a2da]"
                    : "bg-[#DFE1E2]"
                }
                `}
              ></div>
              <div
                className="py-2 w-5/12 lg:w-fit"
                onClick={() => {
                  storeStepValues(
                    stepOne,
                    "question_one",
                    "1985 - 1995",
                    13,
                    setStepOne
                  );
                  setSelectedQuestion(2);
                  smoothScroll("content2");
                }}
              >
                <div
                  className={`border rounded-2xl px-4 py-2 w-full hover:bg-[#01a2da] text-[#3A484D] hover:text-[#fff] cursor-pointer
                ${
                  activeOptionHandler(
                    stepOne,
                    "question_one",
                    "1985 - 1995"
                  ) === true
                    ? "border-[#01a2da] bg-[#01a2da] text-[#fff]"
                    : "text-[#3A484D]"
                }
                `}
                >
                  <p className="text-[15px] text-center my-1">1985 - 1995</p>
                </div>
              </div>
              {/* line */}
              <div
                className={`hidden lg:block w-6 h-1
                ${
                  // stepOne?.question_one?.answer_title === "1946 - 1970" ||
                  stepOne?.question_one?.answer_title === "1996 - 2010"
                    ? "bg-[#01a2da]"
                    : "bg-[#DFE1E2]"
                }
                `}
              ></div>

              <div
                className="py-2 w-5/12 lg:w-fit"
                onClick={() => {
                  storeStepValues(
                    stepOne,
                    "question_one",
                    "1996 - 2010",
                    14,
                    setStepOne
                  );
                  setSelectedQuestion(2);
                  smoothScroll("content2");
                }}
              >
                <div
                  className={`border rounded-2xl px-4 py-2 w-full hover:bg-[#01a2da] text-[#3A484D] hover:text-[#fff] cursor-pointer
                ${
                  activeOptionHandler(
                    stepOne,
                    "question_one",
                    "1996 - 2010"
                  ) === true
                    ? "border-[#01a2da] bg-[#01a2da] text-[#fff]"
                    : "text-[#3A484D]"
                }
                `}
                >
                  <p className="text-[15px] text-center my-1">1996 - 2010</p>
                </div>
              </div>
            </div>
            <div className="pb-6 px-1 lg:px-6">
              <label className="radio-container mt-4">
                <span
                  className="text-[18px] font-normal text-[#4a4a4b] pt-[3px]"
                  onClick={() => {
                    storeStepValues(
                      stepOne,
                      "question_one",
                      state?.language?.stepOne?.questionOne?.answerOne,
                      123,
                      setStepOne
                    );
                    setSelectedQuestion(2);
                    smoothScroll("content2");
                  }}
                >
                  {state?.language?.stepOne?.questionOne?.answerOne}
                </span>
                <input
                  type="checkbox"
                  className=""
                  name="radio"
                  checked={
                    stepOne?.question_one?.answer_title === "Je ne sais pas"
                      ? true
                      : false
                  }
                  onChange={() => {
                    storeStepValues(
                      stepOne,
                      "question_one",
                      state?.language?.stepOne?.questionOne?.answerOne,
                      123,
                      setStepOne
                    );
                    setSelectedQuestion(2);
                    smoothScroll("content2");

                  }}
                />
                <span className="checkmark checkbox-input mt-1"></span>
              </label>
            </div>
          </div>
          {/* 2nd card */}
          <div id="content2" className="w-full py-4">
            <div
              className={`w-full pl-4 pr-7 border-2 rounded-2xl mb-6  ${
                selectedQuestion === 2 ? "border-[#00A3DA]" : "border-[#E1F4FB]"
              }`}
            >
              {/* header */}
              <div className="w-full pt-4 flex items-center justify-between">
                <div className="flex items-center w-11/12">
                  <img src={HouseTree} alt="" className="h-12" />
                  <h5 className="text-[#3A484D] font-semibold px-6 text-[17px]">
                    {state?.language?.stepOne?.questionTwo?.title} 
                    {/* --- {`{"question_id": ${stepOne.question_two.question_id}, "answer_id": ${stepOne.question_two.answer_id}}`} */}
                  </h5>
                </div>
                <div
                  className="cursor-pointer w-1/12 flex justify-end"
                  onClick={() => {
                    setModalHandler(
                      {
                        content: "stepOne.questionTwo",
                        modal: "step1",
                      },
                      dispatch
                    );
                  }}
                >
                  <img src={InfoIcon} alt="" className="h-5" />
                </div>
              </div>
              {/* content */}
              <div className="flex flex-col lg:flex-row justify-between py-8 lg:px-6">
                <div
                  className="py-2 w-full lg:w-3/12"
                  onClick={() => {
                    storeStepValues(
                      stepOne,
                      "question_two",
                      state?.language?.stepOne?.questionTwo?.answerOne,
                      15,
                      setStepOne
                    );
                    setSelectedQuestion(3);
                    smoothScroll("content3");
                  }}
                >
                  <div
                    className={`border rounded-2xl h-full px-4 py-6 w-full hover:bg-[#01a2da] text-[#3A484D] hover:text-[#fff] cursor-pointer
                ${
                  activeOptionHandler(
                    stepOne,
                    "question_two",
                    state?.language?.stepOne?.questionTwo?.answerOne
                  ) === true
                    ? "bg-[#01a2da] text-[#fff]"
                    : "text-[#3A484D]"
                }
                `}
                  >
                    <p className="text-[15px] text-center">
                      {state?.language?.stepOne?.questionTwo?.answerOne}
                    </p>
                  </div>
                </div>
                <div
                  className="py-2 w-full lg:w-4/12"
                  onClick={() => {
                    storeStepValues(
                      stepOne,
                      "question_two",
                      state?.language?.stepOne?.questionTwo?.answerTwo,
                      16,
                      setStepOne
                    );
                    setSelectedQuestion(3);
                    smoothScroll("content3");
                  }}
                >
                  <div
                    className={`border rounded-2xl px-4 py-6 w-full h-full hover:bg-[#01a2da] text-[#3A484D] hover:text-[#fff] cursor-pointer
                                ${
                                  activeOptionHandler(
                                    stepOne,
                                    "question_two",
                                    state?.language?.stepOne?.questionTwo
                                      ?.answerTwo
                                  ) === true
                                    ? "bg-[#01a2da] text-[#fff]"
                                    : "text-[#3A484D]"
                                }
                `}
                  >
                    <p className="text-[15px] text-center my-1">
                      {state?.language?.stepOne?.questionTwo?.answerTwo}
                    </p>
                  </div>
                </div>
                <div
                  className="py-2 w-full lg:w-3/12"
                  onClick={() => {
                    storeStepValues(
                      stepOne,
                      "question_two",
                      state?.language?.stepOne?.questionTwo?.answerThree,
                      17,
                      setStepOne
                    );
                    setSelectedQuestion(3);
                    smoothScroll("content3");
                  }}
                >
                  <div
                    className={`border rounded-2xl px-4 py-6 w-full h-full hover:bg-[#01a2da] text-[#3A484D] hover:text-[#fff] cursor-pointer
                ${
                  activeOptionHandler(
                    stepOne,
                    "question_two",
                    state?.language?.stepOne?.questionTwo?.answerThree
                  ) === true
                    ? "bg-[#01a2da] text-[#fff]"
                    : "text-[#3A484D]"
                }
                `}
                  >
                    <p className="text-[15px] text-center my-1">
                      {state?.language?.stepOne?.questionTwo?.answerThree}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          {/* 3rd card */}
          <div id="content3" className="w-full py-4">
            <div
              className={`w-full pl-4 pt-4 pr-7 border-2 rounded-2xl mb-6 ${
                selectedQuestion === 3 ? "border-[#00A3DA]" : "border-[#E1F4FB]"
              }`}
            >
              {/* header */}
              <div className="w-full pt-4 flex items-center justify-between">
                <div className="flex items-center w-11/12">
                  <img src={TwoHouse} alt="" className="h-12" />
                  <h5 className="text-[#3A484D] font-semibold px-6 text-[17px]">
                    {state?.language?.stepOne?.questionThree?.title}  
                    {/* --- {`{"question_id": ${stepOne.question_three.question_id}, "answer_id": ${stepOne.question_three.answer_id}}`} */}
                  </h5>
                </div>
                <div
                  className="cursor-pointer w-1/12 flex justify-end"
                  onClick={() => {
                    setModalHandler(
                      {
                        content: "stepOne.questionThree",
                        modal: "step1",
                      },
                      dispatch
                    );
                  }}
                >
                  <img src={InfoIcon} alt="" className="h-5" />
                </div>
              </div>
              {/* content */}
              <div className="flex flex-col lg:flex-row justify-between py-8 lg:px-6">
                <div
                  className="w-full py-2 lg:px-2 lg:w-4/12"
                  onClick={() => {
                    storeStepValues(
                      stepOne,
                      "question_three",
                      state?.language?.stepOne?.questionThree?.answerOne,
                      18,
                      setStepOne
                    );
                    setSelectedQuestion(4);
                    smoothScroll("content4");
                  }}
                >
                  <div
                    className={`steps-box-hover border rounded-2xl px-4 py-8 w-full h-full lg:w-12/12 hover:bg-[#01a2da] text-[#3A484D] hover:text-[#fff] cursor-pointer
                  ${
                    activeOptionHandler(
                      stepOne,
                      "question_three",
                      state?.language?.stepOne?.questionThree?.answerOne
                    ) === true
                      ? "bg-[#01a2da] text-[#fff]"
                      : "text-[#3A484D]"
                  }
                `}
                  >
                    <div className="flex flex-row lg:flex-col justify-center items-center">
                      <div className="w-4/12 lg:w-full flex justify-center">
                        {window.screen.availWidth < 1024 ? 
                          <img src={Adjoining1} alt="" className="h-16" />
                          :
                          activeOptionHandler(
                            stepOne,
                            "question_three",
                            state?.language?.stepOne?.questionThree?.answerOne
                          ) === false ? (
                            <>
                              <img
                                src={Adjoining1}
                                alt=""
                                className="h-16 hide-on-hover"
                              />
                              <img
                                src={Adjoining1ON}
                                alt=""
                                className="h-16 show-on-hover"
                              />
                            </>
                          ) : (
                            <img src={Adjoining1ON} alt="" className="h-16" />
                          )
                          
                        }
                      </div>
                      <div className="w-8/12 lg:w-full">
                        <p className="text-[15px] lg:text-center font-normal py-3 px-8 lg:px-0">
                          {state?.language?.stepOne?.questionThree?.answerOne}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="w-full py-2 lg:px-2 lg:w-4/12"
                  onClick={() => {
                    storeStepValues(
                      stepOne,
                      "question_three",
                      state?.language?.stepOne?.questionThree?.answerTwo,
                      19,
                      setStepOne
                    );
                    setSelectedQuestion(4);
                    smoothScroll("content4");
                  }}
                >
                  <div
                    className={`steps-box-hover border rounded-2xl px-4 py-8 w-full h-full lg:w-12/12 hover:bg-[#01a2da] text-[#3A484D] hover:text-[#fff] cursor-pointer
                  ${
                    activeOptionHandler(
                      stepOne,
                      "question_three",
                      state?.language?.stepOne?.questionThree?.answerTwo
                    ) === true
                      ? "bg-[#01a2da] text-[#fff]"
                      : "text-[#3A484D]"
                  } 
                `}
                  >
                    <div className="flex flex-row lg:flex-col justify-center items-center">
                      <div className="w-4/12 lg:w-full flex justify-center">
                      {window.screen.availWidth < 1024 ? 
                          <img
                          src={Adjoining2}
                          alt=""
                          className="h-16"
                        />:
                        activeOptionHandler(
                          stepOne,
                          "question_three",
                          state?.language?.stepOne?.questionThree?.answerTwo
                        ) === false ? (
                          <>
                            <img
                              src={Adjoining2}
                              alt=""
                              className="h-16 hide-on-hover"
                            />
                            <img
                              src={Adjoining2On}
                              alt=""
                              className="h-16 show-on-hover"
                            />
                          </>
                        ) : (
                          <img src={Adjoining2On} alt="" className="h-16" />
                        )}
                      </div>
                      <div className="w-8/12 lg:w-full">
                        <p className="text-[15px] lg:text-center font-normal py-3 px-8 lg:px-0">
                          {state?.language?.stepOne?.questionThree?.answerTwo}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="w-full py-2 lg:px-2 lg:w-4/12"
                  onClick={() => {
                    storeStepValues(
                      stepOne,
                      "question_three",
                      state?.language?.stepOne?.questionThree?.answerThree,
                      20,
                      setStepOne
                    );
                    setSelectedQuestion(4);
                    smoothScroll("content4");
                  }}
                >
                  <div
                    className={`steps-box-hover border rounded-2xl px-4 py-8 w-full h-full lg:w-12/12 hover:bg-[#01a2da] text-[#3A484D] hover:text-[#fff] cursor-pointer
                ${
                  activeOptionHandler(
                    stepOne,
                    "question_three",
                    state?.language?.stepOne?.questionThree?.answerThree
                  ) === true
                    ? "bg-[#01a2da] text-[#fff]"
                    : "text-[#3A484D]"
                }
                `}
                  >
                    <div className="flex flex-row lg:flex-col justify-center items-center">
                      <div className="w-4/12 lg:w-full flex justify-center">
                      {window.screen.availWidth < 1024 ?
                              <img
                              src={Adjoining3}
                              alt=""
                              className="h-16"
                            />:
                        activeOptionHandler(
                          stepOne,
                          "question_three",
                          state?.language?.stepOne?.questionThree?.answerThree
                        ) === false ? (
                          <>
                            <img
                              src={Adjoining3}
                              alt=""
                              className="h-16 hide-on-hover"
                            />
                            <img
                              src={AdjoiningOn3}
                              alt=""
                              className="h-16 show-on-hover"
                            />
                          </>
                        ) : (
                          <img src={AdjoiningOn3} alt="" className="h-16 " />
                        )}
                      </div>
                      <div className="w-8/12 lg:w-full">
                        <p className="text-[15px] lg:text-center font-normal py-3 px-8 lg:px-0">
                          {state?.language?.stepOne?.questionThree?.answerThree}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* 4th card */}
          <div id="content4" className="w-full py-4">
            <div
              className={`w-full pt-4 pl-4 pr-7 border-2 rounded-2xl mb-6 ${
                selectedQuestion === 4 ? "border-[#00A3DA]" : "border-[#E1F4FB]"
              }`}
            >
              {/* header */}
              <div className="w-full flex items-center justify-between">
                <div className="flex items-center w-11/12">
                  <img src={HouseLevel} alt="" className="h-12" />
                  <h5 className="text-[#3A484D] font-semibold px-6 text-[17px]">
                    {state?.language?.stepOne?.questionFour?.title} 
                    {/* --- {`{"question_id": ${stepOne.question_four.question_id}, "answer_id": ${stepOne.question_four.answer_id}}`} */}
                  </h5>
                </div>
                <div
                  className="cursor-pointer w-1/12 flex justify-end"
                  onClick={() => {
                    setModalHandler(
                      {
                        content: "stepOne.questionFour",
                        modal: "step1",
                      },
                      dispatch
                    );
                  }}
                >
                  <img src={InfoIcon} alt="" className="h-5" />
                </div>
              </div>
              {/* content */}
              <div className="flex flex-col lg:flex-row justify-between py-8 lg:px-6">
                <div
                  className="w-full py-2 lg:px-2 lg:w-4/12"
                  onClick={() => {
                    storeStepValues(
                      stepOne,
                      "question_four",
                      state?.language?.stepOne?.questionFour?.answerOne,
                      21,
                      setStepOne
                    );
                    setSelectedQuestion(4);
                    smoothScroll("content5");
                  }}
                >
                  <div
                    className={`steps-box-hover border rounded-2xl px-4 py-8 w-full h-full lg:w-12/12 hover:bg-[#01a2da] text-[#3A484D] hover:text-[#fff] cursor-pointer
                ${
                  activeOptionHandler(
                    stepOne,
                    "question_four",
                    state?.language?.stepOne?.questionFour?.answerOne
                  ) === true
                    ? "bg-[#01a2da] text-[#fff]"
                    : "text-[#3A484D]"
                }
                `}
                  >
                    <div className="flex flex-row lg:flex-col justify-center items-center">
                      <div className="w-4/12 lg:w-full flex justify-center">
                      {window.screen.availWidth < 1024 ?
                         <img
                         src={Charge1}
                         alt=""
                         className="h-16"
                       />:
                        activeOptionHandler(
                          stepOne,
                          "question_four",
                          state?.language?.stepOne?.questionFour?.answerOne
                        ) === false ? (
                          <>
                            <img
                              src={Charge1}
                              alt=""
                              className="h-16 hide-on-hover"
                            />
                            <img
                              src={ChargeOn1}
                              alt=""
                              className="h-16 show-on-hover"
                            />
                          </>
                        ) : (
                          <img src={ChargeOn1} alt="" className="h-16" />
                        )}
                      </div>
                      <div className="w-8/12 lg:w-full">
                        <p className="text-[15px] lg:text-center font-normal py-3 px-8 lg:px-0">
                          {state?.language?.stepOne?.questionFour?.answerOne}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="w-full py-2 lg:px-2 lg:w-4/12"
                  onClick={() => {
                    storeStepValues(
                      stepOne,
                      "question_four",
                      state?.language?.stepOne?.questionFour?.answerTwo,
                      22,
                      setStepOne
                    );
                    setSelectedQuestion(4);
                    smoothScroll("content5");
                  }}
                >
                  <div
                    className={`steps-box-hover border rounded-2xl px-4 py-8 w-full h-full lg:w-12/12 hover:bg-[#01a2da] text-[#3A484D] hover:text-[#fff] cursor-pointer
                                ${
                                  activeOptionHandler(
                                    stepOne,
                                    "question_four",
                                    state?.language?.stepOne?.questionFour
                                      ?.answerTwo
                                  ) === true
                                    ? "bg-[#01a2da] text-[#fff]"
                                    : "text-[#3A484D]"
                                }
                `}
                  >
                    <div className="flex flex-row lg:flex-col justify-center items-center">
                      <div className="w-4/12 lg:w-full flex justify-center">
                      {window.screen.availWidth < 1024 ?
                          <img
                          src={Charge2}
                          alt=""
                          className="h-16"
                        />:
                        activeOptionHandler(
                          stepOne,
                          "question_four",
                          state?.language?.stepOne?.questionFour?.answerTwo
                        ) === false ? (
                          <>
                            <img
                              src={Charge2}
                              alt=""
                              className="h-16 hide-on-hover"
                            />
                            <img
                              src={ChargeOn2}
                              alt=""
                              className="h-16 show-on-hover"
                            />
                          </>
                        ) : (
                          <img src={ChargeOn2} alt="" className="h-16" />
                        )}
                      </div>
                      <div className="w-8/12 lg:w-full">
                        <p className="text-[15px] lg:text-center font-normal py-3 px-8 lg:px-0">
                          {state?.language?.stepOne?.questionFour?.answerTwo}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="w-full py-2 lg:px-2 lg:w-4/12"
                  onClick={() => {
                    storeStepValues(
                      stepOne,
                      "question_four",
                      state?.language?.stepOne?.questionFour?.answerThree,
                      23,
                      setStepOne
                    );
                    setSelectedQuestion(4);
                    smoothScroll("content5");
                  }}
                >
                  <div
                    className={`steps-box-hover border rounded-2xl px-4 py-8 w-full lg:w-12/12 hover:bg-[#01a2da] text-[#3A484D] hover:text-[#fff] cursor-pointer
                ${
                  activeOptionHandler(
                    stepOne,
                    "question_four",
                    state?.language?.stepOne?.questionFour?.answerThree
                  ) === true
                    ? "bg-[#01a2da] text-[#fff]"
                    : "text-[#3A484D]"
                }
                `}
                  >
                    <div className="flex flex-row lg:flex-col justify-center items-center">
                      <div className="w-4/12 lg:w-full flex justify-center">
                      {window.screen.availWidth < 1024 ?
                           <img
                           src={Charge3}
                           alt=""
                           className="h-16"
                         />:
                        activeOptionHandler(
                          stepOne,
                          "question_four",
                          state?.language?.stepOne?.questionFour?.answerThree
                        ) === false ? (
                          <>
                            <img
                              src={Charge3}
                              alt=""
                              className="h-16 hide-on-hover"
                            />
                            <img
                              src={ChargeOn3}
                              alt=""
                              className="h-16 show-on-hover"
                            />
                          </>
                        ) : (
                          <img src={ChargeOn3} alt="" className="h-16 " />
                        )}
                      </div>
                      <div className="w-8/12 lg:w-full">
                        <p className="text-[15px] lg:text-center font-normal py-3 px-8 lg:px-0">
                          {state?.language?.stepOne?.questionFour?.answerThree}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* right side */}
        <div
          className={`w-full lg:w-3/12 flex flex-col justify-between items-center lg:pb-6 ${formsRightQuestionsHeight()}`}
        >
          <div className="hidden lg:block">
            {/*active arrow */}
            {formOptions?.map((item, index) => (
              <div className="flex" key={item?.id}>
                <div>
                  <img
                    src={
                      item?.selected === "true" ? BlueArrow : TransparentArrow
                    }
                    className="h-8"
                    alt=""
                  />
                  {/* line between image */}
                  {item?.id !== 4 && (
                    <div className="flex justify-center">
                      <div
                        className={`w-[2px] h-6 
                         ${
                           item?.selected === "true" &&
                           formOptions[index + 1].selected === "true"
                             ? "bg-[#01a2da]"
                             : "bg-[#E7F6FD]"
                         }
                      `}
                      ></div>
                    </div>
                  )}
                </div>
                {
                  <div className="px-4 pt-1">
                    <p className="text-[#3A484D]">{item?.name}</p>
                  </div>
                }
              </div>
            ))}
          </div>
          {/* buttons */}
          <div
            className="flex flex-row lg:flex-col justify-between w-full lg:w-8/12 px-2"
            id="content5"
          >
            <div className="lg:w-12/12 lg:py-4">
              <button
                className="bg-white hover:bg-[#eee] border w-full rounded-full py-[10px] text-[#3A484D] text-[15px] px-10"
                onClick={() => navigate("/")}
              >
                {state?.language?.stepButton?.previous}
              </button>
            </div>
            <div className="lg:w-12/12">
              <button
                className="bg-[#01a2da] hover:bg-[#0590c1] w-full rounded-full py-[10px] text-white text-[15px] pr-10 pl-5"
                onClick={() => submitHandler()}
              >
                {state?.language?.stepButton?.next}
                <span className="absolute ml-[8px] lg:ml-[20px] mt-[6.5px]">
                  <img src={NextArrow} className="h-3" alt="" />
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step1;
