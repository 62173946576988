import React, { FC } from "react";
import Header from "./header";
import Footer from "./footer";
import { useLocation } from "react-router-dom";

interface LayoutInterface {
  children: JSX.Element;
}

const Layout: FC<LayoutInterface> = ({ children }) => {
  const location = useLocation();
  return (
    <div className="flex flex-col justify-between h-screen">
      <div>
        {location?.pathname !== "/pdf" && <Header />}
        {children}
      </div>
      {location?.pathname !== "/pdf" && <Footer />}
    </div>
  );
};

export default Layout;
