import React, { FC, useContext, useState, useEffect } from "react";
import { GlobalContext } from "../../context/globalContext/globalContext";
import { setModalHandler } from "../../context/actions/modal";
import { storeStepValues } from "../../utils/forms";
import { setStepFormValues } from "../../context/actions/formsAction";
import { storelocalStorageData } from "../../utils/forms";
import { smoothScroll } from "../../utils/smoothScroll";
// section 1 icons
import HouseQ1 from "../../assets/icons/questionsForm/step5/heater__Q1_.svg";
import Imag1Off from "../../assets/icons/questionsForm/step5/off_Q1_10_.svg";
import Imag1ON from "../../assets/icons/questionsForm/step5/on_Q1_10_.svg";
import Imag2OFF from "../../assets/icons/questionsForm/step5/off_Q1_9_.svg";
import Imag2ON from "../../assets/icons/questionsForm/step5/on_Q1_9_.svg";
import Imag3OFF from "../../assets/icons/questionsForm/step5/off_Q1_12_.svg";
import Imag3ON from "../../assets/icons/questionsForm/step5/on_Q1_12_.svg";
import Imag4OFF from "../../assets/icons/questionsForm/step5/off_Q1_13_.svg";
import Imag4ON from "../../assets/icons/questionsForm/step5/on_Q1_13_.svg";
import Imag5OFF from "../../assets/icons/questionsForm/step5/off_Q1_11_.svg";
import Imag5ON from "../../assets/icons/questionsForm/step5/off_Q1_11_.svg";
import Imag6OFF from "../../assets/icons/questionsForm/step5/central-heating-other-off.svg";
import Imag6ON from "../../assets/icons/questionsForm/step5/central-heating-other-on.svg";
// section 2 icons
import HouseQ2 from "../../assets/icons/questionsForm/step5/stove.svg";
// Sections icons of this page
import BlueArrow from "../../assets/icons/questionsForm/step1/arrow-down-blue.svg";
import TransparentArrow from "../../assets/icons/questionsForm/step1/arrow-down-transparency.svg";
import NextArrow from "../../assets/icons/questionsForm/step1/arrow-next.svg";
import InfoIcon from "../../assets/icons/information-button.svg";
import { ScreenSize } from "../../utils/windowDimension";

interface Step5Interface {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  setShowAnimation: React.Dispatch<React.SetStateAction<string>>;
  selectedQuestion?: number;
  setSelectedQuestion?: any;
  formsRightQuestionsHeight?: any;
  formsLeftQuestionsHeight?: any;
  formHeightHandler?: any;
}

// FormOptionTypes types
type FormOptionTypes = {
  id: number;
  name: string;
  selected: string;
};

const Step5: FC<Step5Interface> = ({
  setStep,
  setShowAnimation,
  setSelectedQuestion,
  selectedQuestion,
  formsRightQuestionsHeight,
  formsLeftQuestionsHeight,
  formHeightHandler,
}) => {
  // global state
  const { state, dispatch }: any = useContext(GlobalContext);
  // list of step 1 options
  const formOptions: FormOptionTypes[] = [
    {
      id: 1,
      name:
        state?.language?.language === "fr" ? "Chauffage principal" : "Haupt-Heizung",
      selected:
        selectedQuestion === 1 || selectedQuestion === 2 ? "true" : "false",
    },
    {
      id: 2,
      name:
        state?.language?.language === "fr" ? "Chauffage d’appoint" : "Zusatzheizung",
      selected: selectedQuestion === 2 ? "true" : "false",
    },
  ];
  const [toggle, setToggle] = useState(false);
  const [systemOptionId, setSystemOptionId] = useState(0);
  // variable for screen zoom
  const browserZoomLevel: any =
    (window.outerWidth / window.document.documentElement.clientWidth) * 100;
  const [step5, setStep5]: any = useState({
    question_one: {
      answer_id: 0,
      answer_title: "",
      question_id: 29,
      question_title: "Quel est le système de chauffage principal ? ",
    },
    question_three: {
      answer_id: 0,
      answer_title: "Chauffage central ",
      question_id: 31,
      question_title: "Quel type de chauffage secondaire avez-vous?",
    },
    question_two: {
      answer_id: 0,
      answer_title: "",
      question_id: 30,
      question_title: "Avez-vous un système de chauffage secondaire? ",
    },
  });
  const screenSize: any = ScreenSize();
  let systemOptions = [
    {
      id: 1,
      name: state?.language?.stepFive?.questionOne?.answer1,
      subOptions: [
        {
          id: 88,
          name: state?.language?.stepFive?.questionOne?.answer2,
        },
        {
          id: 88,
          name: state?.language?.stepFive?.questionOne?.answer3,
        },
        {
          id: 89,
          name: state?.language?.stepFive?.questionOne?.answer4,
        },
        {
          id: 90,
          name: state?.language?.stepFive?.questionOne?.answer5,
        }
      ],
    },
    {
      id: 2,
      name: state?.language?.stepFive?.questionOne?.answer9,
      subOptions: [
        {
          id: 91,
          name: state?.language?.stepFive?.questionOne?.answer10
        },
        {
          id: 92,
          name: state?.language?.stepFive?.questionOne?.answer11,
        },
      ],
    },
    {
      id: 3,
      name: state?.language?.stepFive?.questionOne?.answer6,
      subOptions: [
        {
          id: 93,
          name: state?.language?.stepFive?.questionOne?.answer7,
        },
        {
          id: 94,
          name: state?.language?.stepFive?.questionOne?.answer8,
        },
      ],
    },
    {
      id: 6,
      name: state?.language?.stepFive?.questionOne?.answer13,
      subOptions: [
        {
          id: 95,
          name: state?.language?.stepFive?.questionOne?.answer14,
        },
        {
          id: 96,
          name: state?.language?.stepFive?.questionOne?.answer15,
        },
      ],
    },
  ];
  // useEffect to check if data exsit in localStorage to show selectedData
  useEffect(() => {
    let localStorageData: any = localStorage.getItem("formData");
    let formData = JSON.parse(localStorageData);
    if (formData?.stepfive) {
      // to store in local and Global states
      setStep5(formData?.stepfive);
      setToggle(formData?.stepfiveToggle);
      setSystemOptionId(formData?.stepfiveSystemId);
      setStepFormValues(formData?.stepfive, dispatch);
    }
    // window.scrollTo(0, 0);
  }, [dispatch]);
  useEffect(() =>{
    // window.scrollTo(0,0)
    document.getElementById('root')?.scrollIntoView();
  },[]);
  // handler for section2 options for adding and removing array data
  const arrayValuesHandler = (value: any) => {
    // if exist remove
    if (step5?.question_three?.answer_id?.find((item: any) => item === value)) {
      let remainingData = step5?.question_three?.answer_id?.filter(
        (item: any) => item !== value
      );
      let sortedArray = remainingData?.sort((a: any, b: any) => a - b);
      storeStepValues(step5, "question_three", "", sortedArray, setStep5);
    }
    // if not exist add
    else {
      let duplicate = { ...step5?.question_three };
      let newArray = [...duplicate?.answer_id, value];
      let sortedArray = newArray?.sort((a: any, b: any) => a - b);
      storeStepValues(step5, "question_three", "", sortedArray, setStep5);
    }
  };
  // submit handler of step5
  const submitHandler = () => {
    if (
      step5?.question_one?.answer_id === 0 ||
      (!toggle && step5?.question_two?.answer_id === 0) ||
      (toggle && step5?.question_three?.answer_id === 0)
    ) {
      setModalHandler(
        {
          content: "",
          modal: "formError",
        },
        dispatch
      );
    } else {
      setShowAnimation("animate-left6");
      let stepfive = { stepfive: step5, formStep: 6 };
      storelocalStorageData({
        stepfive: step5,
        stepfiveToggle: toggle,
        stepfiveSystemId: systemOptionId,
        formStep: 6,
      });
      // to store in local and global states
      setStepFormValues(stepfive, dispatch);
      setStep(6);
      setSelectedQuestion(1);
    }
  };

  return (
    <div className="container mx-auto py-8 step1-container px-4">
      <div
        className={`flex flex-col lg:flex-row lg:justify-between items-center lg:items-start lg:flex-row ${
          screenSize?.width < 1500 && formHeightHandler("main")
        }`}
      >
        {/* left side */}
        <div
          className={`w-12/12 lg:w-[70%] lg:overflow-y-auto ${
            parseInt(browserZoomLevel) === 100 ||
            parseInt(browserZoomLevel) === 90 ||
            parseInt(browserZoomLevel) === 80
              ? formHeightHandler("left")
              : formsLeftQuestionsHeight("lg:h-[70vh]")
          }`}
        >
          {/* 1st card */}
          <div
            className={`w-full py-4 pl-4 pr-7 border-2 rounded-2xl mb-6 ${
              selectedQuestion === 1 ? "border-[#00A3DA]" : "border-[#E1F4FB]"
            } `}
          >
            {/* header */}
            <div className="w-full flex items-center justify-between">
              <div className="flex items-center w-11/12">
                <img src={HouseQ1} alt="" className="h-12" />
                <h5 className="text-[#3A484D] font-semibold px-6 text-[17px]">
                  {state?.language?.stepFive.questionOne?.title} 
                  {/* --- {`{"question_id": ${step5.question_one.question_id}, "answer_id": ${step5.question_one.answer_id}}`} */}
                </h5>
              </div>
              <div
                className="cursor-pointer w-1/12 flex justify-end"
                onClick={() => {
                  setModalHandler(
                    {
                      content: "stepFive.questionOne",
                      modal: "step4",
                    },
                    dispatch
                  );
                }}
              >
                <img src={InfoIcon} alt="" className="h-5" />
              </div>
            </div>
            {/* contetn */}
            <div className="flex flex-wrap justify-between pt-8 lg:px-6">
              {/* 1 */}
              <div className="w-full py-2 lg:px-2 lg:w-6/12">
                <div
                  className={`steps-box-hover border rounded-2xl px-4 py-4 w-full h-full lg:w-12/12 hover:bg-[#01a2da] text-[#3A484D] hover:text-[#fff] cursor-pointer
                  ${
                    step5?.question_one?.answer_id === 87 &&
                    "bg-[#01a2da] text-[#fff]"
                  }
                `}
                  onClick={() => {
                    storeStepValues(step5, "question_one", "", 87, setStep5);
                    setSelectedQuestion(2);
                    setSystemOptionId(0);
                    smoothScroll("content2");
                  }}
                >
                  <div className="flex flex-row justify-start items-center">
                    <div className="w-4/12 flex justify-center">
                    {window.screen.availWidth < 1024 ? 
                          <img src={Imag1Off} alt="" className="h-16" />
                          :
                      step5?.question_one?.answer_id === 87 ? (
                        <img src={Imag1ON} alt="" className="h-16" />
                      ) : (
                        <>
                          <img
                            src={Imag1Off}
                            alt=""
                            className="h-16 hide-on-hover"
                          />
                          <img
                            src={Imag1ON}
                            alt=""
                            className="h-16 show-on-hover"
                          />
                        </>
                      )}
                    </div>
                    <div className="w-8/12 lg:w-full">
                      <p className="text-[14px] font-normal py-3 px-2">
                        {state?.language?.stepFive?.questionOne?.answer16}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* 2 */}
              <div className="w-full py-2 lg:px-2 lg:w-6/12">
                <div
                  className={`steps-box-hover border rounded-2xl px-4 py-4 w-full h-full lg:w-12/12 hover:bg-[#01a2da] text-[#3A484D] hover:text-[#fff] cursor-pointer
                  ${systemOptionId === 1 && "bg-[#01a2da] text-white"}
                  `}
                  onClick={() => {
                    storeStepValues(step5, "question_one", "", 0, setStep5);
                    setSelectedQuestion(1);
                    setSystemOptionId(1);
                    smoothScroll("content2");
                  }}
                >
                  <div className="flex flex-row justify-start items-center">
                    <div className="w-4/12 flex justify-center">
                    {window.screen.availWidth < 1024 ? 
                          <img src={Imag2OFF} alt="" className="h-16" />
                          :
                      systemOptionId === 1 ? (
                        <img src={Imag2ON} alt="" className="h-16" />
                      ) : (
                        <>
                          {" "}
                          <img
                            src={Imag2OFF}
                            alt=""
                            className="h-16 hide-on-hover"
                          />
                          <img
                            src={Imag2ON}
                            alt=""
                            className="h-16 show-on-hover"
                          />
                        </>
                      )}
                    </div>
                    <div className="w-8/12 lg:w-full">
                      <p className="text-[14px] font-normal py-3 px-2">
                        {state?.language?.stepFive?.questionOne?.answer1}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* 3 */}
              <div className="w-full py-2 lg:px-2 lg:w-6/12">
                <div
                  className={`steps-box-hover border rounded-2xl px-4 py-4 w-full h-full lg:w-12/12 hover:bg-[#01a2da] text-[#3A484D] hover:text-[#fff] cursor-pointer
                  ${systemOptionId === 2 && "bg-[#01a2da] text-white"}
                  `}
                  onClick={() => {
                    storeStepValues(step5, "question_one", "", 0, setStep5);
                    setSelectedQuestion(1);
                    setSystemOptionId(2);
                    smoothScroll("content2");
                  }}
                >
                  <div className="flex flex-row justify-start items-center">
                    <div className="w-4/12 flex justify-center">
                    {window.screen.availWidth < 1024 ? 
                          <img src={Imag3OFF} alt="" className="h-16" />
                          :
                      systemOptionId === 2 ? (
                        <img src={Imag3ON} alt="" className="h-16" />
                      ) : (
                        <>
                          <img
                            src={Imag3OFF}
                            alt=""
                            className="h-16 hide-on-hover"
                          />
                          <img
                            src={Imag3ON}
                            alt=""
                            className="h-16 show-on-hover"
                          />
                        </>
                      )}
                    </div>
                    <div className="w-8/12 lg:w-full">
                      <p className="text-[14px] font-normal py-3 px-2">
                        {state?.language?.stepFive?.questionOne?.answer9}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* 4 */}
              <div className="w-full py-2 lg:px-2 lg:w-6/12">
                <div
                  className={`steps-box-hover border rounded-2xl px-4 py-4 w-full h-full lg:w-12/12 hover:bg-[#01a2da] text-[#3A484D] hover:text-[#fff] cursor-pointer
                   ${systemOptionId === 3 && "bg-[#01a2da] text-white"}
                  `}
                  onClick={() => {
                    storeStepValues(step5, "question_one", "", 0, setStep5);
                    setSelectedQuestion(1);
                    setSystemOptionId(3);
                    smoothScroll("content2");
                  }}
                >
                  <div className="flex flex-row justify-start items-center">
                    <div className="w-4/12 flex justify-center">
                    {window.screen.availWidth < 1024 ? 
                          <img src={Imag4OFF} alt="" className="h-16" />
                          :
                      systemOptionId === 3 ? (
                        <img src={Imag4ON} alt="" className="h-16" />
                      ) : (
                        <>
                          <img
                            src={Imag4OFF}
                            alt=""
                            className="h-16 hide-on-hover"
                          />
                          <img
                            src={Imag4ON}
                            alt=""
                            className="h-16 show-on-hover"
                          />
                        </>
                      )}
                    </div>
                    <div className="w-8/12 lg:w-full">
                      <p className="text-[14px] font-normal py-3 px-2">
                        {state?.language?.stepFive?.questionOne?.answer6}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* 5 */}
              <div className="w-full py-2 lg:px-2 lg:w-6/12">
                <div
                  className={`steps-box-hover border rounded-2xl px-4 py-4 w-full h-full lg:w-12/12 hover:bg-[#01a2da] text-[#3A484D] hover:text-[#fff] cursor-pointer                  
                  ${
                    step5?.question_one?.answer_id === 97 &&
                    "bg-[#01a2da] text-[#fff]"
                  }
                `}
                  onClick={() => {
                    storeStepValues(step5, "question_one", "", 97, setStep5);
                    setSelectedQuestion(2);
                    smoothScroll("content2");
                    setSystemOptionId(0);
                  }}
                >
                  <div className="flex flex-row justify-start items-center">
                    <div className="w-4/12 flex justify-center">
                    {window.screen.availWidth < 1024 ? 
                          <img src={Imag5OFF} alt="" className="h-16" />
                          :
                      step5?.question_one?.answer_id === 97 ? (
                        <img src={Imag5ON} alt="" className="h-16" />
                      ) : (
                        <>
                          <img
                            src={Imag5OFF}
                            alt=""
                            className="h-16 hide-on-hover"
                          />
                          <img
                            src={Imag5ON}
                            alt=""
                            className="h-16 show-on-hover"
                          />
                        </>
                      )}
                    </div>
                    <div className="w-8/12 lg:w-full">
                      <p className="text-[14px] font-normal py-3 px-2">
                        {state?.language?.stepFive?.questionOne?.answer12}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* 6th */}
              <div className="w-full py-2 lg:px-2 lg:w-6/12">
                <div
                  className={`steps-box-hover border rounded-2xl px-4 py-4 w-full h-full lg:w-12/12 hover:bg-[#01a2da] text-[#3A484D] hover:text-[#fff] cursor-pointer
                   ${systemOptionId === 6 && "bg-[#01a2da] text-white"}
                  `}
                  onClick={() => {
                    storeStepValues(step5, "question_one", "", 0, setStep5);
                    setSelectedQuestion(1);
                    setSystemOptionId(6);
                    smoothScroll("content1");
                  }}
                >
                  <div className="flex flex-row justify-start items-center h-full">
                    <div className="w-4/12 flex justify-center">
                    {window.screen.availWidth < 1024 ? 
                          <img src={Imag6OFF} alt="" className="h-16" />
                          :
                      systemOptionId === 3 ? (
                        <img src={Imag4ON} alt="" className="h-16" />
                      ) : (
                        <>
                          <img
                            src={Imag6OFF}
                            alt=""
                            className="h-16 hide-on-hover"
                          />
                          <img
                            src={Imag6ON}
                            alt=""
                            className="h-16 show-on-hover"
                          />
                        </>
                      )}
                    </div>
                    <div className="w-8/12 lg:w-full h-full flex items-center">
                      <p className="text-[14px] font-normal py-3 px-2 flex items-center">
                        {state?.language?.stepFive?.questionOne?.answer13}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* checkboxes */}
            {systemOptionId !== 0 && (
              <div id="content1">
                <div className="px-2 lg:px-3 pt-3">
                  <h5 className="text-[#3A484D] font-semibold lg:px-6 text-[17px]">
                    {state?.language?.stepFive.questionOne?.title}
                  </h5>
                </div>
                <div className="lg:flex justify-start flex-wrap">
                  {systemOptions?.map(
                    (option: any, index: number) =>
                      option.id === systemOptionId &&
                      option?.subOptions?.map((subOption: any) => (
                        <div
                          className="lg:px-8 px-2 pb-4 lg:w-5/12"
                          key={index}
                        >
                          <label className="radio-container mt-4">
                            <span className="text-[14px] font-normal text-[#3A484D] pt-[4px]">
                              {subOption?.name}
                            </span>
                            <input
                              type="radio"
                              className=""
                              name="radio8"
                              checked={
                                subOption.id ===
                                  step5?.question_one?.answer_id &&
                                subOption.name ===
                                  step5?.question_one?.answer_title
                              }
                              onChange={() => {
                                storeStepValues(
                                  step5,
                                  "question_one",
                                  subOption?.name,
                                  subOption?.id,
                                  setStep5
                                );
                                setSelectedQuestion(2);
                                smoothScroll("content2");
                              }}
                            />
                            <span className="checkmark checkbox-input mt-1"></span>
                          </label>
                        </div>
                      ))
                  )}
                </div>
              </div>
            )}
          </div>
          {/* 2nd card */}
          <div
            className={`w-full py-4 pl-4 pr-7 border-2 rounded-2xl mb-6 ${
              selectedQuestion === 2 ? "border-[#00A3DA]" : "border-[#E1F4FB]"
            } `}
            id="content2"
          >
            {/* header */}
            <div className="w-full flex items-center justify-between">
              <div className="flex items-center w-11/12">
                <img src={HouseQ2} alt="" className="h-12" />
                <h5 className="text-[#4A4A4A] font-[600] px-6 text-[17px]">
                  {state?.language?.stepFive.questionTwo?.title} 
                  {/* --- {`{"question_id": ${step5.question_two.question_id}, "answer_id": ${step5.question_two.answer_id}}`} */}
                </h5>
              </div>
              <div
                className="cursor-pointer w-1/12 flex justify-end"
                onClick={() => {
                  setModalHandler(
                    {
                      content: "stepFive.questionTwo",
                      modal: "step5",
                    },
                    dispatch
                  );
                }}
              >
                <img src={InfoIcon} alt="" className="h-5" />
              </div>
            </div>
            <div>
              {/* 1st section */}
              <div>
                {/* content */}
                <div className="flex flex-row lg:flex-row justify-center pt-6 lg:px-6">
                  <div className="py-3 lg:px-3 px-1 w-full lg:w-4/12">
                    <div
                      className={`border rounded-2xl h-full px-3 py-6 w-full hover:bg-[#01a2da] text-[#3A484D] hover:text-[#fff] cursor-pointer flex items-center justify-center
                    ${toggle && "bg-[#01a2da] text-[#fff]"}
                    `}
                      onClick={() => {
                        setToggle(!toggle);
                        storeStepValues(
                          step5,
                          "question_three",
                          "",
                          [],
                          setStep5
                        );
                        storeStepValues(step5, "question_two", "", 0, setStep5);
                      }}
                    >
                      <p className="text-[16px] text-center">
                        {state?.language?.stepFive?.questionTwo?.answerOne}
                      </p>
                    </div>
                  </div>
                  <div className="py-3 lg:px-3 px-1 w-full lg:w-4/12">
                    <div
                      className={`border rounded-2xl px-3 py-6 w-full h-full hover:bg-[#01a2da] text-[#3A484D] hover:text-[#fff] cursor-pointer flex items-center justify-center
                      ${
                        step5?.question_two?.answer_id === 99 &&
                        "bg-[#01a2da] text-white"
                      }
                    `}
                      onClick={() => {
                        storeStepValues(
                          step5,
                          "question_two",
                          "",
                          99,
                          setStep5
                        );
                        storeStepValues(
                          step5,
                          "question_three",
                          "",
                          0,
                          setStep5
                        );
                        setToggle(false);
                        smoothScroll("content3");
                      }}
                    >
                      <p className="text-[16px] text-center my-1">
                        {state?.language?.stepFive?.questionTwo?.answerTwo}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/*  */}
              {toggle && (
                <>
                  <div className="lg:px-3 pt-3">
                    <h5 className="text-[#3A484D] font-semibold lg:px-6 text-[17px]">
                      {state?.language?.stepFive.questionTwoSubOne?.title}  
                      {/* --- {`{"question_id": ${step5.question_three.question_id}, "answer_id": ${step5.question_three.answer_id}}`} */}
                    </h5>
                  </div>
                  <div className="lg:px-8">
                    <label className="radio-container mt-4">
                      <span className="text-[14px] font-normal text-[#3A484D] pt-[4px]">
                        {state?.language?.stepFive.questionTwoSubOne?.answerOne}
                      </span>
                      <input
                        type="checkbox"
                        className=""
                        name="radio"
                        checked={
                          step5?.question_three?.answer_id.find(
                            (item: any) => item === 100
                          )
                            ? true
                            : false
                        }
                        onChange={() => {
                          arrayValuesHandler(100);
                          smoothScroll("content3");
                        }}
                      />
                      <span className="checkmark checkbox-input mt-1"></span>
                    </label>
                    <label className="radio-container mt-4">
                      <span className="text-[14px] font-normal text-[#3A484D] pt-[4px]">
                        {state?.language?.stepFive.questionTwoSubOne?.answerTwo}
                      </span>
                      <input
                        type="checkbox"
                        className=""
                        name="radio"
                        checked={
                          step5?.question_three?.answer_id.find(
                            (item: any) => item === 101
                          )
                            ? true
                            : false
                        }
                        onChange={() => {
                          arrayValuesHandler(101);
                          smoothScroll("content3");
                        }}
                      />
                      <span className="checkmark checkbox-input mt-1"></span>
                    </label>
                    <label className="radio-container mt-4">
                      <span className="text-[14px] font-normal text-[#3A484D] pt-[4px]">
                        {
                          state?.language?.stepFive.questionTwoSubOne
                            ?.answerThree
                        }
                      </span>
                      <input
                        type="checkbox"
                        className=""
                        name="radio"
                        checked={
                          step5?.question_three?.answer_id.find(
                            (item: any) => item === 102
                          )
                            ? true
                            : false
                        }
                        onChange={() => {
                          arrayValuesHandler(102);
                          smoothScroll("content3");
                        }}
                      />
                      <span className="checkmark checkbox-input mt-1"></span>
                    </label>
                    <label className="radio-container mt-4">
                      <span className="text-[14px] font-normal text-[#3A484D] pt-[4px]">
                        {
                          state?.language?.stepFive.questionTwoSubOne
                            ?.answerFour
                        }
                      </span>
                      <input
                        type="checkbox"
                        className=""
                        name="radio"
                        checked={
                          step5?.question_three?.answer_id.find(
                            (item: any) => item === 103
                          )
                            ? true
                            : false
                        }
                        onChange={() => {
                          arrayValuesHandler(103);
                          smoothScroll("content3");
                        }}
                      />
                      <span className="checkmark checkbox-input mt-1"></span>
                    </label>
                    <label className="radio-container mt-4">
                      <span className="text-[14px] font-normal text-[#3A484D] pt-[4px]">
                        {
                          state?.language?.stepFive.questionTwoSubOne
                            ?.answerFive
                        }
                      </span>
                      <input
                        type="checkbox"
                        className=""
                        name="radio"
                        checked={
                          step5?.question_three?.answer_id.find(
                            (item: any) => item === 104
                          )
                            ? true
                            : false
                        }
                        onChange={() => {
                          arrayValuesHandler(104);
                          smoothScroll("content3");
                        }}
                      />
                      <span className="checkmark checkbox-input mt-1"></span>
                    </label>
                    <label className="radio-container mt-4">
                      <span className="text-[14px] font-normal text-[#3A484D] pt-[4px]">
                        {state?.language?.stepFive.questionTwoSubOne?.answerSix}
                      </span>
                      <input
                        type="checkbox"
                        className=""
                        name="radio"
                        checked={
                          step5?.question_three?.answer_id.find(
                            (item: any) => item === 127
                          )
                            ? true
                            : false
                        }
                        onChange={() => {
                          arrayValuesHandler(127);
                          smoothScroll("content3");
                        }}
                      />
                      <span className="checkmark checkbox-input mt-1"></span>
                    </label>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        {/* right side */}
        <div
          className={`w-full lg:w-3/12 flex flex-col justify-between items-center lg:pb-6 ${formsRightQuestionsHeight()}`}
          id="content3"
        >
          <div className="hidden lg:block">
            {/*active arrow */}
            {formOptions?.map((item, index) => (
              <div className="flex" key={item?.id}>
                <div>
                  <img
                    src={
                      item?.selected === "true" ? BlueArrow : TransparentArrow
                    }
                    className="h-8"
                    alt=""
                  />
                  {/* line between image */}
                  {item?.id !== 2 && (
                    <div className="flex justify-center">
                      <div
                        className={`w-[2px] h-6 
                         ${
                           item?.selected === "true" &&
                           formOptions[index + 1].selected === "true"
                             ? "bg-[#01a2da]"
                             : "bg-[#E7F6FD]"
                         }
                      `}
                      ></div>
                    </div>
                  )}
                </div>
                {
                  <div className="px-4 pt-1">
                    <p className="text-[#3A484D]">{item?.name}</p>
                  </div>
                }
              </div>
            ))}
          </div>
          {/* buttons */}
          <div className="flex flex-row lg:flex-col justify-between w-full lg:w-8/12 px-2">
            <div className="lg:w-12/12 lg:py-4">
              <button
                className="bg-white hover:bg-[#eee] border w-full rounded-full py-[10px] text-[#3A484D] text-[15px] px-10"
                onClick={() => {
                  setStep(4);
                  setShowAnimation("animate-right4");
                }}
              >
                {state?.language?.stepButton?.previous}
              </button>
            </div>
            <div className="lg:w-12/12">
              <button
                className="bg-[#01a2da] hover:bg-[#0590c1] w-full rounded-full py-[10px] text-white text-[15px] pr-10 pl-5"
                onClick={() => submitHandler()}
              >
                {state?.language?.stepButton?.next}
                <span className="absolute ml-[8px] lg:ml-[20px] mt-[6.5px]">
                  <img src={NextArrow} className="h-3" alt="" />
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step5;
