import React, { createContext, useReducer } from "react";
import AppReducer from "../reducer/appReducer";
// language file
import french from "../../languages/fr";

// Initial state
const initialState: any = {
  // visitorData
  visitorData: {},
  // language (default French)
  language: french,
  // state to show modal of form pages and their content
  modal: {
    content: "",
    modal: "",
  },
  // state to store homepage form data
  homePageForm: {
    address: "",
    age: [0, 1, 2, 3, 4],
    button: "",
  },
  // state for 7 steps of form to save data
  stepData: {},
  // all houses types of step 2 in form page
  allHousesTypes: [],
  stepTwoSelectedHouse: {},
  // resultPage data to get all submited data of from
  visitedUserData: {},
};

// Create context
export const GlobalContext = createContext(initialState);

// Provider component
export const GlobalProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);
  return (
    <GlobalContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
