import React, { FC, useContext } from "react";
import { GlobalContext } from "../../context/globalContext/globalContext";
import CrossIcon from "../../assets/icons/close.png";
import { setModalHandler } from "../../context/actions/modal";

interface HouseTypeModallInterface {
  houseModalData?: any;
  houseTypeModalHandler?: any;
  stepTwo?: any;
  setStepTwo?: any;
  selectedHouse?: any;
  housesTypesImagesHandler?: any;
  setHouseModalData?: any;
  showModal?: any;
  setShowModal?: any;
}

const HouseTypeModal: FC<HouseTypeModallInterface> = ({
  houseModalData,
  houseTypeModalHandler,
  stepTwo,
  setStepTwo,
  selectedHouse,
  housesTypesImagesHandler,
  setHouseModalData,
  showModal,
  setShowModal,
}) => {
  // global state
  const { state, dispatch }: any = useContext(GlobalContext);
  return (
    <div
      className={`relative z-50 modal-container ${
        showModal ? "opacity-100" : "opacity-0"
      }`}
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      {/* condition to show modal when it's active */}
      {showModal && (
        <div>
          <div className="fixed inset-0 bg-[#E9F6FD] bg-opacity-75 transition-opacity"></div>
          <div className="fixed z-10 inset-0 overflow-y-auto bg-[#e6f4fb]">
            <div className="flex sm:items-center justify-center lg:min-h-full p-4 text-center sm:p-0">
              <div className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 w-full lg:w-7/12">
                {/* header */}
                {state?.modal?.content !== "/" && (
                  <div
                    className="flex justify-end absolute right-0 m-3 cursor-pointer w-fit"
                    onClick={() => {
                      houseTypeModalHandler(
                        selectedHouse?.houseDetails,
                        "selectedHouseDetail"
                      );
                      setHouseModalData({});
                      setModalHandler(
                        {
                          content: "",
                          modal: "",
                        },
                        dispatch
                      );
                      setShowModal(false);
                    }}
                  >
                    <img src={CrossIcon} alt="cross-icon" className="h-3" />
                  </div>
                )}
                {/* body of home page modal */}
                <div>
                  <div className="flex flex-col items-center justify-center">
                    {/* image */}
                    <div className="w-full lg:w-4/12 flex items-center lg:justify-start border-b border-[#E1F4FB] lg:border-0 p-4 lg:p-5 ">
                      <div>
                        <img
                          src={`${housesTypesImagesHandler(
                            houseModalData?.typology
                          )}`}
                          alt=""
                          className="mt-[-12px] lg:mt-[0px] w-[12rempx] h-[11rem] lg:h-60"
                        />
                      </div>
                      <div className="block lg:hidden pl-4">
                        <h4 className="text-[#3A484D] text-[16px] font-bold">
                          {houseModalData?.title}
                        </h4>
                      </div>
                    </div>
                    {/* content */}
                    <div className="w-full lg:w-8/12 pl-5 pr-2 p-2 lg:p-5">
                      <p className="pt-4 text-[##3A484D] font-normal">
                        {houseModalData?.description}
                      </p>
                    </div>
                    <div className="flex justify-center py-8">
                      <div>
                        <button
                          className="text-[#fff] bg-[#01a2da] hover:bg-[#0590c1] font-semibold text-[18px] text-center rounded-full py-2 px-4 lg:px-14"
                          onClick={() => {
                            setShowModal(false);
                            houseTypeModalHandler(
                              houseModalData,
                              "selectedHouseDetail"
                            );
                            setModalHandler(
                              {
                                content: "",
                                modal: "",
                              },
                              dispatch
                            );
                            setStepTwo(selectedHouse?.houseDetails);
                          }}
                        >
                          {state?.language?.stepTwo?.questionOne?.select}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HouseTypeModal;
