const german = {
  language: "de",
  plusdinfo: "Mehr Informationen",
  headerLanguage:"Meine Sprache auswählen",
  presentationScreen: {
    title: "Willkommen bei Quickscan",
    title1: "Willkommen bei",
    description: `Dieses Instrument hilft Ihnen dabei, Ihr Wohngebäude aus energetischer Sicht zu bewerten! Es informiert Sie über die Renovierungsarbeiten, die durchzuführen sind, um Ihren Komfort zu erhöhen, Ihren Verbrauch zu verringern und somit aktiven Umweltschutz zu betreiben.`,
    Button: {
      title1: "DIE ENERGIEEFFIZIENZ ",
      title2: "MEINES WOHNGEBÄUDES",
    },
    informationText:
      "Quickscan dient lediglich der Information und ersetzt das GEE-Zertifikat nicht.",
  },
  HomePageForm: {
    title:
      "Bewerten Sie mit wenigen Klicks die Energieeffizienz Ihres Wohngebäudes und erhalten Sie kostenlos Empfehlungen für ihre Verbesserung-",
    form: {
      title: "Postleitzahl des Gebäudes",
      checkBoxGroup: {
        title: "Ihr Status",
        radioBtn1Text: "Ich bin EigentümerIn",
        radioBtn2Text: "Ich bin MieterIn",
        radioBtn3Text: "Sonstiges",
      },
      dropDown: {
        title: `Ihre Altersgruppe`,
      },
      submitBtn: {
        title: "DEN TEST STARTEN",
      },
      select1829:"18-29 Jahre",
      select3039:"30-39 Jahre",
      select4049:"40-49 Jahre",
      select5059:"50-59 Jahre",
      select60:"60+ Jahre",
    },
    age: "Jahre",
    bottomMessage:
      "Die Anwendung bezieht sich gegenwärtig ausschließlich auf bestehende Einfamilienhäuser, die vor 2010 gebaut wurden. Für Wohnungen ist sie nicht anwendbar.",
    bottomMessageTwo:
      "Gemäß der im Bereich des Datenschutz geltenden Regelung werden die übermittelten personenbezogenen Daten vom Öffentlichen Dienst der Wallonie nur für folgende Zwecke verwendet: Statistik und Weiterentwicklung des Instruments „Quickscan“. Diese Daten werden weder verkauft noch für Marketingzwecke verwendet. Diese Daten werden ohne Ihre vorherige Zustimmung nicht an Dritte weitergegeben. Diese Daten werden so lange aufbewahrt, wie dies für die Nutzung und die Weiterentwicklung des Instruments erforderlich ist. In bestimmten Fällen können Sie Ihre Daten korrigieren, ihre Übermittlung beantragen oder ihrer Verarbeitung widersprechen. Wenden Sie sich dafür an den ÖDW.",
    objectives: {
      title: "Auf dem Weg zum A-Label",
      description: `Die Wallonie will Ihnen helfen! Ein großer Teil der Wohngebäude in unserer Region ist sehr alt und erfüllt die Normen im Bereich der Energieeffizienz nicht. Die Wallonie hat beschlossen, aktiv zu werden und sich ein ambitioniertes Renovierungsziel zu setzen: bis 2050 sollen die wallonischen Wohngebäude durchschnittlich das GEE-Label A erreichen. Diese großangelegte Aktion ist Teil der europäischen Ziele für die Verringerung der Treibhausgasemissionen.`,
    },
  },
  KeyPoint: {
    title: "Verbesserung Ihres Wohngebäudes in 3 Etappen",
    subHeader: {
      header1: {
        title: "DÄMMEN UND BELÜFTEN",
        description: `Die DÄMMUNG aller Wände in Kontakt mit dem Außenbereich und die korrekte BELÜFTUNG sind Prioritäten für die nachhaltige Verbesserung der Wohnung, die Erhöhung des Komforts und für Verringerung des Heizbedarfs`,
      },
      header2: {
        title: "HEIZEN",
        description:
          "Das HEIZEN mit einer Heizung mit hohem Wirkungsgrad in Ihrem Wohngebäude verbessert Ihren Komfort und verringert Ihren Verbrauch.",
      },
      header3: {
        title: `ENERGIE ERZEUGEN `,
        description:
          "Die PRODUKTION Ihrer Energie mit Installation eines oder mehrerer Systeme mit erneuerbaren Energien führt Sie zu „NULL EMISSIONEN“",
      },
    },
    bottomMessageParagraph: {
      title: `Was ist die GEE?`,
      description: `Die GEE-Regelung ist die Regelung zur Gebäudeenergieeffizienz. Beim Bauen oder Renovieren sind Anforderungen zur Energieeffizienz einzuhalten.<br /><br />
      Diese Effizienz wird durch ein PEB-Ausweisen bescheinigt. Dieses steht das Kennzeichnungsblatt für Ihr Gebäude dar. Es gibt die Energieklasse an, welche von A++ (energieneutral) bis G (sehr energieintensiv) reicht und gibt Auskunft über seinen Energieverbrauch. Es handelt sich um ein Dokument, mit dem die Energieeffizienz der Gebäude auf dem Markt objektiv verglichen werden kann. Diese wird durch verschiedene Indikatoren ausgedrückt. Außerdem werden Maßnahmen zur Verbesserung vorgeschlagen.<br /><br />
      Ein PEB-Ausweisen wird verpflichtend, sobald eine Wohnung (Haus oder Apartment) verkauft oder vermietet werden soll. Es muss von einer zugelassenen Zertifizierungsstelle erstellt werden.<br /><br />
      Achtung: Quickscan bewertet die Energieeffizienz Ihres Gebäudes nach der vereinfachten Methode, stellt aber kein PEB-Ausweisen aus!
      `,
    },
  },
  OfficialObjectives: {
    bodyoneheader: {
      title: "Auf dem Weg zum Label A im Jahr 2050!",
      description: `Wallonien will Ihnen helfen! Ein großer Teil der Wohnungen in unserer Region ist sehr alt und entspricht nicht den Normen für energetische Leistung. Wallonien hat beschlossen zu handeln und hat ein sehr ehrgeiziges Renovierungsziel festgesetzt: bis 2050 sollen die wallonischen Wohnungen durchschnittlich Label A erreichen. Diese sehr umfangreiche Aktion gehört zu den europäischen Zielen, die Emissionen von Treibhausgas zu verringern.`,
    },
    footerboxheader: {
      site: {
        title: "Website",
        site1: "Datenschutz/DSGVO",
        site2: `Rechtliche Hinweise`,
        site3: "Ein Problem melden",
      },
      utiles: {
        title: `Nützliche Links`,
        array: ["Walloreno", "ÖDW Energie", "PMP asbl", "Be-Reel!"],
      },
      developer: {
        title: "Entwickelt von",
        bottom: "Studie durchgeführt von",
      },
      bottomMessage: {
        title: `Projekt entwickelt von der VoG pmp auf Initiative der Wallonischen Region mithilfe des Finanzierungsinstruments LIFE der Europäischen Kommission – LIFE IP CA 2016 BE-REEL!`,
      },
    },
  },
  stepButton: {
    previous: "ZURÜCK",
    next: "WEITER",
  },
  modal: {
    error: `<p> Entschuldigung. Etwas ist schief gelaufen. Bitte versuchen Sie es später erneut. </p> <p> Wenn der Fehler weiterhin besteht, wenden Sie sich an <a href="mailto:celine.renard@spw.wallonie.be"> die SPW </a> </p>`,
    form: "Stellen Sie sicher, dass Sie jede Frage beantworten.",
    email: "Sie haben eine E-Mail bekommen",
    button: "Weiter",
    calculation: "Die Berechnung Ihres Ergebnisses läuft ...",
    calculationSub:
      "Wir leiten Sie weiter, wenn die Berechnung durchgeführt wurde",
  },
  infobox: {
    button: `Einverstanden`,
  },
  switch: {
    yes: "Ja",
    no: "Nein",
    idk: "Ich weiß nicht",
  },
  stepOne: {
    stepTitle: "Zu welchem Typ gehört Ihr Wohngebäude?",
    questionOne: {
      title: "Wann wurde Ihr Wohngebäude erbaut?",
      infoboxHeader: "Wann wurde Ihr Wohngebäude erbaut?",
      infoboxDescription: `Das Baujahr ist ein entscheidender Faktor für die Bestimmung der Energiebilanz Ihres Wohngebäudes. Die Anforderungen zur Energieeffizienz wurden im Laufe der Zeit weiterentwickelt und Quickscan basiert auf diesen Anforderungen, um die Standardwerte festzulegen, vor allem, wenn nicht bekannt ist, ob Dämmungen vorhanden sind. Wenn Sie das Baujahr gar nicht kennen, wählen Sie das Feld „Ich weiß nicht“ aus. Dann werden Ihnen verschiedene mögliche Typologien vorgeschlagen und Sie können jene auswählen, die Ihnen als am besten zu Ihrem Wohngebäude passend erscheint.<br /><br />
      Gut zu wissen: Ihre Gemeindeverwaltung kann Ihnen auf jeden Fall dabei helfen, das Baujahr zu bestimmen.`,
      answerOne: `Ich weiß nicht`,
      answerlistOne:"Vor 1918",
    },
    questionTwo: {
      title: "Mein Wohngebäude liegt ...",
      infoboxHeader: "Mein Wohngebäude liegt ...",
      infoboxDescription: `Der Standort Ihres Wohngebäudes ermöglicht es uns, Ihnen die am besten geeignete Typologie vorzuschlagen. Wenn Sie der Meinung sind, dass Ihr Wohngebäude nicht wirklich in der Stadt, aber auch nicht auf dem Land liegt, wählen Sie „Sonstiges“ aus.`,
      answerOne: `In der Stadt`,
      answerTwo: `Auf dem Land oder in einem Dorf`,
      answerThree: `Sonstiges`,
    },
    questionThree: {
      title: "Mein Wohngebäude ist ...",
      infoboxHeader: "Mein Wohngebäude ist ...",
      infoboxDescription: `Die Frage ermöglicht die Festlegung der Anzahl der Fassaden mit Wärmeverlust der Wohnung, der Fassaden in Kontakt mit dem Außenbereich oder von nicht beheizten Räumen. Wenn das Wohngebäude 4 voll eigenständige Fassaden hat oder wenn eine von ihnen an eine Garage, eine Scheune oder einen Stall angeschlossen ist, wählen Sie bitte die Antwort „Freistehend (4 Fassaden)“ aus. Wenn das Wohngebäude eine einzige Fassade besitzt, die an ein anderes Wohngebäude oder ein Geschäft angeschlossen ist, wählen Sie die Antwort „Halb-Reihenhaus (3 Fassaden)“. Wenn das Wohngebäude zwei Fassaden besitzt, die an Wohngebäude oder Geschäfte angeschlossen sind, wählen Sie die Antwort „Halb-Reihenhaus (2 Fassaden)“.`,
      answerOne: `Reihenhaus (2 Fassaden)`,
      answerTwo: `Halb-Reihenhaus (3 Fassaden)`,
      answerThree: `Freistehend (4 Fassaden)`,
    },
    questionFour: {
      title: "Anzahl der bewohnbaren Ebenen (außer nicht ausgebautem Dachboden und Keller)?",
      infoboxHeader: "Anzahl der bewohnbaren Ebenen (außer nicht ausgebautem Dachboden und Keller)? ",
      infoboxDescription: "Die Anzahl der bewohnbaren Ebenen umfasst alle Etagen Ihres Wohngebäudes (einschließlich Erdgeschoss), welche Sie regelmäßig bewohnen und welche direkt oder indirekt beheizt werden. Der Keller und ein nicht ausgebauter Dachboden sind daher nicht zu berücksichtigen. Wenn die Dachboden-Ebene ausgebaut ist, muss sie jedoch berücksichtigt werden.",
      answerOne: `1 Ebene (Erdgeschoss)`,
      answerTwo: `2 Ebenen (Erdgeschoss + 1)`,
      answerThree: `3 Ebenen oder mehr (Erdgeschoss + 2 oder mehr)`,
    },
  },
  stepTwo: {
    stepTitle: "Bestätigung des Typs Ihres Wohngebäudes",
    questionOne: {
      title: "Entspricht Ihr Wohngebäude der Beschreibung des für Sie ausgewählten Typs?",
      infoboxHeader: "Typologie",
      infoboxDescription: `Die Zuordnung Ihres Wohngebäudes zu einer Referenz-Typologie ermöglicht das Erreichen zweier Ziele:
       <br />
            <ol>
                <li>1. Bestimmung der für die Art der Wohnung typischen Verlustflächen, um die Berechnung der Energiebilanz zu ermöglichen</li>
                <li>2. Erfahren der wahrscheinlichen Zusammensetzungen der Wände je nach Zeitraum des Baujahrs</li>
            </ol>
            Ihr Wohngebäude muss der Referenz-Typologie nicht ganz genau entsprechen.  Das wichtigste ist, dass es dem richtigen Bauzeitraum zugeordnet ist und dass die korrekte Anzahl an Fassaden angegeben ist.`,
      select: `Auswählen`,
      backProposal: "Zurück zum Anfangsvorschlag",
      proposal: "Sonstige Optionen",
      allTypologies: "Alle Typologien",
      allTypologiesLong: "Alle vorhandenen Typologien sehen",
      defaultButtonText: "Ich habe den für mich passenden Haus-Typ nicht gefunden",
    },
  },
  stepThree: {
    stepTitle: "Dämmung",
    modal: "Welches ist der Typ der Haupt-Verglasung? ",
    questionOne: {
      title: "Welches ist der Typ der Haupt-Verglasung? ",
      infoboxHeader: "Welches ist der Typ der Haupt-Verglasung? ",
      infoboxDescription: `Wenn Sie über verschiedene Typen von Verglasung verfügen, wählen Sie jenen Typ, auf den die größte Fläche entfällt.
      Betrachten Sie eine Doppelverglasung von vor 2000 als „alte“ Doppelverglasung. Ist sie neuer, betrachten Sie sie als neuere Doppelverglasung.`,
      answerOne: `Einfachverglasung`,
      answerTwo: `Doppelverglasung`,
      answerThree: `Dreifachverglasung`,
      answerFour: `Relativ alte Doppelverglasung (vor 2000)`,
      answerFive: `Neuere Doppelverglasung (nach 2000)`,
    },
    questionTwo: {
      title: `Gibt es in der Dicke des Daches oder der Decke eine Dämmung? `,
      infoboxHeader: `SGibt es in der Dicke des Daches oder der Decke eine Dämmung? `,
      infoboxDescription: `Die meisten Dämmungen bieten ein ähnliches Effizienzniveau: Steinwolle, Glaswolle, expandiertes Polystyrol, extrudiertes Polystyrol, Zellulose, Holz- oder Hanfwolle.<br /><br />
      Ihre Dicke ermöglicht die Feststellung des Dämmniveaus der Wand.<br /><br />
      Polyurethan-Platten bieten als Dämmung jedoch ein etwas höheres Effizienzniveau. Wenn Sie solche angebracht haben, können Sie für diese eine etwas höhere Dicke berücksichtigen, als jene, die angebracht wurde. Beispiel: Wenn Sie eine Polyurethan-Platte mit 12 cm angebracht haben, können Sie „zwischen 14 und 18 cm“ für diese Wand auswählen.<br /><br />
      Der Erhalt von Prämien der Wallonischen Region erfordert die Einhaltung der Mindestnormen zur Effizienz. Auf diese Effizienz bezieht sich Quickscan, wenn Sie diese Art von Prämien erhalten haben.`,
      answerOne: `Ja`,
      answerTwo: `Nein`,
      answerThree: `Ich weiß nicht`,
      answerFour: `Certaines mais pas toutes`,
      answerFive: `Nur in bestimmten Teilen des Daches/der Decken`,

    },
    questionTwoSubOne: {
      title: `Welche Dicke hat die Dämmung ungefähr?`,
      answerFive: `Ich weiß nicht`,
    },
    questionTwoSubTwo: {
      title: `Wissen Sie, ob für diese Arbeiten Prämien für Dämmung beantragt wurden?`,
      answerOne: `Ja, ich habe Prämien der Wallonischen Region und/oder Steuerermäßigungen erhalten`,
      answerTwo: `Nein, ich habe niemals Prämien und/oder Steuerermäßigungen erhalten`,
    },
    questionTwoSubThree: {
      text: `Mit Quickscan ist keine exakte Simulation eines Wohngebäudes mit teilweise gedämmten Wänden oder mit verschiedenen Dicken von Dämmungen möglich. Um der Realität möglichst nahe zu kommen, wählen Sie die Antwort, die für den Großteil der Wände zutrifft. Wenn Sie mehr Genauigkeit wünschen, müssen Sie ein Wohnungsaudit durchführen lassen.`,
    },
    questionThree: {
      title: `Gibt es eine Dämmung in der Dicke der Mauern? `,
      infoboxHeader: `Gibt es eine Dämmung in der Dicke der Mauern? `,
      infoboxDescription: `Die meisten Dämmungen bieten ein ähnliches Effizienzniveau: Steinwolle, Glaswolle, expandiertes Polystyrol, extrudiertes Polystyrol, Zellulose, Holz- oder Hanfwolle.<br /><br />
      Ihre Dicke ermöglicht die Feststellung des Dämmniveaus der Wand.<br /><br />
      Polyurethan-Platten bieten als Dämmung jedoch ein etwas höheres Effizienzniveau. Wenn Sie solche angebracht haben, können Sie für diese eine etwas höhere Dicke berücksichtigen, als jene, die angebracht wurde. Beispiel: Wenn Sie eine Polyurethan-Platte mit 8 cm angebracht haben, können Sie „10 cm und mehr“ für diese Wand auswählen.<br /><br />
      Der Erhalt von Prämien der Wallonischen Region erforderte die Einhaltung der Mindestnormen zur Effizienz. Auf diese Effizienz beziehen wir uns, wenn Sie diese Art von Prämien erhalten haben.`,
      answerOne: `Ja`,
      answerTwo: `Nein`,
      answerThree: `Ich weiß nicht `,
      answerFour: `Nur in bestimmten Außenmauern`,
      answerFive: `Nur in bestimmten Außenmauern`,

    },
    questionThreeSubOne: {
      title: `Welche Dicke hat die Dämmung ungefähr?`,
      answerFour: `Ich weiß nicht `,
    },
    questionThreeSubTwo: {
      title: `Wissen Sie, ob für diese Arbeiten Prämien für Dämmung beantragt wurden?`,
      answerOne: `Ja, ich habe Prämien und/oder Steuerermäßigungen erhalten`,
      answerTwo: `Nein`,
      answerThree: `Ich weiß nicht`,
    },
    questionThreeSubThree: {
      title: `Handelt es sich um eine Dämmung …`,
      answerOne: `Innenseite der Mauer`,
      answerTwo: `An der Innenseite der Mauer (bei hohlen Mauern)`,
      answerThree: `An der Außenseite der Mauer`,
    },
    questionThreeSubFour: {
      text: `Mit Quickscan ist keine exakte Simulation eines Wohngebäudes mit teilweise gedämmten Wänden oder mit verschiedenen Dicken von Dämmungen möglich. Um der Realität möglichst nahe zu kommen, wählen Sie die Antwort, die für den Großteil der Wände zutrifft. Wenn Sie mehr Genauigkeit wünschen, müssen Sie ein Wohnungsaudit durchführen lassen.`,
    },
    questionFour: {
      title: `Gibt es eine Dämmung im Fußboden? (Berücksichtigen Sie nur die Fußböden am Boden, zum Außenbereich oder zu einem Keller/belüfteten Leerraum)`,
      infoboxHeader: `Gibt es eine Dämmung im Fußboden? (Berücksichtigen Sie nur die Fußböden am Boden, zum Außenbereich oder zu einem Keller/belüfteten Leerraum)`,
      infoboxDescription: `Die meisten Dämmungen bieten ein ähnliches Effizienzniveau: Steinwolle, Glaswolle, expandiertes Polystyrol, extrudiertes Polystyrol, Zellulose, Holz- oder Hanfwolle.<br /><br />
      Ihre Dicke ermöglicht die Feststellung des Dämmniveaus der Wand.<br /><br />
      Polyurethan-Platten bieten als Dämmung jedoch ein etwas höheres Effizienzniveau. Wenn Sie solche angebracht haben, können Sie für diese eine etwas höhere Dicke berücksichtigen, als jene, die angebracht wurde. Beispiel: Wenn Sie eine Polyurethan-Platte mit 8 cm angebracht haben, können Sie „10 cm und mehr“ für diese Wand auswählen.<br /><br />
      Der Erhalt von Prämien der Wallonischen Region erforderte die Einhaltung der Mindestnormen zur Effizienz. Auf diese Effizienz beziehen wir uns, wenn Sie diese Art von Prämien erhalten haben.`,
      answerOne: `Ja`,
      answerTwo: `Nein`,
      answerThree: `Ich weiß nicht`,
      answerFour: `Bestimmte, aber nicht alle`,
      answerFive: `Nur in einigen Fußböden`,
    },
    questionFourSubOne: {
      title: `Welche Dicke hat die Dämmung ungefähr?`,
      answerFour: `Ich weiß nicht`,
    },
    questionFourSubTwo: {
      title: `Wissen Sie, ob für diese Arbeiten Prämien für Dämmung beantragt wurden? `,
      answerOne: `Ja, ich habe Prämien der Wallonischen Region erhalten`,
      answerTwo: `Nein`,
      answerThree: `Ich weiß nicht`,
    },
    questionFourSubThree: {
      title: `Handelt es sich um eine Dämmung …`,
      answerOne: `von oben`,
      answerTwo: `von unten (Decke von Kellern und belüfteten Leerräumen) `,
    },
    questionFourSubFour: {
      title: `Haben Sie eine Fußbodenheizung?`,
      answerOne: `Ja`,
      answerTwo: `Nein`,
    },
    questionFourSubFive: {
      text: `Mit Quickscan ist keine exakte Simulation eines Wohngebäudes mit teilweise gedämmten Wänden oder mit verschiedenen Dicken von Dämmungen möglich. Um der Realität möglichst nahe zu kommen, wählen Sie die Antwort, die für den Großteil der Wände zutrifft. Wenn Sie mehr Genauigkeit wünschen, müssen Sie ein Wohnungsaudit durchführen lassen.`,
    },
  },
  stepFour: {
    stepTitle: `Belüftung und Luftdichtigkeit`,
    questionOne: {
      title: "Gibt es im Wohngebäude ein Belüftungssystem?",
      infoboxHeader: "Gibt es im Wohngebäude ein Belüftungssystem?",
      infoboxDescription: `Seit 1996 sieht eine Norm die Installation eines konformen Belüftungssystems für alle neuen Wohngebäude vor. Teilweise Anforderungen gelten bei Renovierung auch für bestehende Gebäude. In der Praxis verfügen jedoch sehr wenige Wohngebäude über ein komplettes und konformes Belüftungssystem.<br /><br />
      Ziel der Anforderung zur Belüftung ist es, die Qualität der Innenluft in Wohnungen zu garantieren. Dies dient dem Komfort der Bewohner sowie der Begrenzung der Risiken für Kondensation im Zusammenhang mit übermäßiger Feuchtigkeit, welche sich schädlich auf die Hygiene der Wohnung auswirken können (Auftreten von Schimmel).<br /><br />      
      Ein wenig luftdichtes Gebäude braucht vielleicht kein Belüftungssystem, um „hygienisch“ zu sein, führt jedoch aufgrund der ständigen, unfreiwilligen und unkontrollierbaren Lufterneuerung zu erheblichen Energieverlusten. <br /><br />      
      Wenn bei einem Gebäude Renovierungsarbeiten durchgeführt werden, die schrittweise zur Verbesserung seiner Luftdichtigkeit führen, wird die Einrichtung eines Belüftungssystems erforderlich, um Phänomene oberflächlicher (Schimmel – schwarze Flecken) oder innerer (fortschreitende Zerstörung bestimmter Materialien unter Einfluss der Feuchtigkeit) Kondensation zu vermeiden. <br /><br />      
      Diese Arbeiten zur Verbesserung der Luftdichtigkeit der Hülle können mit Dämmarbeiten verbunden werden. Dies erfolgt jedoch nicht zwangsläufig. Zu nennen sind hier insbesondere: Austausch von Rahmen, Anbringung von Membranen zur Dampfsperre, Renovierung von Deckenverkleidungen, Auftragen von spezieller Farbe etc.`,
      answerOne: `Nein, wir öffnen die Fenster bei Bedarf`,
      answerTwo: `Es gibt Luftabzüge im Badezimmer und/oder WC`,
      answerThree: `Es gibt Gitter für Luftzufuhr im oberen Teil bestimmter Verglasungen sowie Luftabzüge im Badezimmer und WC`,
      answerFour: `Es gibt in den meisten Zimmern eine Belüftungseinheit mit doppeltem Fluss und mit Wärmerückgewinnung sowie Öffnungen für Zuluft oder Abluft.`,
    },
    questionTwo: {
      title: `Haben Sie den Eindruck, dass es in Ihrem Wohngebäude Zugluft gibt? `,
      infoboxHeader: `Haben Sie den Eindruck, dass es in Ihrem Wohngebäude Zugluft gibt? `,
      infoboxDescription: `Mangelnde Luftdichtigkeit von Wohngebäuden ist vor allem durch Luftlecks auf Ebene der Rahmen selbst, auf Ebene der Verbindungen von Rahmen/Verglasungen sowie auf Ebene der Verbindungen Rahmen/Mauerwerk gekennzeichnet.<br /><br />
      Häufig ist sie auch auf Ebene der Türen zu beobachten: am unteren Teil der Türen und/oder in deren Umgebung.<br /><br />      
      Das Mauerwerk ist im Allgemeinen mit relativ luftdichten Verkleidungen versehen. Bei getäfelten Verkleidungen kann es jedoch wiederum zu starken Lufteinströmungen kommen.<br /><br />      
      Schließlich können Lufteinströmungen auch von bestimmten speziellen Ausrüstungen stammen: Briefkästen, Katzenklappen, Abzüge, Kamine oder offene Feuerstellen etc.`,
      answerOne: `Ja, ich spüre sehr regelmäßig unangenehme Zugluft in meinem Wohngebäude`,
      answerTwo: `Ja, ich spüre manchmal unangenehme Zugluft in meinem Wohngebäude, vor allem, wenn der Wind stark weht`,
      answerThree: `Nein; ich spüre in jedem Fall keine unangenehmen Auswirkungen`,
      answerFour: `Nein, ich habe für solche Feinarbeiten bei meinem Wohngebäude gesorgt, dass ich keine Zugluft mehr spüre`,
      answerFive: `Ich weiß nicht`,
    },
    questionThree: {
      title: `Gibt es bei Ihrem Wohngebäude Probleme mit Feuchtigkeit oder Kondensation? `,
      infoboxHeader: `Gibt es bei Ihrem Wohngebäude Probleme mit Feuchtigkeit oder Kondensation? `,
      infoboxDescription: `Kondensationsphänomene sind auf die folgende Kombination zurückzuführen:<br />
      <ul>
      <li>- übermäßige Erzeugung von Feuchtigkeit (Kochen, Duschen oder Baden, aber auch einfach Atmung)</li>
      <li>- unzureichende Belüftung</li>
      <li>- „Kältepunkte“ auf der Ebene von Wänden (Dach, Mauern, Boden) des Gebäudes, wenn bestimmte Wände nicht oder schlecht gedämmt sind.</li>
      <ul>
      <br />
      Sie sind durch Phänomene aufsteigender Feuchtigkeit gekennzeichnet, die am Mauerfuß im Erdgeschoss auftreten können oder auch durch Phänomene lokal eintretenden Wassers, die mit Mängeln bei der Dichtigkeit des Daches, der Gesimse etc. in Verbindung gebracht werden können.`,
      answerOne: `Nein`,
      answerTwo: `Ja, im Badezimmer sind manchmal Kondensations- oder Schimmelflecken zu beobachten; die Fliesenfugen werden schwarz`,
      answerThree: `Ja, lokal sind in den Schlafzimmern schwarze Schimmelflecken im Randbereich der Decke entlang der Fensterrahmen oder hinter bestimmten Möbeln zu sehen`,
      answerFour: `Ja, an vielen verschiedenen Stellen, wir sehen Probleme und finden keine Lösung dafür`,
      answerFive: `Nein, aber im Winter sind einige Fenster in verschiedenen Bereichen des Wohngebäudes regelmäßig beschlagen (im Allgemeinen Badezimmer oder Küche, Schlafzimmer am Morgen)`,
    },
  },
  stepFive: {
    stepTitle: `Heizsysteme`,
    questionOne: {
      title: "Welches ist das Haupt-Heizsystem? ",
      infoboxHeader: "Welches ist das Haupt-Heizsystem? ",
      infoboxDescription: `Als Haupt-Heizsystem ist jenes zu betrachten, das die Beheizung des Großteils des Wohngebäudes ermöglicht. Wenn ein Zentralheizungssystem und ein Zusatzheizungssystem (etwa ein Ofen) vorhanden sind, ist hier das Zentralheizungssystem auszuwählen, auch wenn Sie davon ausgehen, den Ofen mehr zu nutzen. Der Ofen kann bei der nächsten Frage ausgewählt werden.`,
      answer1 : "Ofen",
      answer2 : "Gasofen",
      answer3 : "Ölofen",
      answer4 : "Holzofen",
      answer5 : "Pelletofen",
      answer6 : "Brennwertkessel",
      answer7 : "Öl-Brennwertkessel",
      answer8 : "Gas-Brennwertkessel",
      answer9 : "Nicht-Brennwertkessel",
      answer10 : "Nicht-Brennwertkessel mit Öl",
      answer11 : "Nicht-Brennwertkessel mit Gas",
      answer12 : "Wärmepumpe",
      answer13 : "Sonstige Zentralheizung",
      answer14 : "Zentralheizung mit Holz",
      answer15 : "Zentralheizung mit Pellets",
      answer16 : "Elektroheizung (Konvektoren oder Akkumulationsheizung)"

    },
    questionTwo: {
      title: `Haben Sie ein Zusatzheizungssystem?`,
      infoboxHeader: `Haben Sie ein Zusatzheizungssystem?`,
      infoboxDescription: `Welchen Sie hier ein oder mehrere etwaige Zusatzsystem(e), die nur das eine oder andere Zimmer des Wohngebäudes versorgen`,
      answerOne: `Ja`,
      answerTwo: `Nein`,
    },
    questionTwoSubOne: {
      title: `Welchen Typ von Zusatzheizung haben Sie?`,
      answerOne: `Elektroheizung (Konvektoren oder Akkumulationsheizung)`,
      answerTwo: `Gas- oder Ölofen`,
      answerThree: `Holzofen`,
      answerFour: `Pelletofen`,
      answerFive: `Wärmepumpe`,
      answerSix: `Zentralheizung`,
    },
  },
  stepSix: {
    stepTitle: `Systeme zur Warmwassererzeugung`,
    questionOne: {
      title: `Welches System zur Warmwassererzeugung gibt es für das Bad? `,
      infoboxHeader: `Welches System zur Warmwassererzeugung gibt es für das Bad? `,
      infoboxDescription: `Wenn das System für die Warmwassererzeugung für die Küche anders als jenes für das Bad ist, wählen Sie hier das System aus, das für das Badezimmer vorhanden ist – dieses macht den Großteil des Warmwasserverbrauchs eines Wohngebäudes aus.`,
      answerOne: `Elektrischer Boiler`,
      answerTwo: `Durchlauferzeugung mit Gas über den Kessel oder über einen Warmwasserbereiter`,
      answerThree: `Erzeugung über alten Ölheizkessel – mit Speicherbehälter`,
      answerFour: `Erzeugung über Gasheizkessel oder neueren Ölheizkessel – mit Speicherbehälter`,
      answerFive: `Wärmepumpe für Sanitärwarmwasser (thermodynamischer Boiler)`,
    },
  },
  stepSeven: {
    stepTitle: `Stromerzeugung`,
    questionOne: {
      title: `Verfügt das Wohngebäude über Solarmodule für die Warmwassererzeugung?`,
      infoboxHeader: `Verfügt das Wohngebäude über Solarmodule für die Warmwassererzeugung?`,
      infoboxDescription: `Eine thermische Solaranlage wandelt die Sonneneinstrahlung in Wärme um, welche im Allgemeinen einen Pufferspeicher versorgt, der für das Sanitärwarmwasser bestimmt ist. Die Berechnung basiert auf einer „standardmäßigen“ Anlage, d. h. 2 Module mit +/- 2 m² und ein Speicherbehälter mit  +/- 300 l.`,
      answerOne: `Ja`,
      answerTwo: `Nein`,
    },
    questionTwo: {
      title: `Verfügt das Wohngebäude über Photovoltaik-Module für die Stromerzeugung?`,
      infoboxHeader: `Verfügt das Wohngebäude über Photovoltaik-Module für die Stromerzeugung?`,
      infoboxDescription: `Eine Photovoltaik-Anlage wandelt Sonneneinstrahlung in Strom um und zeichnet sich durch ihre Spitzenleistung, ihre Ausrichtung und ihre Neigung aus.<br /><br />
      Wenn Sie die Anzahl der Module und die installierte Spitzenleistung kennen, stützen Sie sich eher auf die Spitzenleistung, da es sich hier um eine genauere Angabe handelt.<br /><br />      
      Achtung: Die Spitzenleistung und die jährliche Produktion sind zwei unterschiedliche Angaben. Unter mehr oder weniger idealen Bedingungen der Sonneneinstrahlung erzeugt eine Anlage in der Wallonischen Region mit einer Spitzenleistung von 2500 Wp etwas mehr als 2000 kWh/Jahr.`,
      answerOne: `Ja`,
      answerTwo: `Nein`,
    },
    questionTwoSubOne: {
      title: `Wie hoch ist die installierte Spitzenleistung?`,
      answerOne: `Bis 3000 Wp`,
      answerTwo: `3000 bis 5000 Wp`,
      answerThree: `5000 bis 7000 Wp`,
      answerFour: `Über 7000 Wp`,
      answerFive: `Ich weiß nicht, aber ich kenne die Anzahl der Module`,
    },
    questionTwoSubTwo: {
      title: `Wie viele Module sind installiert?`,
      answerOne: `Bis 12 Module`,
      answerTwo: `13 bis 20 Module`,
      answerThree: `21 bis 30 Module`,
      answerFour: `Mehr als 30 Module `,
    },
  },
  result: {
    infoboxHeader: "Ihr Ergebnis",
    infoboxDescription: `Die Energieeffizienz Ihres Wohngebäudes wurde mit dem Instrument „Quickscan“ bewertet.<br /><br />
    Diese Bewertung kann von dem eventuell für dieses Wohngebäude verfügbaren GEE-Zertifikat abweichen, da die Berechnungsmethode vereinfacht wurde.<br /><br />
    Um es Ihnen zu ersparen, lästige Berechnungen von Flächen durchführen zu müssen, funktioniert die Bewertung mit dem Instrument „Quickscan“ durch Zuordnung Ihres Wohngebäudes zu einer repräsentativen Referenz-Typologie des Bestands an Immobilien in der Wallonie.<br /><br />
    Das in der Bewertung verwendete Effizienzniveau „Mein Wohngebäude ursprünglich“ entspricht der ursprünglichen Situation der jeweiligen Referenz-Typologie mit Bewertung nach der vorgeschriebenen GEE-Methode.<br /><br />
    Das Effizienzniveau „Mein Wohngebäude heute“ basiert auf den Verlustflächen der Referenz-Typologie, es kommen aber Zusammensetzungen von Wänden und Systemen zur Anwendung, wie diese bei den Etappen 3 bis 7 eingegeben wurden.<br /><br />
    Das Effizienzniveau „Situation nach Arbeiten“ basiert:<br />
    <ul>
    <li>- Für die Etappe 1 auf allen Empfehlungen „DÄMMEN UND BELÜFTEN“, die im Bericht erscheinen, in Kombination mit den in der Wohnung vorhandenen Systemen.<li/>
    <li>- Für die Etappe 2 auf der Kombination der Empfehlungen „DÄMMEN UND BELÜFTEN“ + „HEIZEN“</li>
    <li>- Für die Etappe 3 auf der Kombination der Empfehlungen „DÄMMEN UND BELÜFTEN“ + „HEIZEN“ + „ENERGIE ERZEUGEN“</li>
    </ul>
    <br />
    Anmerkung: Wenn in der bestehenden Situation bereits Fotovoltaik-Solarmodule vorhanden sind, werden diese in Szenarien für die Verbesserung der verschiedenen Etappen berücksichtigt und in Etappe 3 wird keine Anlage ergänzt (Ergebnis Etappe 2 = Ergebnis Etappe 3).`,
    date: "Ergebnis erstellt am",
    pageTitle: "Ihr Ergebnis",
    report: "ERGEBNISSE SEHEN",
    explication: "Erklärungen",
    share: "Teilen",
    optionSection1: "oder",
    epcProgressResult: "Fortschritt",
    epcProgressResultPDF: "Schatting van uw resultaat",
    downloadPDF: "Download des Berichts mit Vorschlägen zur Verbesserung",
    GeneratePDF: "Even geduld a.u.b. Het PDF-bestand wordt gegenereerd",
    shareLink:
      "Sie können diese Seite teilen, indem Sie folgenden Link kopieren",
    email: "Die Ergebnisse per Mail versenden",
    modifyTitle: "Ihre Informationen sind nicht korrekt?",
    modifyContent:
      "Mit diesem Button können Sie zum Anfang des Fragebogens zurückkehren. So können Sie ganz einfach die gewünschten Antworten anpassen.",
    modifyButton: "MEINE INFORMATIONEN ÄNDERN",
    emailConfirmation: "Sie haben eine E-Mail bekommen",
    emailButton: "Schließen",

    informations: {
      title: "Informationen",
      box1New: "Sie möchten klare Informationen zur Gesetzgebung, zu vorhandenen Prämien und Beihilfen im Bereich Energie oder sonstige Auskünfte und technische Beratung zum Thema Energie?",
      box1: "Brauchen Sie zusätzliche Erklärungen?",
      box12:
        "Brauchen Sie Informationen zu den verfügbaren Prämien oder Finanzierungsmöglichkeiten?",
      box13: `Hätten Sie gern eine erste Schätzung der Arbeitskosten?`,
      box14: `Kommen Sie kostenlos zu einem der 16 Energieberatung von Wallonie`,
      box2: "Suchen Sie nach allgemeinen Informationen?",
      box22: "Und beachten Sie unsere Themen-Programme",
      box22Point1: "Für das Verstehen der Grundprinzipien einer effizienten Renovierung.",
      box22Point2: "Um Ihnen dabei zu helfen, die richtigen Fragen zu stellen (Auswahl der Arbeiten, Techniken, Materialien etc.)",
      box22Point3: "Bestimmung der vorab, währenddessen und nach den Arbeiten zu treffenden Vorkehrungen.",
      box3: "Sind Sie bereit zu handeln?",
      box31: `Sie möchten in Aktion treten, wissen aber nicht, wo Sie anfangen sollen?`,
      box32: "Wenden Sie sich an einen Wohnungsauditor",
      box32Point1: "Für eine umfassende und ausführliche Energieanalyse Ihres Gebäudes",
      box32Point2: "Für personalisierte und maßgeschneiderte Ratschläge",
      box32Point3: "Für eine Einschätzung der Energieeinsparung und Kosten von Arbeiten",
      box4: `Sie möchten eine Wohnung verkaufen oder vermieten?`,
      box41: "Kontaktieren Sie eine zugelassenen Aussteller von PEB-Ausweisen",
      box5A: "Laden Sie den ",
      box5B: "praktischen Leitfaden für die Renovierung Ihrer Wohnung herunter",
      box5C: "",
    },
    building: {
      title: "Ihr Wohngebäude heute",
      habitation: "Wohngebäude",
      isolation: "Dämmung",
      ventilation: "Belüftung und Dichtigkeit",
      heater: "Heizsysteme",
      heaterPrimary: "Haupt-Heizung",
      heaterSecondary: "Sekundäre Heizung",
      water: "Systeme für die Warmwassererzeugung",
      pv: "Erneuerbare Energien",
      pvWater: "Thermische Solarmodule",
      pvElec: "Stromerzeugung",
    },
    question1: "Haupt-Verglasung : ",
    question2: "Dicke der Dämmung von Dach oder Decke : ",
    question3: "Wissen Sie, ob Prämien für Dämmung beantragt wurden?",
    question4: "Dicke der Dämmung der Mauern : ",
    question5: "Wissen Sie, ob Prämien für Dämmung beantragt wurden?",
    question6: "Dicke Dämmung Fußboden (oder Platte) : ",
    question7: "Wissen Sie, ob Prämien für Dämmung beantragt wurden?",
    question8: "Belüftungssystem : ",
    question9: "Zugluft : ",
    question10: "Feuchtigkeit : ",
    question11: "Dämmung Dach : ",
    question12: "Dämmung Mauern : ",
    question13: "Dämmung Boden/Fußboden : ",
    question14: "Position Dämmung : ",
    modalContent:
      "Geen resultaten gevonden, u wordt doorgestuurd naar de startpagina",
    modalButton: "Oké",
    emailError: "Vul alstublieft uw e-mailadres in",
    emailError2: "Fehler beim Senden der E-Mail. Bitte versuchen Sie es später erneut.",
    succesMessageTitle: "Raken",
    succesMessageContent: "Lien copié avec succès",
  },
  endlist: "10 cm und +",
  pdf: {
    userTitle: "Berichtsnummer",
    epcSectionTitle: "Schätzung Ihres Ergebnisses",
    epcStep: "Etappe",
    ecoLabelOrigin: "Wohngebäude ursprünglich",
    ecoLabelNow: "Wohngebäude heute",

    stepsTitle: "Szenario der Arbeiten, um das Label A zu erreichen",
    step1Title: "Etappe 1 – Verbesserung der Hülle",
    step1Box1Title: "Dach oder Decke:",
    step1Box1Content:
      "zu erreichender U-Wert < 0,2 W/m²K, d.h. ca. 20 bis 25 cm Dämmung in Rahmenstärke je nach Art der Dämmung bzw. 15 bis 20 cm bei durchgehender Verlegung von oben (erfordert gleichzeitige Neuauflage)",

    step1Box2Title: "Mauern :",
    step1Box2Content:
      "zu erreichender U-Wert < 0,24 W/m²K, d. h. ca. 16 cm Dämmung durchgehend verlegt (erfordert neue Fassadenverkleidung)",
    step1Box3Title: "Böden :",
    step1Box3Content:
      "zu erreichender U-Wert < 0,24 W/m²K, d.h. ca. 10 cm Dämmung durchgehend verlegt, wenn es sich um eine Bodenplatte handelt (erfordert neuen Estrich und neuen Bodenbelag)",
    step1Box4Title: "Fensterrahmen :",
    step1Box4Content:
      "Setzen Sie neue Hochleistungsrahmen, ausgestattet mit Hochleistungs-Isolierverglasung U = 1,0 W/m²K",
    step1Box5Title: "Luftdichtigkeit :",
    step1Box5Content:
      "Zu erreichender Wert v50 < 2 m³h/m² (Zielwert überprüfbar durch Luftdichtheitstest oder Blower-Door-Test) Dampfsperre vor der Dämmung, sauberer Wandanschluss, umlaufende Luftdichtheitsstreifen um die Zargen.",
    step1Box6Title: "Belüftung :",
    step1Box6Content:
      "Installation eines kompletten Lüftungssystems, bestehend aus Zuluftgittern in den Rahmen von Trockenräumen (Wohnzimmer, Schlafzimmer, Büro) und Luftabzügen in Nassräumen (Küche, Bad, Waschküche und WC), die mit Sonden verbunden sind, die ihren Betrieb bei angemessener Zeit gewährleisten mal.",
    step1BottomText: "Geschätzte Energieeffizienz nach Etappe 1 :",

    step2Title: "Etappe 2 – Verbesserung der Systeme",
    step2Box1Title: "Heizung :",
    step2Box1Content:
      "Installation einer Zentralheizung mit Heizkörpern und einem Brennwertkessel",
    step2Box2Title: "Warmwasser :",
    step2Box2Content:
      "Möglichst sofortige Produktion (ohne Speicherung) durch den Boiler oder einen Warmwasserbereiter",
    step2BottomText: "Geschätzte Energieeffizienz nach Etappe 2 :",

    step3Title: "Etappe 3 – Erneuerbare Energien",
    step3Box1Title: "Stromerzeugung :",
    step3Box1Content:
      "Zur Stromerzeugung - Platzierung von 4800 Watt-Peak oder etwa 16 Panels",
    step3Box1Content2:
      "Zur Stromerzeugung – Platzierung von 3600 Watt-Peak oder etwa 12 Panels",

    step3Box2Title: "Geschätzte Energieeffizienz nach Etappe 3 :",

    footerLink: "Gehen Sie auf unsere Website: ",
  },
};
export default german;
